import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
import { useServerContext } from '../../../../../hooks/useServerContext';
import type { LdapGroupsResponse } from '../../../../../types/__generated/on-premise-solution/api/ldapGroupsResponse.v1';

export function CellSelectLdapGr({
  dataItem: { commonName },
}: Omit<GridCellProps, 'commonName'> & {
  dataItem: LdapGroupsResponse;
}): React.ReactElement {
  const { ldapGrForm, setLdapGrForm } = useServerContext();

  useEffect(() => {
    if (!ldapGrForm) {
      setLdapGrForm(ldapGrForm);
    }
  }, [ldapGrForm, setLdapGrForm]);

  const addGroup = useCallback(
    ({ name }: { name: string }): void => {
      if (ldapGrForm && ldapGrForm.length > 0) {
        const index = ldapGrForm?.findIndex((l) => l.ldapGroupName === name);
        if (index !== -1) {
          const cutLdapGrForm = ldapGrForm.filter((gr) => gr.ldapGroupName !== name);
          setLdapGrForm([...cutLdapGrForm]);
        } else {
          setLdapGrForm([
            ...ldapGrForm,
            {
              ldapGroupName: name,
              assetGroupPolicy: 'do_not_create',
            },
          ]);
        }
      } else {
        setLdapGrForm([
          {
            ldapGroupName: name,
            assetGroupPolicy: 'do_not_create',
          },
        ]);
      }
    },
    [ldapGrForm, setLdapGrForm],
  );

  return (
    <td className="checkbox_cell">
      <Checkbox
        checked={!!ldapGrForm?.find((l) => l.ldapGroupName === commonName)}
        onClick={(): void =>
          addGroup({
            name: commonName,
          })
        }
      />
    </td>
  );
}
