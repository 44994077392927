import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import type { IPageState } from './types';
import localeNewTask from '../i18n/taskLocale/newTask.json';

export const BACKEND_BASE_URL =
  process.env.NODE_ENV === 'development' ? 'https://192.168.32.63/vm/v1' : '/vm/v1';

export const BACKEND_AUTH_URL =
  process.env.NODE_ENV === 'development' ? 'https://192.168.32.63/auth/v1/user' : '/auth/v1/user';

export const BACKEND_DASHBOARD_URL =
  process.env.NODE_ENV === 'development' ? 'https://192.168.32.63/dashboard/v1' : '/dashboard/v1';

export const BACKEND_DOWNLOAD_URL =
  process.env.NODE_ENV === 'development' ? 'https://192.168.32.63/download' : '/download';

export const BACKEND_COMMON =
  process.env.NODE_ENV === 'development' ? 'https://192.168.32.63/common' : '/common';

export const WEBSOCKET_URL =
  process.env.NODE_ENV === 'development'
    ? 'wss://192.168.32.63/connection/websocket'
    : `${window.location.protocol.includes('https') ? 'wss' : 'ws'}://${
        window.location.host
      }/connection/websocket`;

export const ROLE_VALUES = [
  {
    role: 'super_admin',
    description:
      'Полные права на работу с данными всех организаций, создание организаций, управление любыми пользователями, конфигурирование и обновление системы',
  },
  {
    role: 'account_admin',
    description:
      'Полные права на работу с данными конкретной организации (активы, пользователи, отчеты), запуск задач',
  },
  {
    role: 'user',
    description:
      'Имеет доступ только в рамках своей организации: добавление активов, создание задач, генерация отчетов',
  },
  {
    role: 'reader_user',
    description: 'Имеет доступ только в рамках своей организации: создание задач',
  },
];

export const ROLE_VALUES2 = [
  {
    role: 'super_admin',
    description: {
      'ru-RU':
        'Доступ по всем организациям: полные права на работу в системе, управление пользователями, учетными данными для подключения к активам, конфигурация настроек серверного ПО',
      'en-EN':
        'Access by all organizations: full system permissions, user management, asset connection credentials, server software configuration',
    },
  },
  {
    role: 'account_admin',
    description: {
      'ru-RU':
        'Доступ в рамках назначенной организации: полные права на работу в системе, управление пользователями, учетными данными для подключения к активам',
      'en-EN':
        'Access within the designated organization: full rights to work in the system, manage users, credentials to connect to assets',
    },
  },
  {
    role: 'user',
    description: {
      'ru-RU':
        ' Доступ в рамках назначенной организации: просмотр состояния инфраструктуры, создание задач, управление активами и группами, работа со списками исключений',
      'en-EN':
        ' Access within a designated organization: view infrastructure Status, create tasks, manage assets and groups, manage exception lists',
    },
  },
  {
    role: 'reader_user',
    description: {
      'ru-RU':
        'Доступ в рамках назначенной организации: просмотр состояния инфраструктуры, создание задач (аудит, инвентаризация, генерация отчетов)',
      'en-EN':
        'Access within a designated organization: view infrastructure status, create tasks (audit, inventory, report generation)',
    },
  },
];

export const COMMAND_VALUES = [
  'audit',
  // 'audit_windows',
  // 'ping',
  'package_info',
  'package_update',
  'agent_info',
  'agent_update',
  'get_config',
  'put_config',
  'inventorization',
  // 'inventorization_windows',
  // 'tasks_status',
];

export const GET_CONFIG_VALUES = ['logging.log_level', 'logging.log_path', 'scheduler.schedule'];
export const PUT_CONFIG_VALUES = ['CRITICAL', 'ERROR', 'WARNING', 'INFO', 'DEBUG'];

export const PAGEABLE_DATA = {
  buttonCount: 6,
  pageSizes: [15, 30, 50, 100],
};
export const PAGEABLE_DATA_LOCAL = {
  buttonCount: 5,
  pageSizes: [10, 15, 20, 30],
};

export const LAZY_PAGEABLE_DATA = {
  buttonCount: 5,
  pageSizes: [5, 10],
};

export const DATA_CACHE_STALETIME = Infinity;
export const DATA_CACHE_CACHETIME = Infinity;
export const AGENT_REFETCH_INTERVAL = 10000;
export const INITIAL_PAGING: IPageState = { skip: 0, take: 15 };
export const INITIAL_PAGING_10: IPageState = { skip: 0, take: 10 };
export const INITIAL_PAGING_5: IPageState = { skip: 0, take: 5 };
export const INITIAL_SORT: Array<SortDescriptor> = [{ field: '', dir: 'asc' }];
export const INITIAL_SORT_TASK_HISTORY: Array<SortDescriptor> = [
  { field: 'startedAt', dir: 'desc' },
];
export const INITIAL_SORT_ALL_USER_LOGLIST: Array<SortDescriptor> = [
  { field: 'createdAt', dir: 'desc' },
];

export const INITIAL_CREATED_AT_SORT: Array<SortDescriptor> = [{ field: 'createdAt', dir: 'desc' }];
export const INITIAL_LOG_TASK: Array<SortDescriptor> = [{ field: 'createdAt', dir: 'desc' }];
export const INITIAL_DEVICE_LIST: Array<SortDescriptor> = [
  { field: 'latestAudit.maxCvssScore', dir: 'desc' },
];
export const INITIAL_IMAGE_LIST: Array<SortDescriptor> = [
  { field: 'latestAudit.maxCvssScore', dir: 'desc' },
];
export const INITIAL_SORT_SECURITY_VULNS_LIST: Array<SortDescriptor> = [
  { field: 'totalAssets', dir: 'desc' },
];
export const INITIAL_SORT_ASSET_GROUP_LIST: Array<SortDescriptor> = [{ field: 'name', dir: 'asc' }];
export const INITIAL_SORT_CREDENTIAL_LIST: Array<SortDescriptor> = [{ field: '', dir: 'asc' }];
export const IMG_SRC_VALUES: { [osName: string]: string } = {
  ubuntu: 'ubuntu.svg',
  debian: 'debian.svg',
  centos: 'centos.svg',
  windows: 'windows.svg',
  oracle: 'oracle.svg',
  fedora: 'fedora.svg',
  redhat: 'redhat.svg',
  altlinux: 'altlinux.svg',
  astralinux: 'astralinux.svg',
  mintlinux: 'mintlinux.svg',
  amazon: 'amazon.svg',
  rocky: 'rocky.svg',
  alpine: 'alpine.svg',
  gentoo: 'gentoo.svg',
  rosa: 'rosa.svg',
  suse: 'suse.svg',
  redos: 'redos.svg',
  virtuozzo: 'virtuozzo.svg',
  cisco: 'cisco.svg',
  huawei: 'huawei.svg',
  mikrotik: 'mikrotik.svg',
  fortinet: 'fortinet.svg',
  juniper: 'juniper.svg',
  eltex: 'eltex.svg',
  paloaltonetworks: 'paloaltonetworks.svg',
  router: 'routerTitle.svg',
  firewall: 'firewallTitle.svg',
  switch: 'switch.svg',
  aruba: 'aruba.svg',
  arch: 'arch.svg',
  manjaro: 'manjaro.svg',
  almalinux: 'almalinux.svg',
  msvsphere: 'msvsphere.png',
  hypervisor: 'hypervisor.svg',
  checkpoint: 'checkpoint.svg',
  vmware: 'vmware.svg',
  osnova: 'osnova.svg',
};

// filtering: //////////////////////////////////////////////
export const FILTER_INITIAL: CompositeFilterDescriptor = {
  logic: 'and',
  filters: [],
};

export const INITIAL_SORT_HOST: Array<SortDescriptor> = [
  { field: 'latestAudit.maxCvssScore', dir: 'desc' },
];
export const INITIAL_SORT_WL: Array<SortDescriptor> = [{ field: 'createdAt', dir: 'desc' }];
export const INITIAL_SORT_SM: Array<SortDescriptor> = [{ field: 'softwareName', dir: 'asc' }];

export const STEPPER_LINUX_ASSET = [{ text: '1' }, { text: 'n' }, { text: '2' }, { text: '3' }];
export const STEPPER_WINDOWS_ASSET = [{ text: '1' }, { text: '2' }, { text: '3' }, { text: '4' }];

// NewTask form:
export const NEWTASK_VAL_START_TIME = {
  'ru-RU': [
    { label: localeNewTask['ru-RU'].form.startTime.now, value: 'now' },
    { label: localeNewTask['ru-RU'].form.startTime.startAt, value: 'startAt' },
    { label: localeNewTask['ru-RU'].form.startTime.repeat, value: 'scheduled' },
  ],
  'en-EN': [
    { label: localeNewTask['en-EN'].form.startTime.now, value: 'now' },
    { label: localeNewTask['en-EN'].form.startTime.startAt, value: 'startAt' },
    { label: localeNewTask['en-EN'].form.startTime.repeat, value: 'scheduled' },
  ],
};

export const NEWTASK_CHECK_DUPLICATE = {
  'ru-RU': [
    { label: localeNewTask['ru-RU'].form.checkDuplicate.globalCheck, value: 'globalCheck' },
    { label: localeNewTask['ru-RU'].form.checkDuplicate.inGroupCheck, value: 'inGroupCheck' },
    { label: localeNewTask['ru-RU'].form.checkDuplicate.doNotCheck, value: 'doNotCheck' },
  ],
  'en-EN': [
    { label: localeNewTask['en-EN'].form.checkDuplicate.globalCheck, value: 'globalCheck' },
    { label: localeNewTask['en-EN'].form.checkDuplicate.inGroupCheck, value: 'inGroupCheck' },
    { label: localeNewTask['en-EN'].form.checkDuplicate.doNotCheck, value: 'doNotCheck' },
  ],
};

export const taskTypesValue = {
  'ru-RU': [
    { text: 'Хосты', id: 'host' },
    { text: 'Образы контейнеров', id: 'image' },
    { text: 'Отчёты', id: 'report' },
    { text: 'Сетевые устройства', id: 'device' },
  ],
  'en-EN': [
    { text: 'Hosts', id: 'host' },
    { text: 'Container images', id: 'image' },
    { text: 'Reports', id: 'report' },
    { text: 'Network device', id: 'device' },
  ],
};
export const taskTypesValue2 = {
  'ru-RU': [
    { text: 'Активы', id: 'asset' },
    { text: 'Отчёты', id: 'report' },
    { text: 'Импорт активов/Синхронизация', id: 'asset_synchronize' },
  ],
  'en-EN': [
    { text: 'Assets', id: 'asset' },
    { text: 'Reports', id: 'report' },
    { text: 'Assets import/Synchronize', id: 'asset_synchronize' },
  ],
};

export const DASHBOARD_INTERVAL = {
  'ru-RU': [
    { text: 'Нет', value: 0 },
    { text: '1 минута', value: 60000 },
    { text: '5 минут', value: 300000 },
    { text: '15 минут', value: 900000 },
  ],
  'en-EN': [
    { text: 'No', value: 0 },
    { text: '1 minute', value: 60000 },
    { text: '5 minutes', value: 300000 },
    { text: '15 minutes', value: 900000 },
  ],
};

export const reportTypeValue = {
  'ru-RU': [
    { text: 'Отчет об уязвимостях активов', id: 'asset_vulnerability_audit' },
    { text: 'Инвентаризация активов', id: 'asset_inventorization' },
    { text: 'Отчет о наличии указанных уязвимостей', id: 'bulletin_overview' },
    { text: 'Топ-N уязвимостей (по кол-ву активов)', id: 'top_bulletins' },
    { text: 'Топ-N активов (по кол-ву уязвимостей)', id: 'top_vulnerable_assets' },
    { text: 'Дифференциальный отчет об уязвимостях', id: 'differential_audit' },
  ],
  'en-EN': [
    { text: 'Assets inventorization', id: 'asset_inventorization' },
    { text: 'Assets vulnerability report', id: 'asset_vulnerability_audit' },
    { text: 'Presence of specified vulnerabilities', id: 'bulletin_overview' },
    { text: 'Top-N vulnerabilities in infrastructure', id: 'top_bulletins' },
    { text: 'Top-N vulnerable assets in infrastructure', id: 'top_vulnerable_assets' },
    { text: 'Differential vulnerability report', id: 'differential_audit' },
  ],
};

export const kbUpdateSrcData = {
  'ru-RU': [
    { text: 'По умолчанию', id: 'default' },
    { text: 'Глобальный центр обновлений', id: 'global' },
    { text: 'Локальный WSUS', id: 'wsus' },
  ],
  'en-EN': [
    { text: 'Default', id: 'default' },
    { text: 'Global', id: 'global' },
    { text: 'Local WSUS', id: 'wsus' },
  ],
};

export const kbRebootData = {
  'ru-RU': [
    { text: 'Нет', id: 'never' },
    { text: 'При необходимости', id: 'ifRequired' },
    { text: 'В любом случае', id: 'always' },
  ],
  'en-EN': [
    { text: 'Never', id: 'never' },
    { text: 'Can request', id: 'ifRequired' },
    { text: 'Always', id: 'always' },
  ],
};

export const fileFormatValue = {
  'ru-RU': [{ text: 'pdf', id: 'pdf' }],
  'en-EN': [{ text: 'pdf', id: 'pdf' }],
};

// regExs:
// export const regExpAnd = /&|.+&|.+&./;
// export const regExpNum = /#|.+#|.+#./;
// export const regExpD = /\$|.+\$|.+\$./;
export const regExpSpecSymbols = /\$|.+\$|.+\$.|&|.+&|.+&.|#|.+#|.+#.|%|.+%|.+%.|%|.+%|.+%./iu;
// export const regExpSpecSymbols = /^[$.]+$/iu;

// export const checkSpace = /[^\s]/iu;

export const cvssScoreWidth = {
  noSort: '56px',
  sort: '76px',
};
export const publishedWidth = {
  noSort: '128px',
  sort: '148px',
};

// export const HOST_TOOLTIP_PROP = {
//   marginTop: '-48px',
//   marginLeft: '32px',
//   padding: '4px',
//   fontSize: '12px',
// };

export const HOST_ADD_TAG_PROP = {
  marginTop: '-28px',
  marginLeft: '12px',
  padding: '4px',
  fontSize: '12px',
};

// export const randomColor = Math.random().toString(16).slice(-6);

export const backgroundColorList = [
  'f8675c',
  'f24d85',
  'c53adc',
  '8150d9',
  '6374d3',
  '2196f3',
  '0daff8',
  '00bcd4',
  '009688',
  '44c74f',
  'dae947',
  'ffeb3b',
  'a7b5bc',
];
