/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-useless-fragment */
import { useCallback, useEffect, useState } from 'react';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { SoftManagementList } from './SoftManagementList';
import { CheckBox } from '../../common/form/CheckBox';
import type { SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse } from '../../../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdatesWithAvailableDashboardResponse.v1';
import { useSoftwareManagementContext } from '../../../hooks/useSoftwareManagementContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
// import { Button } from '../../common/baseElements/Button';
// import localeSofwareManagement from '../../../utils/i18n/assetLocales/softwareManagement.json';
import { useTaskAddContext } from '../../../hooks/useTaskAddContext';

// const currentLocale = (
//   window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
// ) as keyof typeof localeSofwareManagement;

export function SoftwareRow(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  const [softClass, setSoftClass] = useState(['soft-management', 'visible']);

  const { selectedSoftwareForUpdate, addSoftware, removeSoftware, setSelectedSoftwareForUpdate } =
    useSoftwareManagementContext();

  const { winSoftwareUpdateState, winSoftwareUpdateStateWl } = useHostModalContext();

  const { isSoftwareManagement } = useTaskAddContext();

  const handleContent = useCallback((): void => {
    if (softClass.indexOf('visible') === -1) {
      setSoftClass((prevState) => [...prevState, 'visible']);
    } else {
      setSoftClass(['repository']);
    }
  }, [softClass]);

  useEffect(() => {
    if (
      isSoftwareManagement &&
      winSoftwareUpdateState &&
      Object.keys(winSoftwareUpdateState).length > 0
    ) {
      setSelectedSoftwareForUpdate({ ...winSoftwareUpdateState });
    }

    if (
      isSoftwareManagement &&
      winSoftwareUpdateStateWl &&
      Object.keys(winSoftwareUpdateStateWl).length > 0
    ) {
      setSelectedSoftwareForUpdate({ ...winSoftwareUpdateStateWl });
    }
  }, []);

  return (
    <td className="wrapper-menu">
      {className && (
        <div className="row">
          <KendoButton
            className={softClass && softClass.indexOf('visible') !== -1 ? 'active' : ''}
            icon={softClass && softClass.indexOf('visible') !== -1 ? 'minus' : 'plus'}
            onClick={handleContent}
          />
          <span
            id="software-ids"
            className="k-button k-button-flat row-title"
            role="button"
            tabIndex={0}
            onKeyPress={handleContent}
            style={{
              gap: '12px',
            }}
          >
            <CheckBox
              value={
                dataItem.softwareCodename in selectedSoftwareForUpdate &&
                !selectedSoftwareForUpdate[dataItem.softwareCodename].fullId
              }
              onChange={(event: CheckboxChangeEvent): void => {
                if (event.value)
                  addSoftware(dataItem.softwareCodename, { softwareName: dataItem.softwareName });
                else removeSoftware(dataItem.softwareCodename);
              }}
              label=""
              checked={
                dataItem.softwareCodename in selectedSoftwareForUpdate &&
                !selectedSoftwareForUpdate[dataItem.softwareCodename].arch
              }
              disabled={
                !dataItem.softwareUpdates?.filter(
                  (s: any) => s.downloadStatus === 'downloaded' && s.isApproved,
                ).length
              }
              className={
                !dataItem.softwareUpdates?.filter(
                  (s: any) => s.downloadStatus === 'downloaded' && s.isApproved,
                ).length
                  ? 'disabled'
                  : ''
              }
            />
            <span onClick={handleContent}>
              {dataItem.softwareName}
              {dataItem.vendorName && (
                <span
                  style={{
                    color: '#ccc',
                    // fontSize: '.75em',
                  }}
                >
                  , {dataItem.vendorName}
                </span>
              )}
            </span>
            {/* {dataItem.softwareCodename in selectedSoftwareForUpdate && (
              <Button
                style={{ marginLeft: '12px' }}
                onClick={(): void => removeSoftware(dataItem.softwareCodename)}
                title={localeSofwareManagement[currentLocale].buttons.removeSoftwareSelection}
              >
                <span className="k-icon k-i-x" />
              </Button>
            )} */}
          </span>
        </div>
      )}
      <div className={`content ${softClass.join(' ')}`}>
        <SoftManagementList
          software={
            dataItem.softwareUpdates as SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse['data'][0]['softwareUpdates']
          }
          classNameValue={className}
        />
      </div>
    </td>
  );
}
