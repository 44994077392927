import type { TaskActionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';
import styles from '../TaskDescription.module.scss';

// If agent using old version when: !query.data.result?.data.log
export function TaskKbLogIfOldAgent({
  data,
}: {
  data: TaskActionDashboardResponse | undefined;
}): React.ReactElement {
  return (
    <div className={styles.taskKbLog}>
      {data?.type === 'kb_install' && data.result ? (
        <div>
          <span>KB install finished</span>
          <div>
            <span>
              Installed KB:{' '}
              {data.result.data.installedKb && Array.isArray(data.result.data.installedKb)
                ? data.result.data.installedKb.length
                : 0}
            </span>
          </div>
          <div>
            <span>
              Deleted KB:{' '}
              {data.result.data.deletedKb && Array.isArray(data.result.data.deletedKb)
                ? data.result.data.deletedKb.length
                : 0}
            </span>
          </div>
          <div>
            <span>
              Already exist KB:{' '}
              {data.result.data.alreadyExistKb && Array.isArray(data.result.data.alreadyExistKb)
                ? data.result.data.alreadyExistKb.length
                : 0}
            </span>
          </div>
          <div>
            <span>
              Not found KB:{' '}
              {data.result.data.notFoundKb && Array.isArray(data.result.data.notFoundKb)
                ? data.result.data.notFoundKb.length
                : 0}
            </span>
          </div>
          <div>
            <span>
              Not installed KB:{' '}
              {data.result.data.notInstalledKb && Array.isArray(data.result.data.notInstalledKb)
                ? data.result.data.notInstalledKb.length
                : 0}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
