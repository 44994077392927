import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeHostGroups from '../../../utils/i18n/assetLocales/assetGroups.json';
import { GroupList } from '../Group/GroupList';
import { DynamicGroupList } from '../DynamicGroup/DynamicGroupList';
import { useGroupContext } from '../../../hooks/useGroupContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;
export function TabsGroups({
  selected,
  handleSelect,
  taskElement,
}: {
  selected: number;
  handleSelect: (e: TabStripSelectEventArguments) => void;
  taskElement?: string;
}): React.ReactElement {
  const { dynamicGroupForm } = useGroupContext();

  const { hostFormTemp } = useHostModalContext();

  const countOfStaticGroup =
    taskElement === 'AddGroupsForTask' && hostFormTemp
      ? hostFormTemp.filter((f) => f.assetGroupId).length
      : 0;

  const countOfDynamicGroup =
    taskElement === 'AddGroupsForTask' && dynamicGroupForm ? dynamicGroupForm.length : 0;

  const titleOfStaticGroup = countOfStaticGroup
    ? `${localeHostGroups[currentLocale].containerOfGr.tabs.gr} (${countOfStaticGroup})`
    : localeHostGroups[currentLocale].containerOfGr.tabs.gr;

  const titleOfDynamicGroup = countOfDynamicGroup
    ? `${localeHostGroups[currentLocale].containerOfGr.tabs.grDynamic} (${countOfDynamicGroup})`
    : localeHostGroups[currentLocale].containerOfGr.tabs.grDynamic;

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title={titleOfStaticGroup}>
        <GroupList taskElement={taskElement} />
      </TabStripTab>
      <TabStripTab title={titleOfDynamicGroup}>
        <DynamicGroupList taskElement={taskElement} />
      </TabStripTab>
    </TabStrip>
  );
}
