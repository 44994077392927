/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

let dataQuery = [] as IDaraFilterSelect[];
export function FilterActionSelect(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  dataQuery = [
    { text: currentLocale === 'ru-RU' ? 'Выбрать действие...' : 'Select action...', val: '' },
    { text: currentLocale === 'ru-RU' ? 'Аудит уязвимостей' : 'Audit', val: 'audit' },
    { text: currentLocale === 'ru-RU' ? 'Аудит образа' : 'Image audit', val: 'audit_image' },
    { text: currentLocale === 'ru-RU' ? 'Обновление агента' : 'Agent update', val: 'agent_update' },
    {
      text: currentLocale === 'ru-RU' ? 'Обновление пакетов' : 'Package update',
      val: 'package_update',
    },
    {
      text: currentLocale === 'ru-RU' ? 'Обновление ПО' : 'Software management',
      val: 'windows_software_management',
    },
    {
      text: currentLocale === 'ru-RU' ? 'Установка KB' : 'KB install',
      val: 'kb_install',
    },
    {
      text: currentLocale === 'ru-RU' ? 'Инвентаризация' : 'Inventorization',
      val: 'inventorization',
    },
    {
      text: currentLocale === 'ru-RU' ? 'Отчёт по активу' : 'Asset report',
      val: 'single_report',
    },
    {
      text: currentLocale === 'ru-RU' ? 'Консолидированный отчёт' : 'Asset report generation',
      val: 'group_report',
    },
    {
      text:
        currentLocale === 'ru-RU' ? 'Импорт активов/Синхронизация' : '	Assets import/Synchronize',
      val: 'asset_ldap_synchronize',
    },
  ];

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={dataQuery[0]}
      data={dataQuery}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
