import type { IAccountForm } from '../../hooks/useAccountModalContext';
import type { AccountDashboardResponse } from '../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import type { AssetsImagesDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import type { IApiMethod, IDeviceForm, IHostForm, IImageForm } from './types';

interface IScope {
  accountIds: (string | undefined)[] | null;
  assetIds: (string | undefined)[] | null;
  assetGroupIds: (string | undefined)[] | null;
  allowedApiMethods: IApiMethod[] | null;
  allowedIpRanges: string[] | null;
  assetTypes: null;
}
type TField =
  | 'accountIds'
  | 'allowedApiMethods'
  | 'allowedIpRanges'
  | 'assetGroupIds'
  | 'assetIds'
  | 'assetIds'
  | 'assetTypes';
interface IReturnRestriction {
  field: TField;
  restriction: boolean;
}

export function addAllAccounts(
  accounts: AccountDashboardResponse[] | undefined,
  accountForm: IAccountForm[] | null,
  setAccountForm: (v: IAccountForm[] | null) => void,
): void {
  const accountAllListForm = [] as IAccountForm[];
  accounts?.forEach((a) => {
    if (a.accountId) {
      accountAllListForm.push({
        accountId: a.accountId,
        name: a.name,
      });
    }
  });
  if (accountForm && accountForm.length > 0) {
    const accountFormAndSelected = [...accountForm, ...accountAllListForm];
    const duplicatValChecked = accountFormAndSelected.filter(
      (a, i, arr) =>
        arr.findIndex((val) => val.name === a.name && val.accountId === a.accountId) === i,
    );

    setAccountForm([...duplicatValChecked]);
  } else {
    setAccountForm([...accountAllListForm]);
  }
}

export function addAllImages(
  images: AssetsImagesDashboardResponse | undefined,
  imageForm: IImageForm[] | null,
  setImageForm: (v: IImageForm[] | null) => void,
): void {
  const imageAllListForm = [] as IImageForm[];
  images?.data.forEach((image) => {
    if (image.imageId) {
      imageAllListForm.push({
        imageNames: image.imageNames[0],
        imageId: image.imageId,
        assetId: image.assetId,
      });
    }
  });
  if (imageForm && imageForm.length > 0) {
    const imageFormAndSelected = [...imageForm, ...imageAllListForm];
    const duplicatValChecked = imageFormAndSelected.filter(
      (image, i, arr) =>
        arr.findIndex(
          (val) => val.imageNames === image.imageNames && val.imageId === image.imageId,
        ) === i,
    );

    setImageForm([...duplicatValChecked]);
  } else {
    setImageForm([...imageAllListForm]);
  }
}

export function addAllMethods(
  methods: IApiMethod[],
  apiMethodsForm: IApiMethod[] | null,
  setApiMethodsForm: React.Dispatch<React.SetStateAction<IApiMethod[] | null>>,
): void {
  const methodAllListForm = [] as IApiMethod[];
  methods?.forEach((m) => {
    methodAllListForm.push(m);
  });
  if (apiMethodsForm && apiMethodsForm.length > 0) {
    const nethodFormAndSelected = [...apiMethodsForm, ...methodAllListForm];
    const duplicatValChecked = nethodFormAndSelected.filter(
      (m, i, arr) => arr.findIndex((val) => val.path === m.path) === i,
    ) as IApiMethod[];

    setApiMethodsForm([...duplicatValChecked]);
  } else {
    setApiMethodsForm([...methodAllListForm]);
  }
}

export function prepareAssetIds({
  hostForm,
  deviceForm,
  imageForm,
}: {
  hostForm: IHostForm[] | null;
  deviceForm: IDeviceForm[] | null;
  imageForm: IImageForm[] | null;
}): (string | undefined)[] | null {
  let assetIds: (string | undefined)[] | null = null;

  if (hostForm && hostForm.length > 0 && assetIds) {
    assetIds = [...assetIds, ...hostForm.filter((a) => a.assetId).map((a) => a.assetId)];
  } else if (hostForm && hostForm.length > 0 && !assetIds) {
    assetIds = hostForm.filter((a) => a.assetId).map((a) => a.assetId);
  }
  if (deviceForm && deviceForm.length > 0 && assetIds) {
    assetIds = [...assetIds, ...deviceForm.map((d) => d.assetId)];
  } else if (deviceForm && deviceForm.length > 0 && !assetIds) {
    assetIds = deviceForm.map((d) => d.assetId);
  }
  if (imageForm && imageForm.length > 0 && assetIds) {
    assetIds = [...assetIds, ...imageForm.map((i) => i.assetId)];
  } else if (imageForm && imageForm.length > 0 && !assetIds) {
    assetIds = imageForm.map((i) => i.assetId);
  }

  return assetIds;
}
export function prepareScopeForApiToken({
  hostForm,
  accountForm,
  assetIds,
  apiMethodsForm,
  ipRangesForm,
}: {
  hostForm: IHostForm[] | null;
  accountForm: IAccountForm[] | null;
  assetIds: (string | undefined)[] | null;
  apiMethodsForm: IApiMethod[] | null;
  ipRangesForm: string[] | null;
}): IScope {
  const scope = {
    accountIds: accountForm && accountForm.length > 0 ? accountForm.map((a) => a.accountId) : null,
    assetIds,
    assetGroupIds:
      hostForm && hostForm.length > 0 && hostForm.filter((g) => g.assetGroupId).length > 0
        ? hostForm.filter((g) => g.assetGroupId).map((g) => g.assetGroupId)
        : null,
    allowedApiMethods: apiMethodsForm && apiMethodsForm.length > 0 ? [...apiMethodsForm] : null,
    allowedIpRanges: ipRangesForm && ipRangesForm.length > 0 ? ipRangesForm.map((ip) => ip) : null,
    assetTypes: null,
  };

  return scope;
}
export function showRestrictions(scope: { [key: string]: [] }): IReturnRestriction[] {
  const RestrictionVal = [] as IReturnRestriction[];

  Object.keys(scope).forEach((val: string) => {
    if (val !== 'assetTypes') {
      const fieldName = val as TField;

      if (scope && scope[val] && scope[val].length > 0) {
        RestrictionVal.push({ field: fieldName, restriction: true });
      } else {
        RestrictionVal.push({ field: fieldName, restriction: false });
      }
    }
  });

  return RestrictionVal.sort((a, b) => (a.field < b.field ? -1 : 1));
}
