import type { EnrichedTaskActionResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedTaskActionResponse.v1';
import styles from '../TaskDescription.module.scss';

export function TaskSmLogSummary({
  data,
}: {
  data: EnrichedTaskActionResponse | undefined;
}): React.ReactElement {
  return (
    <div className={styles.taskKbLog}>
      {data?.type === 'windows_software_management' && data.result ? (
        <div>
          <div style={{ borderBottom: '1px dashed black', margin: '16px 0' }} />
          <span>Software install finished.</span>
          <div>
            <span>
              Installed software:{' '}
              {data.result.data.installedSoftware &&
              Array.isArray(data.result.data.installedSoftware)
                ? data.result.data.installedSoftware.length
                : 0}
            </span>
          </div>
          <div>
            <span>
              Uninstalled software:{' '}
              {data.result.data.uninstalledSoftware &&
              Array.isArray(data.result.data.uninstalledSoftware)
                ? data.result.data.uninstalledSoftware.length
                : 0}
            </span>
          </div>
          <div>
            <span>
              Updated software:{' '}
              {data.result.data.updatedSoftware && Array.isArray(data.result.data.updatedSoftware)
                ? data.result.data.updatedSoftware.length
                : 0}
            </span>
          </div>
          <div>
            <span>
              Not updated software:{' '}
              {data.result.data.notUpdatedSoftware &&
              Array.isArray(data.result.data.notUpdatedSoftware)
                ? data.result.data.notUpdatedSoftware.length
                : 0}
            </span>
          </div>
          <div>
            <span>
              No available updates for software:{' '}
              {data.result.data.noAvailableUpdatesForSoftware &&
              Array.isArray(data.result.data.noAvailableUpdatesForSoftware)
                ? data.result.data.noAvailableUpdatesForSoftware.length
                : 0}
            </span>
          </div>
          <div>
            <span>
              Not found software:{' '}
              {data.result.data.notFoundSoftware && Array.isArray(data.result.data.notFoundSoftware)
                ? data.result.data.notFoundSoftware.length
                : 0}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
