import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { ResultSyncTask } from './ResultSyncTask';
import type { TaskExecutionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { ImportOptionsSyncTask } from './ImportOptionsSyncTask';
import type { TypeLdapEqualAssetGroup } from '../../../../utils/helpers/types';
import type { TaskActionsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskActionsDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function TabsSyncDetails({
  data,
  dataExecutionInfo,
}: {
  data:
    | Extract<TaskActionsDashboardResponse['data'][number], { type: 'asset_ldap_synchronize' }>[]
    | undefined;
  dataExecutionInfo: TaskExecutionDashboardResponse | undefined;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const typeOfServer = useRef<'ldap' | 'activedirectory' | undefined>(undefined);

  const sourceLdapEqualAssetGroup = useRef<TypeLdapEqualAssetGroup[] | null>(null);

  const resultLdapEqualAssetGroup = useRef<TypeLdapEqualAssetGroup[] | null>(null);

  useEffect(() => {
    if (data && data[0].ldap) typeOfServer.current = data[0].ldap.type;
    if (data && data[0].taskType === 'asset_synchronize' && data[0].result) {
      sourceLdapEqualAssetGroup.current = data[0].result
        .filter((r) => r.targetAssetGroup?.name)
        .map((r) => ({
          sourceLdap: r.sourceLdapGroupName,
          assetLdapName: r.targetAssetGroup?.name,
          assetLdapId: r.targetAssetGroup?.assetGroupId,
        }));
    }
  }, [data]);

  useEffect(() => {
    if (
      sourceLdapEqualAssetGroup.current &&
      dataExecutionInfo?.taskType === 'asset_synchronize' &&
      dataExecutionInfo.actions.asset_ldap_synchronize?.ldapGroups
    ) {
      resultLdapEqualAssetGroup.current =
        dataExecutionInfo.actions.asset_ldap_synchronize?.ldapGroups
          .filter(
            (g, i) =>
              sourceLdapEqualAssetGroup.current &&
              sourceLdapEqualAssetGroup.current[i] &&
              g.ldapGroupName === sourceLdapEqualAssetGroup.current[i].sourceLdap,
          )
          .map((r, i) => ({
            sourceLdap: r.ldapGroupName,
            assetLdapName:
              (sourceLdapEqualAssetGroup.current &&
                sourceLdapEqualAssetGroup.current[i] &&
                sourceLdapEqualAssetGroup.current[i].assetLdapName) ||
              undefined,
            assetLdapId:
              (sourceLdapEqualAssetGroup.current &&
                sourceLdapEqualAssetGroup.current[i] &&
                sourceLdapEqualAssetGroup.current[i].assetLdapId) ||
              undefined,
          }));
    }
  }, [sourceLdapEqualAssetGroup.current]);

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title={localeNewTask[currentLocale].taskSyncDescription.tabs.resultTitle}>
        <ResultSyncTask data={data} />
      </TabStripTab>
      <TabStripTab title={localeNewTask[currentLocale].taskSyncDescription.tabs.importOptionsTitle}>
        <ImportOptionsSyncTask
          dataExecutionInfo={dataExecutionInfo}
          typeOfServer={typeOfServer.current}
          resultLdapEqualAssetGroup={resultLdapEqualAssetGroup.current}
        />
      </TabStripTab>
    </TabStrip>
  );
}
