/* eslint-disable react/jsx-pascal-case */
import { useQueryClient } from 'react-query';
import { useEffect } from 'react';
import localeHostGroups from '../../../utils/i18n/assetLocales/assetGroups.json';
import { useGroupContext } from '../../../hooks/useGroupContext';
import { getUrlHostGr } from '../../../utils/helpers/host-group-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { CommonGroupList } from '../../common/CommonGroupList';
import { Button } from '../../common/baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';
import styles from '../ContainerOfGroups/containerOfGroups.module.scss';
import { EditGroupForm } from '../Group/form/EditGroup';

export function DynamicGroupList({ taskElement }: { taskElement?: string }): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHostGroups;

  const currentUser = getUserData();

  const urlParams = getUrlHostGr('DynamicGroupList');

  const queryClient = useQueryClient();

  const { isOpen, setIsOpen, setMarkForStringOfDynamicGr } = useGroupContext();

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'DynamicGroupList',
  };

  const editThisGroup = (): void => {
    setIsOpen(true);
  };

  const handleRefresh = (): void => {
    queryClient.invalidateQueries('HostDynamicGroupList');
  };

  useEffect(() => {
    return () => setMarkForStringOfDynamicGr(null);
  }, []);

  return (
    <div className={styles.dynamicGrList}>
      <div className="common-header-page">
        {taskElement !== 'AddGroupsForTask' && (
          <div className="topactions">
            {currentUser?.role !== 'reader_user' && (
              <Button fill="action" onClick={editThisGroup}>
                {localeHostGroups[currentLocale].btnAddGroup}
              </Button>
            )}
            <Button onClick={handleRefresh} icon="refresh">
              {localeHostGroups[currentLocale].btnRefresh}
            </Button>
          </div>
        )}
      </div>
      <CommonGroupList
        dataForHooks={dataForHooks}
        urlParams={urlParams}
        taskElement={taskElement}
      />
      {isOpen && <EditGroupForm />}
    </div>
  );
}
