import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
  GridRowProps,
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React from 'react';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonWhiteList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeSettings from '../../utils/i18n/settingsLocales/settings.json';
import { CellLinkWhiteList } from '../features/WhiteList/cells/CellLinkWhiteList';
import { StandartCell } from './baseElements/StandartCell';
import { CellCommonDate } from './baseElements/CellCommonDate';
import { StatusCell } from './cells/StatusCell';
import { CellWhiteListMenu } from '../features/WhiteList/cells/CellWhiteListMenu';
import CellSelectWL from './cells/CellSelectWL';

export function CommonWhiteList(props: ICommonWhiteList): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeSettings;

  const { sort, data, page, total, pageChange, setSort, dataForHooks } = props;

  const rowRender = (
    trElement: React.ReactElement<HTMLTableRowElement>,
    values: GridRowProps,
  ): React.ReactElement => {
    const notActive = !!values.dataItem.isDisabled;
    const trProps: any = { className: notActive ? 'row-grid-table' : '' };

    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <Grid
          pageable={total && total > 15 ? PAGEABLE_DATA : false}
          sortable
          sort={sort}
          data={data}
          skip={page.skip}
          take={page.take}
          total={total || 0}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          scrollable="none"
          rowRender={rowRender}
          className={total && total < 15 ? 'no-pageable-k-grid' : ''}
        >
          {dataForHooks.componentName === 'AddVulnToWLForm' && (
            <Column
              field="addVuln"
              title={localeSettings[currentLocale].selectValue}
              cell={CellSelectWL}
              width="80px"
              sortable={false}
            />
          )}
          <Column
            cell={CellLinkWhiteList}
            title={localeSettings[currentLocale].whiteList.grid.name}
            filter="text"
            field="name"
            width="240px"
          />
          <Column
            cell={StandartCell}
            title={localeSettings[currentLocale].whiteList.grid.createdBy}
            filter="text"
            field="createdBy.name"
            width="200px"
          />
          <Column
            cell={CellCommonDate}
            title={localeSettings[currentLocale].whiteList.grid.createdAt}
            filter="text"
            field="createdAt"
            width="180px"
          />
          <Column
            cell={StatusCell}
            field="isDisabled"
            title={localeSettings[currentLocale].whiteList.grid.status}
            filter="text"
            width="140px"
          />
          <Column
            cell={StandartCell}
            field="totalBulletins"
            title={localeSettings[currentLocale].whiteList.grid.bulletinCount}
            filter="text"
            width="140px"
          />
          <Column
            cell={StandartCell}
            title={localeSettings[currentLocale].whiteList.grid.description}
            filter="text"
            field="description"
          />
          {dataForHooks.componentName === 'WhiteList' && (
            <Column cell={CellWhiteListMenu} filter="text" sortable={false} width="40px" />
          )}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
