import locale from '../../../../utils/i18n/assetLocales/assetDescriptionGroup.json';
import localeMaintenance from '../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import type { AssetDynamicGroupDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetDynamicGroupsDashboardResponse.v1';
import styles from '../../ContainerOfGroups/containerOfGroups.module.scss';
import { MaintenanceTableListButton } from '../../Host/MaintenanceWindows/MaintenanceTableListButton';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;
export function DynamicGroupMetaBlock({
  data,
  latestAssetsCount,
}: {
  data: AssetDynamicGroupDashboardResponse | undefined;
  latestAssetsCount: number | undefined;
}): React.ReactElement {
  return (
    <div className={`${styles.dynamicGroupMetaBlock} common-summary-page`}>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.assetCount}</span>
            </td>
            <td>{latestAssetsCount ? <span>{latestAssetsCount}</span> : <span>0</span>}</td>
          </tr>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.description}</span>
            </td>
            {data?.description ? (
              <td>
                <div title={data?.description} className="vuln-description">
                  {data?.description.length > 100
                    ? `${data?.description.slice(0, 100)}...`
                    : data?.description}
                </div>
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.createdAt}</span>
            </td>
            <td>{new Date(String(data?.createdAt)).toLocaleString()}</td>
          </tr>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.modifiedAt}</span>
            </td>
            <td>{data?.modifiedAt ? new Date(String(data?.modifiedAt)).toLocaleString() : '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.createdBy}</span>
            </td>
            <td>
              {data &&
              data.createdBy &&
              'source' in data.createdBy &&
              data?.createdBy.source === 'user' ? (
                <span>{data?.createdBy.name}</span>
              ) : (
                <span>-</span>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.modifiedBy}</span>
            </td>
            <td>
              {data &&
              data.modifiedBy &&
              'source' in data.modifiedBy &&
              data?.modifiedBy.source === 'user' ? (
                <span>{data?.modifiedBy.name}</span>
              ) : (
                <span>-</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{localeMaintenance[currentLocale].maintenanceWindows}</span>
            </td>
            <td>
              <MaintenanceTableListButton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
