const ITEMS_MAIN_MENU = [
  {
    lang: { 'ru-RU': 'Дашборды' },
    text: 'Dashboards',
    className: 'head',
    id: 'dashboard-top-level',
  },
  {
    lang: { 'ru-RU': 'Уязвимости' },
    text: 'Vulnerabilities',
    className: 'button',
    route: '/lk/dashboard/vulnerabilities',
    id: 'vulns-list',
    icon: 'vulnerability-dashboard-menu-icon',
  },
  {
    lang: { 'ru-RU': 'Инвентаризация' },
    text: 'Inventorization',
    className: 'button',
    route: '/lk/dashboard/inventorization',
    id: 'inv-list',
    icon: 'inventorization-dashboard-menu-icon',
  },
  {
    separator: true,
  },
  {
    lang: { 'ru-RU': 'Активы' },
    text: 'Assets',
    className: 'head',
    id: 'asset-top-level',
  },
  {
    lang: { 'ru-RU': 'Хосты' },
    text: 'Hosts',
    className: 'button',
    route: '/lk/assets/hosts',
    id: 'host-list',
    icon: 'hosts-menu-icon',
  },
  {
    lang: { 'ru-RU': 'Образы' },
    text: 'Images',
    className: 'button',
    route: '/lk/assets/images',
    id: 'image-list',
    icon: 'images-menu-icon',
  },
  // {
  //   lang: { 'ru-RU': 'Группы хостов' },
  //   text: 'Host groups',
  //   className: 'button',
  //   route: '/lk/assets/groups',
  //   id: 'group-list',
  //   icon: 'host-groups-menu-icon',
  // },
  // {
  //   lang: { 'ru-RU': 'Динамические группы' },
  //   text: 'Dynamic groups',
  //   className: 'button',
  //   route: '/lk/assets/dynamic-groups',
  //   id: 'group-list',
  //   icon: 'host-groups-menu-icon',
  // },
  {
    lang: { 'ru-RU': 'Группы хостов' },
    text: 'Host groups',
    className: 'button',
    route: '/lk/assets/groups/static',
    id: 'group-list',
    icon: 'host-groups-menu-icon',
  },
  {
    lang: { 'ru-RU': 'Устройства' },
    text: 'Devices',
    className: 'button',
    route: '/lk/assets/devices',
    id: 'devices',
    icon: 'devices-menu-icon',
  },
  {
    lang: { 'ru-RU': 'Учетные записи' },
    text: 'Credentials',
    className: 'button',
    route: '/lk/credentials/list',
    icon: 'credentials-menu-icon',
    id: 'credentials',
  },
  {
    lang: { 'ru-RU': 'Обновления' },
    text: 'Software management',
    className: 'button',
    route: '/lk/software-management',
    icon: 'software-management-menu-icon',
    id: 'software-management',
  },
  {
    separator: true,
  },
  {
    lang: { 'ru-RU': 'Аудит' },
    text: 'Audit',
    className: 'head',
    id: 'audit-top-level',
  },
  {
    lang: { 'ru-RU': 'Задачи' },
    text: 'Tasks',
    className: 'button',
    route: '/lk/audit/tasks',
    id: 'audit-task',
    selected: true,
    icon: 'tasks-menu-icon',
  },
  {
    lang: { 'ru-RU': 'История' },
    text: 'History',
    className: 'button',
    route: '/lk/audit/history',
    id: 'audit-history',
    icon: 'history-menu-icon',
  },
  {
    lang: { 'ru-RU': 'Списки исключений' },
    text: 'Exception lists',
    className: 'button',
    route: '/lk/audit/exceptions',
    id: 'whitelist',
    icon: 'logs-menu-icon',
  },
  {
    separator: true,
  },
  {
    lang: { 'ru-RU': 'Обзор защищенности' },
    text: 'Security overview',
    className: 'head',
    id: 'audit-top-level',
  },
  {
    lang: { 'ru-RU': 'Уязвимости' },
    text: 'Vulnerabilities',
    className: 'button',
    route: '/lk/security/vulnerabilities',
    id: 'audit-task',
    selected: true,
    icon: 'vulns-menu-icon',
  },
  {
    separator: true,
  },
  {
    lang: { 'ru-RU': 'Система' },
    text: 'System',
    className: 'head',
    id: 'data-second-level',
  },
  {
    lang: { 'ru-RU': 'Настройки' },
    text: 'Settings',
    className: 'button',
    icon: 'settings-menu-icon',
    // icon: 'k-i-gear',
    route: '/lk/settings/license',
    id: 'settings-list',
  },
  {
    lang: { 'ru-RU': 'Пользователи' },
    text: 'Users',
    className: 'button',
    route: '/lk/users/list',
    id: 'users-list',
    icon: 'users-menu-icon',
  },
  {
    lang: { 'ru-RU': 'БДУ' },
    text: 'VDB',
    className: 'button',
    route: '/lk/security/vdb',
    id: 'audit-task',
    selected: true,
    icon: 'vdb-menu-icon',
  },
  {
    lang: { 'ru-RU': 'Журналы' },
    text: 'Logs',
    className: 'button',
    route: '/lk/systemlog/users',
    icon: 'logs-menu-icon',
    id: 'systemlog',
  },
  {
    lang: { 'ru-RU': 'Справка' },
    text: 'Help',
    className: 'button',
    route: '/lk/doc/',
    icon: 'help-menu-icon',
  },
];

type LocaleType = Array<{
  separator?: boolean;
  text?: string;
  route?: string;
  id?: string;
  className?: string;
  selected?: boolean;
  icon?: string;
}>;

export function localizeDrawerMenu(locale: string): LocaleType {
  return ITEMS_MAIN_MENU.map((item) => {
    const { lang, ...menuItem } = item;
    if (lang) {
      switch (locale) {
        case 'ru-RU':
          menuItem.text = lang['ru-RU'];
          break;

        default:
          break;
      }
    }

    return menuItem;
  });
}
