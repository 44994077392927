import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';

export function MaintenanceGroupnameCell(props: GridCellProps): ReactElement {
  const { dataItem } = props;

  return (
    <td>
      {dataItem.groupType && dataItem.groupId ? (
        <a href={`/lk/assets/groups/${dataItem.groupType}/${dataItem.groupId}`}>
          {dataItem.name || ''}
        </a>
      ) : (
        dataItem.name || ''
      )}
    </td>
  );
}
