/* eslint-disable react/jsx-props-no-spreading */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { State, process as Process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useRef, useState } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { FILTER_INITIAL, PAGEABLE_DATA_LOCAL } from '../../../../utils/helpers/constants';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import localeVmTask from '../../../../utils/i18n/taskLocale/vmTask.json';
import { useLocalExcelExport } from '../../../../hooks/components/useLocalExcelExport';
import type { ISoftwareManagement } from '../../../../utils/helpers/task-helpers';
import { CellOldVersion } from '../taskPDetails/cells/CellOldVersion';
import { CellNewVersion } from '../taskPDetails/cells/CellNewVersion';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../utils/helpers/local-filter-helpers';
import { SM_UPDATE } from '../../../../utils/helpers/constant-local-filter-task';
import { CellStatus } from '../taskPDetails/cells/CellType';
import { Input } from '../../../common/baseElements/Input';
import { Button } from '../../../common/baseElements/Button';
import { TaskSmFastFilter } from './TaskSmFastFilter';
import type { EnrichedTaskActionResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedTaskActionResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function TaskSmResult({
  softwareUpdate,
  hostName,
  status,
}: {
  softwareUpdate: ISoftwareManagement;
  hostName: string | undefined | null | false;
  status: EnrichedTaskActionResponse['status'];
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'maxScore', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.VulnsSoft ? Number(pageLimitGrid.VulnsSoft) : 15,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'name';

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [customSearch, setCustomSearch] = useState('');

  const [filter, setFilter] = useState(FILTER_INITIAL);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _grid = useRef<React.LegacyRef<Grid> | undefined>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const excelExport = useLocalExcelExport(
    dataState,
    _export,
    softwareUpdate.allSoftware,
    setDataState,
  );

  const titleVulnsSoft = `${hostName && hostName.replaceAll('.', '-')}_${
    localeVmTask[currentLocale].tabs.excelTitleSu
  }`;

  return (
    <div className="task-p-result">
      {softwareUpdate && softwareUpdate.allSoftware.length > 0 && (
        <>
          <div className="filter-actions">
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <div className="filter-line">
                  <Filter
                    value={filter}
                    onChange={(event): void =>
                      onFilterChange(
                        event,
                        setCustomSearch,
                        setDataState,
                        dataState,
                        setFilter,
                        filterValue,
                        customSearch,
                        fieldFindInTable,
                      )
                    }
                    fields={SM_UPDATE[currentLocale]}
                  />
                  {filter.filters.length === 0 && (
                    <Input
                      name="customSearch"
                      placeholder={localeAuditTask[currentLocale].customFilterSu}
                      value={customSearch}
                      onChange={(e): void =>
                        handlerCustomSearch(
                          e,
                          setCustomSearch,
                          setDataState,
                          filterValue,
                          dataState,
                          fieldFindInTable,
                        )
                      }
                    />
                  )}
                  <Button excel fill="flat" onClick={excelExport}>
                    {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
                  </Button>
                </div>
              </IntlProvider>
            </LocalizationProvider>
            {filter.filters.length === 0 && (
              <TaskSmFastFilter
                dataState={dataState}
                setDataState={setDataState}
                softwareUpdate={softwareUpdate}
                filterValue={filterValue}
              />
            )}
          </div>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              {softwareUpdate && softwareUpdate.allSoftware && (
                <ExcelExport
                  data={softwareUpdate.allSoftware}
                  ref={_export}
                  fileName={titleVulnsSoft.replaceAll('.', '_')}
                >
                  <Grid
                    pageable={softwareUpdate.allSoftware.length > 10 ? PAGEABLE_DATA_LOCAL : false}
                    sortable
                    data={Process(softwareUpdate.allSoftware, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent): void => {
                      setDataState(e.dataState);
                      if (pageLimitGrid) {
                        const pageLimit = {
                          ...pageLimitGrid,
                          VulnsSoft: String(e.dataState.take),
                        };
                        localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                      } else {
                        const pageLimit = {
                          VulnsSoft: String(e.dataState.take),
                        };
                        localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                      }
                    }}
                    scrollable="none"
                    ref={_grid.current}
                    className={
                      softwareUpdate.allSoftware.length && softwareUpdate.allSoftware.length < 10
                        ? 'no-pageable-k-grid'
                        : ''
                    }
                  >
                    <Column
                      field="name"
                      title={localeAuditTask[currentLocale].vulnsSoft.table.swName}
                    />
                    <Column
                      field="oldVersion"
                      title={localeAuditTask[currentLocale].packageList.oldVersion}
                      cell={CellOldVersion}
                    />
                    <Column
                      field="newVersion"
                      title={localeAuditTask[currentLocale].packageList.newVersion}
                      cell={CellNewVersion}
                    />
                    <Column
                      field="status"
                      title={localeAuditTask[currentLocale].packageList.status}
                      width="220px"
                      cell={CellStatus}
                    />
                  </Grid>
                </ExcelExport>
              )}
            </IntlProvider>
          </LocalizationProvider>
        </>
      )}
      {status === 'processing' && (
        <div>
          <p>{localeAuditTask[currentLocale].vulnsSoft.table.processing}</p>
        </div>
      )}
      {(status === 'fail' || status === 'timeout') && (
        <div>
          <p>{localeAuditTask[currentLocale].vulnsSoft.table.noRecords}</p>
        </div>
      )}
      {status === 'success' && softwareUpdate.countOfType < 1 && (
        <div>
          <p>{localeAuditTask[currentLocale].vulnsSoft.table.noRecords}</p>
        </div>
      )}
    </div>
  );
}
