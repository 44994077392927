import type { GridCellProps } from '@progress/kendo-react-grid';
import type {
  TaskAgentUpdateAction,
  TaskAuditAction,
  TaskGroupReportAction,
  TaskInventorizationAction,
  TaskSingleReportAction,
} from '../../../../../types/__generated/on-premise-solution/api/taskActionsDashboardResponse.v1';

export function CellStatus(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const {
    status,
  }:
    | TaskAuditAction
    | TaskInventorizationAction
    | TaskAgentUpdateAction
    | TaskGroupReportAction
    | TaskSingleReportAction = dataItem;

  return (
    <td>
      <div className="task__status" title={status}>
        {status === 'processing' && <span className="k-i-loader" />}
        {status === 'success' && <span className="k-icon k-i-check success" />}
        {status === 'fail' && <span className="k-icon k-i-close k-i-x fail" />}
        {status === 'timeout' && <span className="k-icon k-i-clock" />}
        {status === 'skipped' && <span className="k-icon k-i-chevron-double-right" />}
      </div>
    </td>
  );
}
