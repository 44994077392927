/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridHeaderCellProps } from '@progress/kendo-react-grid';
import styles from '../TaskList.module.scss';
import localeTask from '../../../../utils/i18n/taskLocale/task.json';
import { TaskType } from '../common/TaskType';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function ActionHeaderCell(props: GridHeaderCellProps): React.ReactElement {
  const { title, onClick, children } = props;

  return (
    <div onClick={onClick} className={`${styles.action_header_cell} k-cell-inner`}>
      {title}
      <span className={styles.types_info}>
        <div className={`${styles.types_info_content} k-link`}>
          <div className={styles.type_info}>
            <TaskType type="audit" />
            {localeTask[currentLocale].form.actionsValues.audit}
          </div>
          <div className={styles.type_info}>
            <TaskType type="audit_image" />
            {localeTask[currentLocale].form.actionsValues.audit_image}
          </div>
          <div className={styles.type_info}>
            <TaskType type="inventorization" />
            {localeTask[currentLocale].form.actionsValues.inventorization}
          </div>
          <div className={styles.type_info}>
            <TaskType type="agent_update" />
            {localeTask[currentLocale].form.actionsValues.agent_update}
          </div>
          <div className={styles.type_info}>
            <TaskType type="package_update" />
            {localeTask[currentLocale].form.actionsValues.package_update}
          </div>
          <div className={styles.type_info}>
            <TaskType type="windows_software_management" />
            {localeTask[currentLocale].form.actionsValues.windows_software_management}
          </div>
          <div className={styles.type_info}>
            <TaskType type="single_report" />
            {localeTask[currentLocale].form.actionsValues.single_report}
          </div>
          <div className={styles.type_info}>
            <TaskType type="group_report" />
            {localeTask[currentLocale].form.actionsValues.group_report}
          </div>
          <div className={styles.type_info}>
            <TaskType type="notification" />
            {localeTask[currentLocale].form.actionsValues.notificationTitle}
          </div>
          <div className={styles.type_info}>
            <TaskType type="bulletin_specific_analysis" />
            {localeTask[currentLocale].form.actionsValues.bulletin_specific_analysis}
          </div>
          <div className={styles.type_info}>
            <TaskType type="kb_install" />
            {localeTask[currentLocale].form.actionsValues.kb_install}
          </div>
          <div className={styles.type_info}>
            <TaskType type="asset_ldap_synchronize" />
            {localeTask[currentLocale].form.actionsValues.asset_ldap_synchronize}
          </div>
        </div>
        {children}
        <img
          src={`${process.env.PUBLIC_URL}/images/question.svg`}
          alt=""
          className="tooltip-question-images"
        />
      </span>
    </div>
  );
}
