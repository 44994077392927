/* eslint-disable no-nested-ternary */
import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  convertFromActionsToPeriods,
  convertFromPeriodsToActions,
  createWindowsListInfo,
  isPeriodsEq,
  TaskActionTypes,
  type IWindowData,
} from '../utils/helpers/maintenance-windows-helper';
import type { MaintenanceWindowsField } from '../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';

interface IMaintenanceWindowsContext {
  isTableOpen: boolean;
  setTableOpen: React.Dispatch<React.SetStateAction<boolean>>;
  entityId: React.MutableRefObject<string | undefined>;
  entityName: React.MutableRefObject<string | undefined>;
  entityType: React.MutableRefObject<'asset' | 'group' | 'account' | undefined>;
  isWindowOpen: boolean;
  isWindowEdit: boolean;
  setWindowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  editWindow: (wData?: IWindowData) => void;
  closeWindow: () => void;
  windowData: IWindowData | undefined;
  setWindowData: React.Dispatch<React.SetStateAction<IWindowData | undefined>>;
  windowsListData: IWindowData[];
  setWindowsListData: React.Dispatch<React.SetStateAction<IWindowData[]>>;
  setMaintenanceWindowsList: (data: MaintenanceWindowsField | undefined) => void;
  setPatchFunction: (
    f: ((maintenancePayload: MaintenanceWindowsField | undefined) => void) | undefined,
  ) => void;
  patchEntity: (period: IWindowData) => void;
  deleteEntitysPeriod: (period: IWindowData) => void;
  disableWindow: (period: IWindowData) => void;
  windowsListInfo: {
    [k in TaskActionTypes]?: number;
  };
  disableAllPeriods: () => void;
}

const MaintenanceWindowsContext = createContext<IMaintenanceWindowsContext | null>(null);

export function MaintenanceWindowsProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  // States
  const [isTableOpen, setTableOpen] = useState(false);
  const [isWindowOpen, setWindowOpen] = useState(false);
  const [isWindowEdit, setWindowEdit] = useState(false);
  const [windowsListData, setWindowsListData] = useState<IWindowData[]>([]);
  const [windowsListInfo, setWindowsListInfo] = useState<{
    [k in TaskActionTypes]?: number;
  }>({});
  const [windowData, setWindowData] = useState<IWindowData | undefined>(undefined);

  // Refs
  const entityId = useRef<string | undefined>(undefined);
  const entityName = useRef<string | undefined>(undefined);
  const entityType = useRef<'asset' | 'group' | 'account' | undefined>(undefined);
  const patchFunction = useRef<
    | ((maintenancePayload: MaintenanceWindowsField | undefined, entityId: string) => void)
    | undefined
  >(undefined);

  // Functions
  const setPatchFunction = (
    f: ((maintenancePayload: MaintenanceWindowsField | undefined) => void) | undefined,
  ): void => {
    patchFunction.current = f;
  };

  const editWindow = useCallback((wData?: IWindowData): void => {
    setWindowData(wData);
    setWindowOpen(true);
  }, []);

  const closeWindow = useCallback((): void => {
    setWindowOpen(false);
    setWindowData(undefined);
  }, []);

  const setMaintenanceWindowsList = (data: MaintenanceWindowsField | undefined): void => {
    setWindowsListData(convertFromActionsToPeriods(data));
    setWindowsListInfo(createWindowsListInfo(data));
  };

  const patchEntity = useCallback(
    (wData: IWindowData): void => {
      if (patchFunction.current && entityId.current) {
        const wldCopy = [...windowsListData];
        const periodIndex = wldCopy.findIndex((p) =>
          isPeriodsEq(p.period, windowData ? windowData.period : wData.period),
        );
        if (periodIndex > -1) wldCopy[periodIndex] = wData;
        else wldCopy.push(wData);
        patchFunction.current(convertFromPeriodsToActions(wldCopy), entityId.current);
      }
    },
    [windowsListData, windowData],
  );

  const disableWindow = useCallback(
    (period: IWindowData): void => {
      if (patchFunction.current && entityId.current)
        patchFunction.current(
          convertFromPeriodsToActions(
            windowsListData.map((p) =>
              isPeriodsEq(p.period, period.period)
                ? {
                    period: { ...p.period, isDisabled: !p.period.isDisabled },
                    taskActions: p.taskActions,
                  }
                : p,
            ),
          ),
          entityId.current,
        );
    },
    [windowsListData],
  );

  const deleteEntitysPeriod = useCallback(
    (period: IWindowData): void => {
      if (patchFunction.current && entityId.current)
        patchFunction.current(
          convertFromPeriodsToActions(
            windowsListData.filter((p) => !isPeriodsEq(p.period, period.period)),
          ),
          entityId.current,
        );
    },
    [windowsListData],
  );

  const disableAllPeriods = useCallback(() => {
    if (patchFunction.current && entityId.current)
      patchFunction.current(
        convertFromPeriodsToActions(
          windowsListData.map((wList) => ({
            ...wList,
            period: { ...wList.period, isDisabled: true },
          })),
        ),
        entityId.current,
      );
  }, [windowsListData]);

  const value = useMemo(
    () => ({
      isTableOpen,
      setTableOpen,
      entityId,
      entityName,
      entityType,
      isWindowOpen,
      editWindow,
      closeWindow,
      windowData,
      isWindowEdit,
      setWindowEdit,
      setWindowData,
      windowsListData,
      setWindowsListData,
      setMaintenanceWindowsList,
      setPatchFunction,
      patchEntity,
      deleteEntitysPeriod,
      disableWindow,
      windowsListInfo,
      disableAllPeriods,
    }),
    [
      closeWindow,
      deleteEntitysPeriod,
      disableAllPeriods,
      disableWindow,
      editWindow,
      isTableOpen,
      isWindowEdit,
      isWindowOpen,
      patchEntity,
      windowData,
      windowsListData,
      windowsListInfo,
    ],
  );

  return (
    <MaintenanceWindowsContext.Provider value={value}>
      {children}
    </MaintenanceWindowsContext.Provider>
  );
}

export const useMaintenanceWindowsContext = (): IMaintenanceWindowsContext => {
  const context = useContext(MaintenanceWindowsContext);
  if (context === null) {
    throw new Error(
      'useMaintenanceWindowsContext must be used inside the WebSocketContext.Provider.',
    );
  }

  return context;
};
