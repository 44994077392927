import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useState } from 'react';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import type {
  BulletinWhitelistDashboardResponse,
  WhitelistedBulletin,
} from '../../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import { BulletinsDescriptionList } from './BulletinsDescriptionList';
import { WhiteListDescriptionContentScope } from './WhiteListDescriptionContentScope';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function WhiteListDescriptionContent({
  scope,
  bulletins,
}: {
  scope: BulletinWhitelistDashboardResponse['scope'] | undefined;
  bulletins: WhitelistedBulletin[] | undefined;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const countBulletins = bulletins ? bulletins.length : 0;

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  return (
    <div className={styles.descriptionContent}>
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab
          title={`${localeSettings[currentLocale].whiteList.description.bulletins} (${countBulletins})`}
        >
          <BulletinsDescriptionList bulletins={bulletins} />
        </TabStripTab>
        {(scope?.assets ||
          scope?.assetGroups ||
          scope?.assetTypes ||
          scope?.assetDynamicGroups) && (
          <TabStripTab title={localeSettings[currentLocale].whiteList.description.scope}>
            <WhiteListDescriptionContentScope scope={scope} />
          </TabStripTab>
        )}
      </TabStrip>
    </div>
  );
}
