import { nanoid } from 'nanoid';
import { Button } from '../baseElements/Button';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useTaskAddContext } from '../../../hooks/useTaskAddContext';
import { useNetDeviceContext } from '../../../hooks/useNetDeviceContext';
import { useRegisterContext } from '../../../hooks/useRegisterContext';
import styles from './Form.module.scss';
import { useGroupContext } from '../../../hooks/useGroupContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function AssetsMutableBlock(): React.ReactElement {
  const { hostForm } = useHostModalContext();

  const { deleteAssetFromTask, deleteGrAssetFromTask } = useTaskAddContext();

  const { deviceForm, deleteDeviceFromTask } = useNetDeviceContext();

  const { imageForm, deleteImageFromTask, registryForm, deleteRegistryFromTask } =
    useRegisterContext();

  const { dynamicGroupForm, deleteDynamicGroupFromTask } = useGroupContext();

  return (
    <div className={styles.applyAssets}>
      {imageForm && imageForm.length > 0 && (
        <div className={styles.titleScopeAsset}>
          <span>{localeNewTask[currentLocale].form.applyForAsset.containerImages}</span>
        </div>
      )}
      <div className={styles.assetWrap}>
        {imageForm?.map((image) => {
          return (
            <div key={nanoid()} className={styles.assetContentWrap}>
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteImageFromTask(image.assetId, e)}
              >
                {image.imageNames || image.imageId}
              </Button>
            </div>
          );
        })}
      </div>
      {hostForm &&
        hostForm.filter((h) => !h.assetGroupId && h.assetId) &&
        hostForm.filter((h) => !h.assetGroupId && h.assetId).length > 0 && (
          <div className={styles.titleScopeAsset}>
            <span>{localeNewTask[currentLocale].form.applyForAsset.hosts}</span>
          </div>
        )}
      <div className={styles.assetWrap}>
        {hostForm
          ?.filter((h) => !h.assetGroupId && h.assetId)
          ?.map((h) => {
            return (
              <div key={nanoid()} className={styles.assetContentWrap}>
                <Button
                  type="button"
                  fill="task_asset"
                  onClick={(e): void => deleteAssetFromTask(h.assetId || '', e)}
                  key={nanoid()}
                >
                  {h.hostname || h.assetId}
                </Button>
              </div>
            );
          })}
      </div>
      {deviceForm && deviceForm.length > 0 && (
        <div className={styles.titleScopeAsset}>
          <span>{localeNewTask[currentLocale].form.applyForAsset.networkDevice}</span>
        </div>
      )}
      <div className={styles.assetWrap}>
        {deviceForm?.map((d) => {
          return (
            <div key={nanoid()} className={styles.assetContentWrap}>
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteDeviceFromTask(d.assetId, e)}
                key={nanoid()}
              >
                {d.hostname || d.assetId}
              </Button>
            </div>
          );
        })}
      </div>
      {registryForm && registryForm.length > 0 && (
        <div className={styles.titleScopeAsset}>
          <span>{localeNewTask[currentLocale].form.applyForAsset.registries}</span>
        </div>
      )}
      <div className={styles.assetWrap}>
        {registryForm?.map((reg) => {
          return (
            <div className={styles.itemWithIcon} key={nanoid()}>
              <span className={`k-icon images-menu-icon ${styles.scope_type}`} />
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteRegistryFromTask(reg.registryId, e)}
              >
                {reg.name || reg.registryId}
              </Button>
            </div>
          );
        })}
      </div>
      {hostForm &&
        hostForm.filter((g) => !g.assetId && g.assetGroupId) &&
        hostForm.filter((g) => !g.assetId && g.assetGroupId).length > 0 && (
          <div className={styles.titleScopeAsset}>
            <span>{localeNewTask[currentLocale].form.applyForAsset.staticGroups}</span>
          </div>
        )}
      <div className={styles.assetWrap}>
        {hostForm
          ?.filter((g) => !g.assetId && g.assetGroupId)
          .map((g) => {
            return (
              <div className={styles.itemWithIcon} key={nanoid()}>
                <span className={`k-icon host-groups-menu-icon ${styles.scope_type}`} />
                <Button
                  type="button"
                  fill="task_asset"
                  onClick={(e): void => deleteGrAssetFromTask(g.assetGroupId!, e)}
                  key={nanoid()}
                >
                  {g.name ? g.name : g.assetGroupId}
                </Button>
              </div>
            );
          })}
      </div>
      {dynamicGroupForm && dynamicGroupForm.length > 0 && (
        <div className={styles.titleScopeAsset}>
          <span>{localeNewTask[currentLocale].form.applyForAsset.dynamicGroups}</span>
        </div>
      )}
      <div className={styles.assetWrap}>
        {dynamicGroupForm?.map((gr) => {
          return (
            <div className={styles.itemWithIcon} key={nanoid()}>
              <span className={`k-icon host-groups-menu-icon ${styles.scope_type}`} />{' '}
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteDynamicGroupFromTask(gr.assetDynamicGroupId, e)}
              >
                {gr.name}
              </Button>
            </div>
          );
        })}
      </div>
      {(hostForm?.length === 0 || hostForm === null || !hostForm.find((h) => h.assetId)) &&
        (hostForm?.length === 0 || hostForm === null || !hostForm.find((g) => g.assetGroupId)) &&
        (deviceForm?.length === 0 || deviceForm === null || !deviceForm.find((d) => d.assetId)) &&
        (imageForm?.length === 0 || imageForm === null || !imageForm.find((im) => im.assetId)) &&
        (dynamicGroupForm?.length === 0 ||
          dynamicGroupForm === null ||
          !dynamicGroupForm.find((g) => g.assetDynamicGroupId)) &&
        (registryForm?.length === 0 ||
          registryForm === null ||
          !registryForm.find((r) => r.registryId)) && (
          <span className="placeholder">
            {localeNewTask[currentLocale].form.applyForAsset.assetListPlaceholderScope}
          </span>
        )}
    </div>
  );
}
