import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Filter } from '@progress/kendo-react-data-tools';
import { useEffect } from 'react';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { getUrlParamsHosts } from '../../../../utils/helpers/host-list-helper';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { useHostList } from '../../../../hooks/components/useHostList';
import { CommonHostList } from '../../../common/CommonHostList';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import { Input } from '../../../common/baseElements/Input';
import { FILTER_HOSTLIST } from '../../../../utils/helpers/constant-serv-filter-host';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';

export function GroupListDescription({
  dataAsset,
}: {
  dataAsset: string[] | null | undefined;
}): React.ReactElement {
  const urlParams = getUrlParamsHosts('GroupDescription');

  const dataForHooks: IDataHooks = {
    name: 'latestInventorization',
    componentName: document.location.pathname.includes('static')
      ? 'GroupDescription'
      : 'DynamicGroupDescription',
  };

  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHost;

  const {
    filter,
    onFilterChange,
    customSearch,
    sort,
    dataDashboard,
    page,
    handleCustomSearch,
    total,
    pageChange,
    setSort,
  } = useHostList(urlParams, dataForHooks, dataAsset);

  const { isOpen, setHostFormTemp } = useHostModalContext();

  useEffect(() => {
    if (isOpen && dataDashboard) {
      const assets = dataDashboard.map((a) => ({
        assetId: a.assetId,
        hostname:
          a.type === 'host' && a.latestInventorization?.hostname
            ? a.latestInventorization?.hostname
            : undefined,
      }));
      setHostFormTemp(assets);
    }
  }, [dataDashboard, isOpen, setHostFormTemp]);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_HOSTLIST[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeHost[currentLocale].customSearch}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <CommonHostList
        sort={sort}
        dataDashboard={dataDashboard}
        page={page}
        total={total}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={dataForHooks}
        conditionSort={15}
      />
    </ErrorBoundary>
  );
}
