import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useRef } from 'react';
import { handleFilteringTest, IFilterVal } from '../../utils/filtering-in-table-test';
import { regExpSpecSymbols } from '../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../utils/helpers/types';

export function useCustomSearch(
  setFilterStatus: (value: React.SetStateAction<boolean>) => void,
  setCustomSearch: (value: React.SetStateAction<string>) => void,
  filter: CompositeFilterDescriptor,
  setFilterVal: React.Dispatch<React.SetStateAction<IFilterVal[]>>,
  setPage: (value: React.SetStateAction<IPageState>) => void,
  page: IPageState,
  dataForHooks: IDataHooks,
): (e: React.ChangeEvent<HTMLInputElement>) => void {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debouncedCallback = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterStatus(true);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (!e.target.value) setCustomSearch('');
    setCustomSearch((prevState) =>
      !e.target.value.search(regExpSpecSymbols) ? prevState : e.target.value.trimStart(),
    );

    console.log({
      [dataForHooks.name]: {
        // $regex: e.target.value.trimStart(),
        $regex: e.target.value.trimStart(),
        $options: 'i',
      },
    });

    timer.current = setTimeout(() => {
      if (e.target.value === '') {
        handleFilteringTest(filter, setFilterVal);
        setFilterStatus(false);
      } else {
        setPage({ skip: 0, take: page.take });
        setFilterVal([
          {
            [dataForHooks.name]: {
              // $regex: e.target.value.trimStart(),
              $regex: e.target.value.trimStart(),
              $options: 'i',
            },
          },
        ]);
        setFilterStatus(false);
      }
    }, 800);
  };

  return debouncedCallback;
}
