import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';
import localeMaintenance from '../../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import styles from '../MaintenanceWindows.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeMaintenance;

export function MaintenancePeriodCell(props: GridCellProps): ReactElement {
  const { dataItem } = props;

  const hoursInfo = dataItem.period.hourRanges?.length
    ? `${dataItem.period.hourRanges.join(', ')} (UTC ${dataItem.period.utcOffset || '+00:00'})`
    : undefined;
  const weekdaysInfo = dataItem.period.dayOfWeekRanges?.length
    ? dataItem.period.dayOfWeekRanges
        .map((weekDayPeriod: string) =>
          weekDayPeriod
            .split('-')
            .map((weekdayNumber: string) =>
              weekdayNumber in localeMaintenance[currentLocale].weekDays
                ? localeMaintenance[currentLocale].weekDays[weekdayNumber as '1']
                : weekdayNumber,
            )
            .join('-'),
        )
        .join(', ')
    : undefined;
  const monthdaysInfo = dataItem.period.dayOfMonthRanges?.length
    ? `дни месяца: ${dataItem.period.dayOfMonthRanges.join(', ')}`
    : undefined;
  const monthsInfo = dataItem.period.monthRanges?.length
    ? dataItem.period.monthRanges
        .map((monthPeriod: string) =>
          monthPeriod
            .split('-')
            .map((monthNumber: string) =>
              monthNumber in localeMaintenance[currentLocale].months
                ? localeMaintenance[currentLocale].months[monthNumber as '1']
                : monthNumber,
            )
            .join('-'),
        )
        .join(', ')
    : undefined;

  const value = [hoursInfo, weekdaysInfo, monthdaysInfo, monthsInfo].filter((d) => d).join(' | ');

  return (
    <td title={value} className={dataItem?.period?.isDisabled ? styles.isDisabled : ''}>
      {value}
    </td>
  );
}
