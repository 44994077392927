/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { RadioButton } from '@progress/kendo-react-inputs';
import { useServerContext } from '../../../../../hooks/useServerContext';
import type { LdapGroupsResponse } from '../../../../../types/__generated/on-premise-solution/api/ldapGroupsResponse.v1';
import localeNewTask from '../../../../../utils/i18n/taskLocale/newTask.json';

export function CellWithouGroup({
  dataItem: { commonName },
}: Omit<GridCellProps, 'commonName'> & {
  dataItem: LdapGroupsResponse;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeNewTask;
  const { ldapGrForm, setLdapGrForm } = useServerContext();

  const handleChange = (): void => {
    const mutabledLdapGrForm = ldapGrForm?.map((g) => {
      if (g.ldapGroupName === commonName) {
        return {
          ...g,
          newGr: null,
          assetGr: null,
        };
      }

      return g;
    });

    if (mutabledLdapGrForm) setLdapGrForm(mutabledLdapGrForm);
  };

  return (
    <td
      title={
        !ldapGrForm || !ldapGrForm?.find((g) => g.ldapGroupName && g.ldapGroupName === commonName)
          ? localeNewTask[currentLocale].form.ldapTable.grid.newGrTooltip
          : ''
      }
    >
      <RadioButton
        // checked={
        //   !ldapGrForm?.find(
        //     (l) =>
        //       (l.commonName === commonName && l.newGr) ||
        //       (l.commonName === commonName && l.assetGr),
        //   )
        // }
        checked={
          !!ldapGrForm?.find((g) => g.ldapGroupName === commonName && !g.newGr && !g.assetGr)
        }
        onClick={handleChange}
        disabled={
          !ldapGrForm || !ldapGrForm?.find((g) => g.ldapGroupName && g.ldapGroupName === commonName)
        }
        style={{
          borderColor:
            !ldapGrForm ||
            !ldapGrForm?.find((g) => g.ldapGroupName && g.ldapGroupName === commonName)
              ? '#ccc'
              : '',
        }}
      />
    </td>
  );
}
