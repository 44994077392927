/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  createDynamicGroup,
  createHostGroup,
  deleteDynamicGroup,
  deleteHostAsset,
  editContentOfGroup,
} from '../../services/asset-service';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { AssetGroupCreationRequest } from '../../types/__generated/on-premise-solution/api/assetGroupCreationRequest.v1';
import type { AssetGroupCreationResponse } from '../../types/__generated/on-premise-solution/api/assetGroupCreationResponse.v1';
import type { AssetGroupDeletionResponse } from '../../types/__generated/on-premise-solution/api/assetGroupDeletionResponse.v1';
import type { AssetGroupUpdateRequest } from '../../types/__generated/on-premise-solution/api/assetGroupUpdateRequest.v1';
import type { AssetGroupUpdateResponse } from '../../types/__generated/on-premise-solution/api/assetGroupUpdateResponse.v1';
import type { Asset } from '../../types/__generated/on-premise-solution/api/asset.v1';
import type { IGroupHook } from '../../utils/helpers/types';
import { useGroupContext } from '../useGroupContext';
import { useHostModalContext } from '../useHostModalContext';
import type localeHostGroups from '../../utils/i18n/assetLocales/assetGroups.json';
import { useCommonContext } from '../useCommonContext';
import type { MaintenanceWindowsField } from '../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { useMaintenanceWindowsContext } from '../useMaintenanceWindowsContext';
import type { AssetDynamicGroupCreationRequest } from '../../types/__generated/on-premise-solution/api/assetDynamicGroupCreationRequest.v1';
import type { AssetDynamicGroupCreationResponse } from '../../types/__generated/on-premise-solution/api/assetDynamicGroupCreationResponse.v1';
import type { AssetDynamicGroupDeletionResponse } from '../../types/__generated/on-premise-solution/api/assetDynamicGroupDeletionResponse.v1';
import type { AssetDynamicGroupUpdateRequest } from '../../types/__generated/on-premise-solution/api/assetDynamicGroupUpdateRequest.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function useGroupHook(
  name: string | undefined,
  description: string | undefined | null,
  assetGroupId: string | null,
): IGroupHook {
  const queryClient = useQueryClient();

  const [typeOfChangeGr, setTypeOfChangeGr] = useState('');

  const history = useHistory();

  const [isConfirm, setConfirm] = useState(false);

  const [isDelHostFromGr, setDelHostFromGr] = useState(false);

  const {
    setHostId: setAssetId,
    setIsEdit,
    setIsOpen,
    setGroupData,
    isEdit,
    hostId,
    initialStateGr,
    groupName,
    setGroupName,
    groupDescription,
    setGroupDescription,
    setErrorMutation,
    dynamicGroupId,
    mutationEditDynamicGroup,
    filterInDynamicGrInListForEdit,
  } = useGroupContext();

  const { hostForm, hostFormTemp, setHostForm, setHostFormTemp, setOpen, isOpen } =
    useHostModalContext();

  const { setOpenForm } = useCommonContext();

  const { closeWindow: closeMaintenanceEditWindow, setPatchFunction } =
    useMaintenanceWindowsContext();

  const editGroup = (): void => {
    const data = {
      name: name || '',
      description,
    };
    setAssetId(assetGroupId);
    setIsEdit(true);
    setGroupData(data);
    setIsOpen(true);
  };

  const mutation = useMutation<AssetGroupDeletionResponse | null, ApiError, Asset['assetId']>(
    (payload) => deleteHostAsset(payload),
    {
      onSuccess: () => {
        setConfirm(false);
        queryClient.invalidateQueries('HostGroupList');
        history.push('/lk/assets/groups/static');
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
        setHostForm(null);
        setHostFormTemp(null);
      },
    },
  );

  const mutationDeleteDynamicGr = useMutation<
    AssetDynamicGroupDeletionResponse | null,
    ApiError,
    string
  >((payload) => deleteDynamicGroup(payload), {
    onSuccess: () => {
      setConfirm(false);
      queryClient.invalidateQueries('HostDynamicGroupList');
      history.push('/lk/assets/groups/dynamic');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
      setHostForm(null);
      setHostFormTemp(null);
    },
  });

  const mutationEditGroup = useMutation<
    AssetGroupUpdateResponse,
    ApiError,
    AssetGroupUpdateRequest & { assetGroupId: string }
  >((payload) => editContentOfGroup(payload), {
    onSuccess: () => {
      setHostForm(null);
      setHostFormTemp(null);
      setTypeOfChangeGr('');
      setDelHostFromGr(false);
      closeMaintenanceEditWindow();
      queryClient.invalidateQueries('hostGroupDescription');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationNewGroup = useMutation<
    AssetGroupCreationResponse,
    ApiError,
    AssetGroupCreationRequest
  >((payload) => createHostGroup(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('HostGroupList');
      history.push(`/lk/assets/groups/static/${res.assetGroupId}`);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationNewDynamicGroup = useMutation<
    AssetDynamicGroupCreationResponse,
    ApiError,
    AssetDynamicGroupCreationRequest
  >((payload) => createDynamicGroup(payload), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('HostDynamicGroupList');
      history.push(`/lk/assets/groups/dynamic/${res.assetDynamicGroupId}`);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (groupId: string): void => {
    mutation.mutate(groupId);
  };

  const handleDeleteDynamicGr = (assetDynamicGroupId: string): void => {
    mutationDeleteDynamicGr.mutate(assetDynamicGroupId);
  };

  const handleDelAssets = (): void => {
    setTypeOfChangeGr('delete');
    const data = [] as string[];
    hostFormTemp?.forEach((h) => {
      if (h.assetId) data.push(h.assetId);
    });
    mutationEditGroup.mutateAsync({
      assetIdsToDelete: data,
      assetGroupId,
      type: 'host' as const,
    } as unknown as AssetGroupUpdateRequest & { assetGroupId: string });
  };

  const addAssetToGroup = (): void => {
    setOpen(true);
    setTypeOfChangeGr('add');
  };

  useEffect(() => {
    if (typeOfChangeGr === 'add' && hostForm && hostForm.length > 0 && !isOpen) {
      const assetsData = [] as string[];
      hostForm?.forEach((h) => {
        if (h.assetId) assetsData.push(h.assetId);
      });

      mutationEditGroup
        .mutateAsync({
          assetIdsToAdd: assetsData,
          assetGroupId,
          type: 'host' as const,
        } as AssetGroupUpdateRequest & { assetGroupId: string })
        .then(() => {
          queryClient.invalidateQueries('hostGroupDescription');
        });
    }
  }, [isOpen]);

  useEffect(() => {
    function setMutationFunction(maintenancePayload: MaintenanceWindowsField | undefined): void {
      if (document.location.pathname.includes('groups/dynamic'))
        mutationEditDynamicGroup
          .mutateAsync({
            assetDynamicGroupId: assetGroupId || '',
            type: 'host',
            maintenanceWindows: maintenancePayload,
          })
          .then(() => closeMaintenanceEditWindow());
      else
        mutationEditGroup.mutateAsync({
          assetGroupId,
          type: 'host',
          maintenanceWindows: maintenancePayload,
        } as unknown as AssetGroupUpdateRequest & { assetGroupId: string });
    }
    setPatchFunction((maintenancePayload: MaintenanceWindowsField | undefined): void => {
      if (assetGroupId) {
        setMutationFunction(maintenancePayload);
      }
    });

    return () => {
      setPatchFunction(undefined);
    };
  }, [assetGroupId]);

  const handleWindow = (): void => {
    setIsOpen(false);
    setGroupData(null);
    setIsEdit(false);
    setOpenForm(false);
    setGroupName('');
    setGroupDescription('');
  };

  const handleSubmit = async (): Promise<void> => {
    if (isEdit) {
      const data = {
        name: initialStateGr?.name !== groupName && groupName ? groupName : undefined,
        description:
          groupDescription &&
          groupDescription.length > 0 &&
          initialStateGr?.description !== groupDescription
            ? groupDescription
            : groupDescription?.length === 0
            ? null
            : undefined,
      };
      await mutationEditGroup
        .mutateAsync({
          ...data,
          assetGroupId: hostId,
          type: 'host' as const,
        } as AssetGroupUpdateRequest & { assetGroupId: string })
        .then(() => queryClient.invalidateQueries('HostGroupList'));
    } else {
      const data = {
        name: groupName,
        description: groupDescription?.length === 0 ? undefined : groupDescription,
      };
      await mutationNewGroup.mutateAsync({
        accountId: localStorage.getItem('ai') as string,
        type: 'host',
        ...data,
      });
    }
    // setAssetId(null);
    // setIsOpen(false);
    // setGroupData(null);
    // setIsEdit(false);
    handleWindow();
  };

  const handleSubmitDynamicGr = async (): Promise<void> => {
    if (isEdit) {
      const data = {
        name: initialStateGr?.name !== groupName && groupName ? groupName : undefined,
        description:
          groupDescription &&
          groupDescription.length > 0 &&
          initialStateGr?.description !== groupDescription
            ? groupDescription
            : groupDescription?.length === 0
            ? null
            : undefined,
        filters: filterInDynamicGrInListForEdit,
      };
      await mutationEditDynamicGroup.mutateAsync({
        assetDynamicGroupId: dynamicGroupId,
        accountId: localStorage.getItem('ai') as string,
        type: 'host',
        ...data,
      } as AssetDynamicGroupUpdateRequest & { assetDynamicGroupId: string });
    } else {
      const data = {
        name: groupName,
        description: groupDescription?.length === 0 ? undefined : groupDescription,
        filters: [],
      };
      await mutationNewDynamicGroup.mutateAsync({
        accountId: localStorage.getItem('ai') as string,
        type: 'host',
        ...data,
      });
    }
    handleWindow();
  };

  return {
    isConfirm,
    setConfirm,
    editGroup,
    handleConfirm,
    handleDelete,
    isError: mutation.isError,
    errorMessage: mutation.error?.message,
    handleDelAssets,
    typeOfChangeGr,
    setTypeOfChangeGr,
    addAssetToGroup,
    handleSubmit,
    handleWindow,
    isDelHostFromGr,
    setDelHostFromGr,
    handleSubmitDynamicGr,
    handleDeleteDynamicGr,
  };
}
