import type { GridCellProps } from '@progress/kendo-react-grid';
import { useSoftwareManagementContext } from '../../../../hooks/useSoftwareManagementContext';
import { Button } from '../../../common/baseElements/Button';

export function VersionCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { setModalOpen, setSoftwareVersionInfo } = useSoftwareManagementContext();

  const handleClick = (): void => {
    setSoftwareVersionInfo(dataItem);
    setModalOpen(true);
  };

  return (
    <td>
      <Button fill="modal" onClick={handleClick}>
        {dataItem.version}
      </Button>
    </td>
  );
}
