import type { ReactElement } from 'react';
import { AddTask } from '../../features/Task/form/AddTask';
import { AboutModal } from '../../common/modal/AboutModal';
import { RelatedWhiteListModal } from '../../features/Task/taskVmDetails/vmDetailsComponents/RelatedWhiteListModal';
import { CredentialListModal } from '../../features/Credentials/modal/CredentialListModal';
import { AddNetDeviceForm } from '../../features/Devices/form/AddNetDeviceForm';
import { AddUserForm } from '../../features/User/modals/AddUserForm';
import { useTaskContext } from '../../../hooks/useTaskContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useCredentialContext } from '../../../hooks/useCredentialContext';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { AddHostFormWrap } from '../../features/Host/forms/AddHostFormWrap';
import { useHeaderContext } from '../../../hooks/useHeaderContext';
import { useHostCreateContext } from '../../../hooks/useHostCreateContext';
import { useNetDeviceContext } from '../../../hooks/useNetDeviceContext';
import { useUserModalContext } from '../../../hooks/useUserModalContext';

export function GlobalModals(): ReactElement {
  const { isOpenDialog } = useTaskContext();
  const { selectedCredential } = useCredentialContext();
  const { isEditPort } = useHostModalContext();
  const { isRelatedWhiteListModal } = useWhiteListContext();
  const { showAbout } = useHeaderContext();
  const { hostCreateData } = useHostCreateContext();
  const { isAddNetDevice, netDeviceData } = useNetDeviceContext();
  const { editUserData } = useUserModalContext();

  return (
    <>
      {isOpenDialog && <AddTask />}
      {showAbout && <AboutModal />}
      {isRelatedWhiteListModal && <RelatedWhiteListModal />}
      {!isEditPort && selectedCredential && <CredentialListModal />}
      {(isAddNetDevice || netDeviceData) && <AddNetDeviceForm />}
      {editUserData && <AddUserForm />}
      {hostCreateData?.assetType && typeof hostCreateData.hostname === 'undefined' && (
        <AddHostFormWrap />
      )}
    </>
  );
}
