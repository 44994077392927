import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { handleError } from '../../utils/errors';
import { handleFilteringTest, IFilterVal } from '../../utils/filtering-in-table-test';
import { fillFiltersHostList } from '../../utils/helpers/host-list-fill-filter-helper';
import type { IPageState } from '../../utils/helpers/types';
import { useNotificationContext } from '../useNotificationContext';
import { useFilterHostChange } from './useFilterHostChange';
import { usePageChange } from './usePageChange';
import { useHostModalContext } from '../useHostModalContext';
import { useStatesForList } from './useStatesForList';
import type { IUrlParamsList } from '../../utils/helpers/getUrl-list-helper';
import type { SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse } from '../../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdatesWithAvailableDashboardResponse.v1';
import { getSoftwareList } from '../../services/softManagement-service';
import { useCustomSearch } from './useCustomSearch';
import { useSoftwareManagementContext } from '../useSoftwareManagementContext';
import { useCommonContext } from '../useCommonContext';

interface IUseSoftwareList {
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleRefresh: () => void;
  filter: CompositeFilterDescriptor;
  setFilterVal: React.Dispatch<React.SetStateAction<IFilterVal[]>>;
  onFilterChange: (event: FilterChangeEvent) => void;
  customSearch: string;
  handleCustomSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sort: SortDescriptor[];
  softData: SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse['data'] | undefined;
  page: IPageState;
  total: number | undefined;
  softwareUpdateDownloadedCount: number | undefined;
  softwareUpdateApproveCount: number | undefined;
  pageChange: (event: GridPageChangeEvent) => void;
  setSort: React.Dispatch<React.SetStateAction<SortDescriptor[]>>;
  dataForHooks: {
    name: string;
    componentName: string;
  };
  isLoading: boolean;
  isFetching: boolean;
  filterStatus: boolean;
  isError: boolean;
  errorMessage: string | undefined;
  cancelAllAsset: () => void;
  handleExit: () => void;
  addSelectedAssets: () => void;
  cancelAssetTypeHost: () => void;
}

export function useSoftwareManagement(
  urlParams: IUrlParamsList,
  dataForHooks: {
    name: string;
    componentName: string;
  },
): IUseSoftwareList {
  const queryClient = useQueryClient();

  const {
    customSearch,
    setCustomSearch,
    sort,
    setSort,
    page,
    setPage,
    filter,
    setFilter,
    filterStatus,
    setFilterStatus,
    filterVal,
    setFilterVal,
    filterValue,
    filterRef,
  } = useStatesForList(urlParams);

  const [softData, setSoftData] = useState<
    SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse['data'] | undefined
  >([]);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { createNotification } = useNotificationContext();

  const { setOpen, hostFormTemp, setHostForm, setHostFormTemp } = useHostModalContext();

  const { selectedAccountId } = useSoftwareManagementContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilterHostChange(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    urlParams,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse, ApiError>(
    ['savedSoftwareList', page, filterVal, sort, selectedAccountId],
    () => getSoftwareList(page, filterVal, sort, undefined, undefined, selectedAccountId),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    fillFiltersHostList(urlParams, filterValue, setFilter, setFilterStatus);
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries('savedSoftwareList');
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter]);

  useEffect(() => {
    setSoftData(query.data?.data);
  }, [query.data?.data]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, createNotification, query.error]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  function handleCustomSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    debouncedCustomSearch(e);
  }

  const handleRefresh = (): void => {
    setFilterStatus(true);
    query.refetch().then(() => setFilterStatus(false));
  };

  const addSelectedAssets = (): void => {
    setHostForm(hostFormTemp);
    setOpen(false);
  };

  const cancelAllAsset = (): void => {
    setHostFormTemp(null);
    setHostForm(null);
  };

  const cancelAssetTypeHost = (): void => {
    const mutableAssetTypeHost = hostFormTemp?.filter((h) => h.assetGroupId);
    if (mutableAssetTypeHost) {
      setHostFormTemp(mutableAssetTypeHost);
      setHostForm(mutableAssetTypeHost);
    } else {
      setHostFormTemp(null);
      setHostForm(null);
    }
  };

  const handleExit = (): void => {
    setHostFormTemp(null);
    setHostForm(null);
    setOpen(false);
  };

  return {
    isModalOpen,
    setModalOpen,
    handleRefresh,
    filter,
    setFilterVal,
    onFilterChange,
    customSearch,
    handleCustomSearch,
    sort,
    softData,
    page,
    total: query.data?.total,
    softwareUpdateDownloadedCount: query.data?.softwareUpdateDownloadedCount,
    softwareUpdateApproveCount: query.data?.softwareUpdateApproveCount,
    pageChange,
    setSort,
    dataForHooks,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    filterStatus,
    isError: query.isError,
    errorMessage: query?.error?.message,
    cancelAllAsset,
    handleExit,
    addSelectedAssets,
    cancelAssetTypeHost,
  };
}
