import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_SORT_HOST, INITIAL_PAGING } from './constants';
import type {
  IDashboard,
  IDeviceForm,
  IHostForm,
  IPageState,
  IServerForm,
  TDynamicGroupForm,
} from './types';
import type {
  AssetDeviceDashboardResponse,
  AssetHypervisorDashboardResponse,
} from '../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetsHostItemDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';
import type { AssetGroupDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetGroupsDashboardResponse.v1';
import type { LdapResponse } from '../../types/__generated/on-premise-solution/api/ldapsResponse.v1';
import type { AssetDynamicGroupDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetDynamicGroupsDashboardResponse.v1';

export interface IUrlParamsHosts {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: object;
}
export function getUrlParamsAddHosts(
  pageLimitGridKey: string,
  sortValue?: SortDescriptor[],
): IUrlParamsHosts {
  const pageLimitGrid: IUrlParamsHosts['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc') && !sortValue
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT_HOST;

  const page: IPageState = {
    skip: INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {};

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}

export function handleAddAllAsset(
  dataDashboard: IDashboard[] | AssetsHostItemDashboardResponse[] | undefined,
  hostForm: IHostForm[] | null,
  setHostForm: (v: IHostForm[] | null) => void,
): void {
  const hostAllListForm = [] as IHostForm[];
  dataDashboard?.forEach((d) => {
    if (d.assetId) {
      hostAllListForm.push({
        assetId: d.assetId,
        hostname: d.latestInventorization ? d.latestInventorization.hostname : '',
      });
    }
  });
  if (hostForm && hostForm.length > 0) {
    const hostFormAndSelected = [...hostForm, ...hostAllListForm];
    const duplicatValChecked = hostFormAndSelected.filter(
      (host, i, arr) =>
        arr.findIndex(
          (val) =>
            val.hostname === host.hostname &&
            val.assetId === host.assetId &&
            val.assetGroupId === host.assetGroupId,
        ) === i,
    );

    setHostForm([...duplicatValChecked]);
  } else {
    setHostForm([...hostAllListForm]);
  }
}

export function handleAddAllDevices(
  devices: (AssetDeviceDashboardResponse | AssetHypervisorDashboardResponse)[] | undefined,
  deviceForm: IDeviceForm[] | null,
  setDeviceForm: (v: IDeviceForm[] | null) => void,
): void {
  const deviceAllListForm = [] as IDeviceForm[];

  devices?.forEach((d) => {
    if (d.latestInventorization) {
      deviceAllListForm.push({
        assetId: d.assetId,
        hostname: d.latestInventorization.hostname ? d.latestInventorization.hostname : null,
        ip: d.latestInventorization.ip,
      });
    }
  });
  if (deviceForm && deviceForm.length > 0) {
    const deviceFormAndSelected = [...deviceForm, ...deviceAllListForm];
    const duplicatValChecked = deviceFormAndSelected.filter(
      (host, i, arr) => arr.findIndex((val) => val.assetId === host.assetId) === i,
    );

    setDeviceForm([...duplicatValChecked]);
  } else {
    setDeviceForm([...deviceAllListForm]);
  }
}

export function handleAddAllGroups(
  groups: AssetGroupDashboardResponse[] | undefined,
  hostForm: IHostForm[] | null,
  setHostForm: (v: IHostForm[] | null) => void,
): void {
  const groupAllListForm = [] as IHostForm[];

  groups?.forEach((gr) => {
    if (gr.assetGroupId) {
      groupAllListForm.push({
        assetGroupId: gr.assetGroupId,
        name: gr.name,
      });
    }
  });
  if (hostForm && hostForm.length > 0) {
    const groupFormAndSelected = [...hostForm, ...groupAllListForm];
    const duplicatValChecked = groupFormAndSelected.filter(
      (gr, i, arr) =>
        arr.findIndex(
          (val) => val.assetGroupId === gr.assetGroupId && val.assetId === gr.assetId,
        ) === i,
    );

    setHostForm([...duplicatValChecked]);
  } else {
    setHostForm([...groupAllListForm]);
  }
}

export function handleAddAllDynamicGroups(
  groups: AssetDynamicGroupDashboardResponse[] | undefined,
  dynamicGroupForm: TDynamicGroupForm[] | null,
  setDynamicGroupForm: React.Dispatch<React.SetStateAction<TDynamicGroupForm[] | null>>,
): void {
  const groupAllListForm = [] as {
    name: string;
    assetDynamicGroupId: string;
  }[];

  groups?.forEach((gr) => {
    if (gr.assetDynamicGroupId) {
      groupAllListForm.push({
        assetDynamicGroupId: gr.assetDynamicGroupId,
        name: gr.name,
      });
    }
  });
  if (dynamicGroupForm && dynamicGroupForm.length > 0) {
    const groupFormAndSelected = [...dynamicGroupForm, ...groupAllListForm];
    const duplicatValChecked = groupFormAndSelected.filter(
      (gr, i, arr) =>
        arr.findIndex((val) => val.assetDynamicGroupId === gr.assetDynamicGroupId) === i,
    );

    setDynamicGroupForm([...duplicatValChecked]);
  } else {
    setDynamicGroupForm([...groupAllListForm]);
  }
}

export function handleAddAllServers(
  servers: LdapResponse[] | undefined,
  serverForm: IServerForm[] | null,
  setServerForm: (v: IServerForm[] | null) => void,
): void {
  const serverAllListForm = [] as IServerForm[];

  servers?.forEach((s) => {
    if (s.ldapId) {
      serverAllListForm.push({
        ldapId: s.ldapId,
        name: s.name,
      });
    }
  });
  if (serverForm && serverForm.length > 0) {
    const serverFormAndSelected = [...serverForm, ...serverAllListForm];
    const duplicatValChecked = serverFormAndSelected.filter(
      (s, i, arr) =>
        arr.findIndex((val) => val.ldapId === s.ldapId && val.ldapId === s.ldapId) === i,
    );

    setServerForm([...duplicatValChecked]);
  } else {
    setServerForm([...serverAllListForm]);
  }
}
