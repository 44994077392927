/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useState } from 'react';
import localeMaintenance from '../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import { MaintenanceMenuCell } from '../features/Host/MaintenanceWindows/maintenanceCells/MaintenanceMenuCell';
import { MaintenanceActionsCell } from '../features/Host/MaintenanceWindows/maintenanceCells/MaintenanceActionsCell';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import { MaintenancePeriodCell } from '../features/Host/MaintenanceWindows/maintenanceCells/MaintenancePeriodCell';
import { ActionHeaderCell } from '../features/Task/cellsTaskList/ActionHeaderCell';
import type { IWindowData } from '../../utils/helpers/maintenance-windows-helper';
import { MaintenanceGroupnameCell } from '../features/Host/MaintenanceWindows/maintenanceCells/MaintenanceGroupnameCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeMaintenance;

export function CommonMaintenanceList({
  data,
  isGroupData,
  isShort,
  disableMenu,
}: {
  data: IWindowData[];
  isGroupData?: boolean;
  isShort?: boolean;
  disableMenu?: boolean;
}): React.ReactElement {
  const [page, setPage] = useState({ take: 15, skip: 0 });

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <div className="maintenance-windows-list">
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            pageable={data.length > 10 ? PAGEABLE_DATA : false}
            data={data.slice(page.skip, page.take + page.skip)}
            take={page.take}
            total={data.length || 0}
            scrollable="none"
            pageSize={page.take}
            skip={page.skip}
            onPageChange={pageChange}
            className={`no-margin-top ${
              (!data.length || data.length < 10) && !isShort ? 'no-pageable-k-grid' : ''
            }`}
          >
            <Column
              field="period"
              title={localeMaintenance[currentLocale].table.period}
              filter="text"
              cell={MaintenancePeriodCell}
              width={isShort ? 200 : 480}
            />
            <Column
              title={localeMaintenance[currentLocale].table.actions}
              cell={MaintenanceActionsCell}
              headerCell={isShort ? undefined : ActionHeaderCell}
              width={isGroupData ? 360 : 'unset'}
            />
            {isGroupData && (
              <Column
                title={localeMaintenance[currentLocale].table.group}
                cell={MaintenanceGroupnameCell}
              />
            )}
            {!isShort && !disableMenu && (
              <Column title=" " field="" cell={MaintenanceMenuCell} width="40px" />
            )}
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
