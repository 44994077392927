import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import styles from '../../ContainerOfGroups/containerOfGroups.module.scss';
import { Button } from '../../../common/baseElements/Button';
import type { TSetDisplayBlock } from '../../../../utils/helpers/types';

export function DynamicGroupFilterActions({
  setDisplayBlock,
  isFilterValue,
  isFilterKey,
  filter,
  setFilter,
  setEnabledCancelBtn,
}: {
  setDisplayBlock: TSetDisplayBlock;
  isFilterValue: boolean;
  isFilterKey: string;
  filter: CompositeFilterDescriptor;
  setFilter: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor>>;
  setEnabledCancelBtn: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
  const showFilterBlockBelow = (): void => {
    setEnabledCancelBtn(true);

    setDisplayBlock((prevState) => ({ ...prevState, [isFilterKey]: true }));

    setFilter({
      logic: 'and',
      filters: [{ field: 'latestInventorization.hostname', operator: 'contains', value: '' }],
    });
  };

  return (
    <div className={styles.dynamicGroupFilterActinsWrap}>
      <div className={styles.btnOrWrap}>
        <Button onClick={showFilterBlockBelow} className={styles.saveFilters}>
          OR
        </Button>
        <div className={styles.divLine} />
      </div>
      {isFilterValue && filter && filter.filters && filter.filters.length === 0 && (
        <Button
          onClick={(): void =>
            setDisplayBlock((prevState) => ({ ...prevState, [isFilterKey]: false }))
          }
          className={styles.btnCloseOr}
        >
          X
        </Button>
      )}
    </div>
  );
}
