import type localeVmTask from '../../utils/i18n/taskLocale/vmTask.json';
import type { TaskActionDashboardResponse } from '../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';
import { FirstColumn } from './metaBlockTables/FirstColumn';
import { SecondColumn } from './metaBlockTables/SecondColumn';
import { ThirdColumn } from './metaBlockTables/ThirdColumn';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;
export function MetaBlock({
  taskAction,
}: {
  taskAction: TaskActionDashboardResponse;
}): React.ReactElement {
  return (
    <div className="common-summary-page">
      <FirstColumn currentLocale={currentLocale} taskAction={taskAction} />
      <SecondColumn currentLocale={currentLocale} taskAction={taskAction} />
      {document.location.pathname.includes('kb_install') && (
        <ThirdColumn currentLocale={currentLocale} taskAction={taskAction} />
      )}
    </div>
  );
}
