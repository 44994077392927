/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import styles from './TaskType.module.scss';
import localeTask from '../../../../utils/i18n/taskLocale/task.json';
import type { TaskActionTypes } from '../../../../utils/helpers/maintenance-windows-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

const typeA = {
  audit: 'V',
  audit_image: 'V',
  agent_update: 'A',
  inventorization: 'I',
  package_update: 'U',
  group_report: 'R',
  single_report: 'R',
  bulletin_specific_analysis: 'A',
  notification: 'E',
  kb_install: 'KB',
  asset_ldap_synchronize: 'S',
  inventorization_container_registry: '',
  bulletin_specific_audit: '',
  system_reboot: '',
  windows_software_management: 'SM',
};

export function TaskType({
  title,
  type,
}: {
  title?: boolean;
  type:
    | 'audit'
    | 'audit_image'
    | 'agent_update'
    | 'inventorization'
    | 'package_update'
    | 'group_report'
    | 'single_report'
    | 'bulletin_specific_analysis'
    | 'notification'
    | 'kb_install'
    | 'asset_ldap_synchronize'
    | 'windows_software_management'
    | TaskActionTypes;
}): React.ReactElement {
  return (
    <span
      className={`${styles.task_type} ${styles[type]}`}
      title={title ? localeTask[currentLocale].form.actionsValues[type] : ''}
    >
      {typeA[type]}
    </span>
  );
}
