import { nanoid } from 'nanoid';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function PkgUpdateBlockTask(): React.ReactElement {
  const { hostForm, selectedPkgs, selectedPkgsWl, isPkgWlTask } = useHostModalContext();

  const { taskPackageUpdate } = useTaskAddContext();

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        {hostForm && (
          <div>
            <span>{localeNewTask[currentLocale].form.applyForAsset.titleUpdatePkgsName}</span>
          </div>
        )}
      </div>
      <div className="list-assets">
        {!isPkgWlTask &&
          taskPackageUpdate &&
          selectedPkgs?.map((p, i) => {
            return (
              <span key={nanoid()}>
                {p.name}
                {selectedPkgs.length - 1 !== i && ', '}
              </span>
            );
          })}
        {isPkgWlTask &&
          taskPackageUpdate &&
          selectedPkgsWl?.map((p, i) => {
            return (
              <span key={nanoid()}>
                {p.name}
                {selectedPkgsWl.length - 1 !== i && ', '}
              </span>
            );
          })}
      </div>
    </div>
  );
}
