import type { GridCellProps } from '@progress/kendo-react-grid';
import style from '../ContainerOfGroups/containerOfGroups.module.scss';
import { useGroupContext } from '../../../hooks/useGroupContext';
import localeHostGroups from '../../../utils/i18n/assetLocales/assetGroups.json';

export function CellDynamicGroupAssetsInfo(props: GridCellProps): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHostGroups;

  const { dataItem } = props;

  const { markForStringOfDynamicGr } = useGroupContext();

  return (
    <td>
      <div className={style.latestAssetsCountWrap}>
        <span
          className={
            markForStringOfDynamicGr &&
            markForStringOfDynamicGr.includes(dataItem.assetDynamicGroupId)
              ? ''
              : style.latestAssetsCountValue
          }
        >
          {dataItem.latestAssets.count || dataItem.latestAssets.count === 0
            ? dataItem.latestAssets.count
            : '-'}
        </span>{' '}
        <span>
          <span
            className={
              markForStringOfDynamicGr &&
              markForStringOfDynamicGr.includes(dataItem.assetDynamicGroupId)
                ? ''
                : style.latestAssetsCountDate
            }
            title={localeHostGroups[currentLocale].form.latestAssetsCountDate}
          >
            ({new Date(dataItem.latestAssets.refreshedAt).toLocaleString()})
          </span>
        </span>
      </div>
    </td>
  );
}
