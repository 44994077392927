import { RadioButton } from '@progress/kendo-react-inputs';
import type { ReactElement } from 'react';
import type { GridCellProps } from '@progress/kendo-react-grid';
import { useSoftwareManagementContext } from '../../../../hooks/useSoftwareManagementContext';

export function RadioCell(props: GridCellProps): ReactElement {
  const { dataItem } = props;
  const { selectedSoftwareForUpdate, setSelectedSoftwareForUpdate, removeSoftware } =
    useSoftwareManagementContext();

  const addSoftwareToPayload = (): void => {
    if (selectedSoftwareForUpdate[dataItem.softwareCodename]?.fullId === dataItem.fullId)
      removeSoftware(dataItem.softwareCodename);
    else if (dataItem.softwareCodename) {
      setSelectedSoftwareForUpdate((current) => ({
        ...current,
        [dataItem.softwareCodename]: {
          fullId: dataItem.fullId,
          softwareName: dataItem.softwareName,
          language: dataItem.language,
          arch: dataItem.arch,
          version: dataItem.version,
        },
      }));
    }
  };

  return (
    <td>
      <RadioButton
        disabled={dataItem.downloadStatus !== 'downloaded' || !dataItem.isApproved}
        checked={
          dataItem.fullId &&
          dataItem.softwareCodename &&
          selectedSoftwareForUpdate[dataItem.softwareCodename]?.fullId === dataItem.fullId
        }
        // onChange={addSoftwareToPayload}
        onClick={addSoftwareToPayload}
      />
    </td>
  );
}
