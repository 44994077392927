/* eslint-disable react/jsx-pascal-case */
import { Typography } from '@progress/kendo-react-common';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import type { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { TabsGroups } from './TabsGroups';
import localeHostGroups from '../../../utils/i18n/assetLocales/assetGroups.json';
import styles from './containerOfGroups.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;
export function ContainerOfGroups(): React.ReactElement {
  const history = useHistory();

  const [selected, setSelected] = useState<number>(
    document.location.pathname.includes('/static') ? 0 : 1,
  );

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0 && !document.location.pathname.includes('/static')) {
        history.replace('/lk/assets/groups/static');
        setSelected(e.selected);
      }

      if (e.selected === 1 && !document.location.pathname.includes('/dynamic')) {
        history.replace('/lk/assets/groups/dynamic');
        setSelected(e.selected);
      }

      setSelected(e.selected);
    },
    [history],
  );

  return (
    <div className={styles.containerOfGroups}>
      <div className="common-header-page">
        <Typography.h3>{localeHostGroups[currentLocale].containerOfGr.title}</Typography.h3>
      </div>
      <TabsGroups selected={selected} handleSelect={handleSelect} />
    </div>
  );
}
