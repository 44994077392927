/* eslint-disable react/jsx-pascal-case */
import { useCallback } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../baseElements/Button';
import localeHost from '../../../utils/i18n/assetLocales/assetGroups.json';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { getUrlHostGr } from '../../../utils/helpers/host-group-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { CommonGroupList } from '../CommonGroupList';
import { useGroupContext } from '../../../hooks/useGroupContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function DynamicGroupListForForm({
  parentComponent,
}: {
  parentComponent?: string;
}): React.ReactElement {
  const urlParams = getUrlHostGr('DynamicGroupListForForm');

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'DynamicGroupListForForm',
  };

  const { setOpenDynamicGroupList } = useHostModalContext();

  const { setDynamicGroupForm, dynamicGroupForm } = useGroupContext();

  const handleExit = useCallback(() => {
    setDynamicGroupForm(null);

    setOpenDynamicGroupList(false);
  }, [setDynamicGroupForm, setOpenDynamicGroupList]);

  const addSelectedGroups = useCallback((): void => {
    setDynamicGroupForm(dynamicGroupForm);
    setOpenDynamicGroupList(false);
  }, [setDynamicGroupForm, dynamicGroupForm, setOpenDynamicGroupList]);

  return (
    <Dialog
      title={localeHost[currentLocale].dialogTitleDynamic}
      onClose={(): void => setOpenDynamicGroupList(false)}
      className="hostgroup-list modal"
    >
      <div className="k-form">
        <div className="form-content groups">
          <CommonGroupList
            dataForHooks={dataForHooks}
            urlParams={urlParams}
            parentComponent={parentComponent}
          />
        </div>
        <div className="k-form-buttons">
          <Button
            type="submit"
            disabled={
              (dynamicGroupForm && dynamicGroupForm.length === 0) ||
              (!dynamicGroupForm && dynamicGroupForm === null)
            }
            onClick={addSelectedGroups}
          >
            {localeNewTask[currentLocale].form.btnAddAsset}{' '}
            {dynamicGroupForm &&
              dynamicGroupForm?.length > 0 &&
              `(${dynamicGroupForm.filter((h) => h.assetDynamicGroupId)?.length})`}
          </Button>
          <Button type="button" onClick={handleExit}>
            {localeNewTask[currentLocale].form.btnCancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
