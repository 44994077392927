import React, { createContext, useMemo, useContext, useRef, useEffect } from 'react';
import { refresh } from '../services/auth';
import { getExpiresToken, getRefreshToken, setTokens } from '../services/local-storage-service';
import type { CentrifugoPublishToUser } from '../types/__generated/on-premise-solution/websocket/centrifugoPublishToUser.v1';
import { WEBSOCKET_URL } from '../utils/helpers/constants';
import type { IChildrenNodes } from '../utils/helpers/types';
import { useAuthContext } from './useAuthContext';

export interface IWebSocketContent {
  ws: React.MutableRefObject<WebSocket | null>;
}

export interface IWebSocketResultCommand {
  result: {
    channel: string;
    data: {
      data: CentrifugoPublishToUser;
    };
  };
}

function refreshWsToken(ws: React.MutableRefObject<WebSocket | null>): NodeJS.Timer | null {
  const expiresDate = getExpiresToken();
  const countInt = Number(expiresDate) - Date.now() - 30000;
  const refreshInterval = setInterval(async () => {
    const data = await refresh();
    setTokens(data);
    const dataRefresh = {
      id: 4,
      method: 10,
      reconnect: true,
      maxRetry: 270000,
      params: {
        token: data.accessToken,
      },
    };
    const json = JSON.stringify(dataRefresh);
    ws.current?.send(json);
  }, countInt);

  return refreshInterval;
}

const WebSocketContext = createContext<IWebSocketContent | null>(null);

export function WebSocketProvider({ children }: IChildrenNodes): React.ReactElement {
  const ws = useRef<WebSocket | null>(null);
  const { accessToken, setAccessToken } = useAuthContext();

  useEffect(() => {
    let rInterval: NodeJS.Timer | null = null;
    // let closeTimeout: NodeJS.Timer | null = null;

    async function createChannel(): Promise<void> {
      const refreshToken = getRefreshToken();
      if (!accessToken && refreshToken) {
        const data = await refresh();
        setTokens(data);
        setAccessToken(data.accessToken);
      }
      if (accessToken) {
        ws.current = new WebSocket(WEBSOCKET_URL);

        ws.current.onclose = async (e): Promise<void> => {
          if (rInterval) clearInterval(rInterval);
          if (e.code === 1006) createChannel();
        };

        ws.current.onopen = async (): Promise<void> => {
          // if (closeTimeout) clearInterval(closeTimeout);
          rInterval = refreshWsToken(ws);
          const data = {
            id: 1,
            method: 0,
            reconnect: true,
            maxRetry: 270000,
            params: {
              token: accessToken,
            },
          };
          const json = JSON.stringify(data);
          ws.current?.send(json);
        };
        ws.current.onerror = (e): void => {
          // console.log('Error!:', e);
        };
      }
    }
    createChannel();

    return () => {
      if (rInterval) {
        clearInterval(rInterval);
      }
    };
  }, [accessToken, setAccessToken]);

  const value = useMemo(
    () => ({
      ws,
    }),
    [ws],
  );

  return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
}
export const useWebSocketContext = (): IWebSocketContent => {
  const context = useContext(WebSocketContext);
  if (context === null) {
    throw new Error('WebSocketContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
