import { nanoid } from 'nanoid';
import { Button } from '../baseElements/Button';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useTaskAddContext } from '../../../hooks/useTaskAddContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function GroupMutableBlock(): React.ReactElement {
  const { hostForm, setOpenGroupList } = useHostModalContext();
  const { deleteGrAssetFromTask } = useTaskAddContext();

  return (
    <div className="apply-assets-group">
      <div className="title-apply-assets">
        <span>{localeNewTask[currentLocale].form.applyForAsset.titleGroup}</span>
        <Button onClick={(): void => setOpenGroupList(true)} type="button">
          {localeNewTask[currentLocale].form.addGroup}
        </Button>
      </div>
      <div className="list-assets">
        {hostForm?.map((h) => {
          if (h.name) {
            return (
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteGrAssetFromTask(h.assetGroupId!, e)}
                key={nanoid()}
              >
                {h.name}
              </Button>
            );
          }

          return null;
        })}
        {(hostForm?.length === 0 || hostForm === null || !hostForm.find((g) => g.assetGroupId)) && (
          <span className="placeholder">
            {localeNewTask[currentLocale].form.applyForAsset.assetGroupPlaceholder}
          </span>
        )}
      </div>
    </div>
  );
}
