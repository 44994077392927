/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';
import type localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import { AddCredentialForm } from '../../Credentials/form/AddCredentialForm';
import { TableFDeviceMetaBlock } from './tables/TableFDeviceMetaBlock';
import { TableSDevicetMetaBlock } from './tables/TableSDeviceMetaBlock';
import type { AssetRouterDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import type {
  AssetFirewallDashboardResponse,
  AssetHypervisorDashboardResponse,
} from '../../../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetSwitchDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetDeviceDashboardResponse.v1';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { TableTDeviceMetaBlock } from './tables/TableTDeviceMetaBlock';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { TableFourthDeviceMetaBlock } from './tables/TableFourthDeviceMetaBlock';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;
export function DeviceMetaBlock({
  data,
}: {
  data:
    | AssetRouterDashboardResponse
    | AssetFirewallDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse
    | undefined;
}): React.ReactElement {
  const { isAddCredential, credentialData, isEdit } = useCredentialContext();

  const { setShowImportance, setShowPerimeterImpact } = useAssetCreatePropContext();

  const rootDiv = document.getElementsByClassName('common-summary-page');

  const hideDropDownList = (): void => {
    setShowImportance(false);
    setShowPerimeterImpact(false);
  };

  const keyDownHandler = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      event.preventDefault();

      setShowImportance(false);
      setShowPerimeterImpact(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    if (rootDiv[0]) {
      rootDiv[0].addEventListener('click', hideDropDownList);
      rootDiv[0].addEventListener('keydown', hideDropDownList);
    }

    return () => {
      if (rootDiv[0]) {
        rootDiv[0].removeEventListener('click', hideDropDownList);
      }
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className="common-summary-page">
      <TableFDeviceMetaBlock data={data} currentLocale={currentLocale} />
      <TableSDevicetMetaBlock data={data} currentLocale={currentLocale} />
      <TableTDeviceMetaBlock data={data} currentLocale={currentLocale} />
      <TableFourthDeviceMetaBlock data={data} currentLocale={currentLocale} />
      {(isAddCredential || (credentialData && isEdit)) && <AddCredentialForm />}
    </div>
  );
}
