/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import styles from '../../Settings/ApiKeys/ApiKeyList.module.scss';
import localeHostGroups from '../../../../utils/i18n/assetLocales/assetGroups.json';

export function CellDynamicGRAssetWhiteList(props: GridCellProps): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHostGroups;

  const { dataItem } = props;

  return (
    <td>
      <div className={styles.latestAssetsCountInWlWrap}>
        <span>
          {dataItem.latestAssets &&
          'count' in dataItem.latestAssets &&
          (dataItem.latestAssets.count || dataItem.latestAssets.count === 0)
            ? dataItem.latestAssets.count
            : '-'}
        </span>{' '}
        <span>
          {dataItem.latestAssets && 'refreshedAt' in dataItem.latestAssets ? (
            <span title={localeHostGroups[currentLocale].form.latestAssetsCountDate}>
              ({new Date(dataItem.latestAssets.refreshedAt).toLocaleString()})
            </span>
          ) : (
            <span>-</span>
          )}
        </span>
      </div>
    </td>
  );
}
