import { useCallback, useEffect, useRef, useState } from 'react';
import type { TaskActionDashboardResponse } from '../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';

export interface IPartialResults {
  resultSequenceNumber: number | null;
  hasNextResult: boolean;
  stage: string | null;
  log: string;
  type: 'package_update' | 'kb_install';
}

export interface IPartialResPkg {
  resultSequenceNumber: number | null;
  hasNextResult: boolean;
  command: string;
  output: string;
  type: 'package_update' | 'kb_install';
}

export interface IPartialResSm {
  resultSequenceNumber: number | null;
  hasNextResult: boolean;
  log: string;
  type: 'package_update' | 'kb_install' | 'windows_software_management';
}

export function useScrollLog(
  parentRef: React.RefObject<HTMLHeadingElement>,
  childRef: React.RefObject<HTMLHeadingElement>,
  data: TaskActionDashboardResponse | undefined,
): React.MutableRefObject<(IPartialResPkg | IPartialResults | IPartialResSm)[] | null> {
  const dataState = useRef<(IPartialResults | IPartialResPkg | IPartialResSm)[] | null>(null);

  const pageValue =
    data &&
    (data.type === 'kb_install' || data.type === 'package_update') &&
    data.partialResults?.data &&
    data.status !== 'success' &&
    Array.isArray(data.partialResults?.data) &&
    data.partialResults?.data.length > 0
      ? data.partialResults?.data.length
      : 5;

  const [page, setPage] = useState(pageValue);

  if (childRef.current && data?.status === 'success') {
    childRef.current.scrollIntoView();
  }

  const slicedResultData = useCallback(() => {
    if (
      data &&
      (data.type === 'kb_install' || data.type === 'package_update') &&
      data.partialResults.hasPartialResults !== false &&
      data.partialResults?.data &&
      Array.isArray(data.partialResults?.data)
    ) {
      const resultData = data.partialResults?.data
        ?.slice(0, page)
        .map((r) => ({ ...r, type: data.type }));
      if (resultData && resultData.length > 0) {
        // setDataState([...resultData]);
        dataState.current = [...resultData];
        if (
          data.status !== 'processing' &&
          dataState.current?.length !== data.partialResults?.data.length
        ) {
          setPage((prev) => prev + 5);
        }
        if (
          childRef.current &&
          data.status === 'processing' &&
          dataState.current?.length !== data.partialResults?.data.length
        ) {
          setPage((prev) => prev + 5);
        }
      }
    }
    if (
      data &&
      data.type === 'kb_install' &&
      data.status === 'success' &&
      !data.partialResults.hasPartialResults &&
      data.result &&
      data.result.data &&
      data.result.data.log
    ) {
      dataState.current = null;
    }
    if (
      data &&
      data.type === 'package_update' &&
      data.status === 'success' &&
      !data.partialResults.hasPartialResults &&
      data.result &&
      data.result.data &&
      data.result.data[0].output
    ) {
      dataState.current = null;
    }
  }, [childRef, data, dataState, page]);

  const observer = useRef<any>(null);

  useEffect(() => {
    const options = {
      root: parentRef.current,
      rootMargin: '0px',
      threshold: 0,
    };
    observer.current = new IntersectionObserver(([target]) => {
      if (target.isIntersecting) {
        slicedResultData();
      }
    }, options);

    observer.current.observe(childRef.current);

    return (): void => {
      observer.current.unobserve(childRef.current);
    };
  }, [childRef, slicedResultData, parentRef, data]);

  return dataState;
}
