import React, { useCallback, useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useHistory } from 'react-router';
import localePkgUpdate from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { ISoftwareManagement } from '../../../../utils/helpers/task-helpers';
import { TaskSmResult } from './TaskSmResult';
import { TaskSmLog } from './TaskSmLog';
import type { EnrichedTaskActionResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedTaskActionResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localePkgUpdate;

loadMessages(localePkgUpdate[currentLocale], currentLocale);

export function TabsSmDetails({
  taskActionId,
  softwareUpdate,
  hostName,
  checkConsoleData,
  status,
}: {
  taskActionId: string;
  softwareUpdate: ISoftwareManagement;
  hostName: string | false | undefined;
  checkConsoleData: boolean;
  status: EnrichedTaskActionResponse['status'];
}): React.ReactElement {
  const history = useHistory();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0) {
        history.replace(`/lk/task/actions/${taskActionId}/software_management/result`);
        setSelected(e.selected);
      }
      if (e.selected === 1 && !document.location.pathname.includes('/logs')) {
        history.replace(`/lk/task/actions/${taskActionId}/software_management/logs`);
        setSelected(e.selected);
      }
    },
    [history, taskActionId],
  );

  return (
    <TabStrip
      selected={document.location.pathname.includes('/result') ? 0 : 1}
      onSelect={handleSelect}
    >
      <TabStripTab title={localePkgUpdate[currentLocale].pkgResult}>
        <TaskSmResult softwareUpdate={softwareUpdate} hostName={hostName} status={status} />
      </TabStripTab>
      {checkConsoleData && (
        <TabStripTab title={localePkgUpdate[currentLocale].logs}>
          <TaskSmLog taskActionId={taskActionId} />
        </TabStripTab>
      )}
    </TabStrip>
  );
}
