import { Dialog } from '@progress/kendo-react-dialogs';
import localeAuditTask from '../../../utils/i18n/taskLocale/auditTask.json';
import styles from '../../features/SoftwareManagement/SoftwareManagement.module.scss';
import { CommonSoftManList } from '../CommonSoftManagementList';
import { useTaskAddContext } from '../../../hooks/useTaskAddContext';
import { Button } from '../baseElements/Button';
import { useSoftwareManagementContext } from '../../../hooks/useSoftwareManagementContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;
export function SoftwareListModal(): React.ReactElement {
  const { setSoftwareManagement } = useTaskAddContext();

  const { setSelectedSoftwareForUpdate, selectedSoftwareForUpdate } =
    useSoftwareManagementContext();

  const {
    winSoftwareUpdateState,
    setWinSoftwareUpdateState,
    winSoftwareUpdateStateWl,
    setWinSoftwareUpdateStateWl,
  } = useHostModalContext();

  const closeThisModal = (): void => {
    setSelectedSoftwareForUpdate({});
    setSoftwareManagement(false);
  };

  const AddSoftwareToState = (): void => {
    if (
      winSoftwareUpdateState &&
      selectedSoftwareForUpdate &&
      Object.keys(selectedSoftwareForUpdate).length > 0
    ) {
      setWinSoftwareUpdateState({ ...selectedSoftwareForUpdate });
    }
    if (
      winSoftwareUpdateStateWl &&
      selectedSoftwareForUpdate &&
      Object.keys(selectedSoftwareForUpdate).length > 0
    ) {
      setWinSoftwareUpdateStateWl({ ...selectedSoftwareForUpdate });
    }
    setSoftwareManagement(false);
  };

  return (
    <Dialog
      className={styles.availableUpdatesModal}
      title={localeAuditTask[currentLocale].vulnsSoft.table.availableUpdates}
      onClose={(): void => setSoftwareManagement(false)}
    >
      <div className="k-form">
        <div className="form-content">
          <CommonSoftManList />
        </div>
        <div className="k-form-buttons">
          <Button type="submit" onClick={AddSoftwareToState}>
            {localeAuditTask[currentLocale].vulnsSoft.actions.addSoft}
          </Button>
          <Button type="button" onClick={closeThisModal}>
            {localeAuditTask[currentLocale].vulnsSoft.actions.cancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
