import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';

export function CellDynamicGrLinkName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td>
      <NavLink to={`/lk/assets/groups/dynamic/${dataItem.assetDynamicGroupId}`}>
        {dataItem.name}
      </NavLink>
    </td>
  );
}
