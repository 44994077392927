import type { GridCellProps } from '@progress/kendo-react-grid';
import { transformBytesToHuman } from '../../../../../lib/axios/helpers';

export function SizeCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const size = `${dataItem.size ? Math.floor(dataItem.size / 1024) : '0'} Kb`;

  return <td>{transformBytesToHuman(parseInt(size, 10))}</td>;
}
