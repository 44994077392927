/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

let dataFormat = [] as IDaraFilterSelect[];
export function FilterImportance(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  dataFormat = [
    { text: currentLocale === 'ru-RU' ? 'critical' : 'critical', val: 'critical' },
    { text: currentLocale === 'ru-RU' ? 'high' : 'high', val: 'high' },
    { text: currentLocale === 'ru-RU' ? 'medium' : 'medium', val: 'medium' },
    { text: currentLocale === 'ru-RU' ? 'low' : 'low', val: 'low' },
  ];

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={dataFormat[0]}
      data={dataFormat}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
