/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { useHostModalContext } from '../../../../../../hooks/useHostModalContext';
import { Button } from '../../../../../common/baseElements/Button';

export function CellAvailableUpdates(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { setAvailableUpdateModalData } = useHostModalContext();

  const showAvailableUpdateValue = (): void => {
    if (dataItem.availableUpdate && dataItem.availableUpdate.softwareCodename && dataItem.version) {
      setAvailableUpdateModalData({
        softwareCodename: dataItem.availableUpdate.softwareCodename,
        version: dataItem.version,
      });
    }
  };

  return (
    <td>
      {dataItem.availableUpdate && dataItem.availableUpdate.softwareCodename ? (
        <Button fill="modal" onClick={showAvailableUpdateValue}>
          <div style={{ display: 'flex' }}>
            <span>
              {dataItem.availableUpdate &&
              (dataItem.availableUpdate.approvedUpdatesCount ||
                Number(dataItem.availableUpdate.approvedUpdatesCount) === 0)
                ? dataItem.availableUpdate.approvedUpdatesCount
                : '-'}
            </span>
            /
            <span>
              {dataItem.availableUpdate &&
              (dataItem.availableUpdate.availableUpdatesCount ||
                Number(dataItem.availableUpdate.availableUpdatesCount) === 0)
                ? dataItem.availableUpdate.availableUpdatesCount
                : '-'}
            </span>
          </div>
        </Button>
      ) : (
        <div>
          <span>
            {dataItem.availableUpdate &&
            (dataItem.availableUpdate.approvedUpdatesCount ||
              Number(dataItem.availableUpdate.approvedUpdatesCount) === 0)
              ? dataItem.availableUpdate.approvedUpdatesCount
              : '-'}
          </span>
          /
          <span>
            {dataItem.availableUpdate &&
            (dataItem.availableUpdate.availableUpdatesCount ||
              Number(dataItem.availableUpdate.availableUpdatesCount) === 0)
              ? dataItem.availableUpdate.availableUpdatesCount
              : '-'}
          </span>
        </div>
      )}
    </td>
  );
}
