import type { MenuSelectEvent } from '@progress/kendo-react-layout';
import type { Audit } from '../../types/__generated/on-premise-solution/api/audit.v1';
import type { AuditDashboardResponse } from '../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import type { InventorizationResponse } from '../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import type { TaskActionDashboardResponse } from '../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';

export function useConvertToJson(
  data:
    | AuditDashboardResponse
    | Audit
    | InventorizationResponse
    | TaskActionDashboardResponse
    | undefined,
  fileName: string | null | undefined | false | unknown,
  pkgUpdate?: string | undefined,
): (e: MenuSelectEvent) => void {
  const handle2Json = (e: MenuSelectEvent): void => {
    const a = document.createElement('a');
    if (e.itemId === '0_0' && data && 'payload' in data) {
      a.href = URL.createObjectURL(
        new Blob([JSON.stringify(data.payload, null, 2)], {
          type: 'text/plain',
        }),
      );
      a.setAttribute('download', `${fileName}_payload.json`);
    } else if (e.itemId === '0_1' && data && 'payload' in data) {
      a.href = URL.createObjectURL(
        new Blob([JSON.stringify(data.result, null, 2)], {
          type: 'text/plain',
        }),
      );
      a.setAttribute('download', `${fileName}_audit.json`);
    } else if (e.itemId === '0_0' && data && 'result' in data && !pkgUpdate) {
      a.href = URL.createObjectURL(
        new Blob([JSON.stringify(data.result, null, 2)], {
          type: 'text/plain',
        }),
      );
      a.setAttribute('download', `${fileName}_inventorization.json`);
    } else if (e.itemId === '0_0' && data && pkgUpdate) {
      a.href = URL.createObjectURL(
        new Blob([JSON.stringify(data, null, 2)], {
          type: 'text/plain',
        }),
      );
      a.setAttribute('download', `${fileName}_inventorization.json`);
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return handle2Json;
}
