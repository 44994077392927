import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useCallback, useState, type ReactElement } from 'react';
import { CommonSoftManList } from '../../common/CommonSoftManagementList';
import localeSofwareManagement from '../../../utils/i18n/assetLocales/softwareManagement.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSofwareManagement;

export function TabsSoftwareManagement(): ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  return (
    <div className="software-management-tabstrip">
      <TabStrip selected={selected} onSelect={handleSelect}>
        {/* <TabStripTab title="Обзор" /> */}
        <TabStripTab title={localeSofwareManagement[currentLocale].windowsTitle}>
          <CommonSoftManList />
        </TabStripTab>
        {/* <TabStripTab title="Lin updates" /> */}
        {/* <TabStripTab title="WL" /> */}
        {/* <TabStripTab title="BL" /> */}
        {/* <TabStripTab title="Settings" /> */}
      </TabStrip>
    </div>
  );
}
