import type {
  TaskAssetSynchronizeExecutionDashboardResponse,
  TaskHostExecutionDashboardResponse,
  TaskImageExecutionDashboardResponse,
  TaskReportExecutionDashboardResponse,
} from '../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import type { AuditDashboardResponse } from '../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import type { TaskActionDashboardResponse } from '../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';
import type { LogData, TLogDataList } from './types';
import type { LogResponse } from '../../types/__generated/on-premise-solution/api/logsResponse.v1';

export interface IResultPkg {
  name: string;
  arch: string;
  oldVersion?: string | undefined;
  newVersion?: string | undefined;
  version?: string | undefined;
  status?: string | undefined;
}

// Когда будет использоваться разные пакетные менеджеры:
// export function createUpdatePkgData(data: TaskActionDashboardResponse | undefined): IResultPkg[] {
//   let resultPackages: IResultPkg[] = [];

//   if (data && data.type === 'package_update' && data.result?.data) {
//     if (data.result?.data) {
//       data.result.data.forEach((pkg) => {
//         if (pkg.deletedPackages && Array.isArray(pkg.deletedPackages)) {
//           const deletedPkg = pkg.deletedPackages.map((p) => ({
//             name: p.name,
//             arch: p.arch,
//             oldVersion: p.version,
//             type: 'deleted',
//           }));
//           resultPackages = [...resultPackages, ...deletedPkg];
//         }
//         if (pkg.installedPackages && Array.isArray(pkg.installedPackages)) {
//           const installedPkg = pkg.installedPackages.map((p) => ({
//             name: p.name,
//             arch: p.arch,
//             newVersion: p.version,
//             type: 'installed',
//           }));
//           resultPackages = [...resultPackages, ...installedPkg];
//         }
//         if (pkg.updatedPackages && Array.isArray(pkg.updatedPackages)) {
//           const updatedPackagesPkg = pkg.updatedPackages.map((p) => ({
//             ...p,
//             type: 'updated',
//           }));
//           resultPackages = [...resultPackages, ...updatedPackagesPkg];
//         }
//         if (pkg.notUpdatedPackages && Array.isArray(pkg.notUpdatedPackages)) {
//           const notUpdatedPkg = pkg.notUpdatedPackages.map((p) => ({
//             ...p,
//             type: 'notUpdated',
//           }));
//           resultPackages = [...resultPackages, ...notUpdatedPkg];
//         }
//       });
//     }
//   }

//   return resultPackages;
// }

export interface IPackagesUpdate {
  allPkgs: IResultPkg[];
  installedPkgs: IResultPkg[];
  updatedPkgs: IResultPkg[];
  deletedPkg: IResultPkg[];
  notUpdatedPkg: IResultPkg[];
  countOfType: number;
}
export function createUpdatePkgData(
  data: TaskActionDashboardResponse | undefined,
): IPackagesUpdate {
  let resultPackages: IResultPkg[] = [];

  let countOfType = 0;

  if (data && data.type === 'package_update' && data.result?.data) {
    if (
      data.result?.data[0].deletedPackages &&
      Array.isArray(data.result?.data[0].deletedPackages) &&
      data.result?.data[0].deletedPackages.length > 0
    ) {
      const deletedPkg = data.result.data[0].deletedPackages.map((p) => ({
        name: p.name,
        arch: p.arch,
        oldVersion: p.version,
        status: 'deleted',
      }));
      resultPackages = [...resultPackages, ...deletedPkg];
    }
    if (
      data.result?.data[0] &&
      data.result.data[0].installedPackages &&
      Array.isArray(data.result.data[0].installedPackages)
    ) {
      const installedPkg = data.result.data[0].installedPackages.map((p) => ({
        name: p.name,
        arch: p.arch,
        newVersion: p.version,
        status: 'installed',
      }));
      resultPackages = [...resultPackages, ...installedPkg];
    }
    if (
      data.result?.data[0] &&
      data.result.data[0].notUpdatedPackages &&
      Array.isArray(data.result.data[0].notUpdatedPackages)
    ) {
      const notUpdatedPkg = data.result.data[0].notUpdatedPackages.map((p) => ({
        ...p,
        status: 'notUpdated',
      }));
      resultPackages = [...resultPackages, ...notUpdatedPkg];
    }
    if (
      data.result?.data[0] &&
      data.result.data[0].updatedPackages &&
      Array.isArray(data.result.data[0].updatedPackages)
    ) {
      const updatedPackagesPkg = data.result.data[0].updatedPackages.map((p) => ({
        ...p,
        status: 'updated',
      }));
      resultPackages = [...resultPackages, ...updatedPackagesPkg];
    }
  }

  const packagesUpdate = {
    allPkgs: resultPackages,
    installedPkgs: resultPackages.filter((install) => install.status === 'installed'),
    updatedPkgs: resultPackages.filter((install) => install.status === 'updated'),
    deletedPkg: resultPackages.filter((install) => install.status === 'deleted'),
    notUpdatedPkg: resultPackages.filter((install) => install.status === 'notUpdated'),
  };

  const valPackagesUpdate = Object.values(packagesUpdate);

  valPackagesUpdate.forEach((p) => {
    if (p && Array.isArray(p) && p.length > 0) {
      countOfType += 1;
    }
  });

  return {
    ...packagesUpdate,
    countOfType,
  };
}

export interface IResultSoftwareManagement {
  name?: string;
  version?: string;
  publisher?: string;
  oldVersion?: string;
  newVersion?: string;
  status?: string;
  softwareName?: string;
}
export interface ISoftwareManagement {
  allSoftware: IResultSoftwareManagement[];
  installedSoftware: IResultSoftwareManagement[];
  uninstalledSoftware: IResultSoftwareManagement[];
  updatedSoftware: IResultSoftwareManagement[];
  notUpdatedSoftware: IResultSoftwareManagement[];
  notFoundSoftware: IResultSoftwareManagement[];
  noAvailableUpdatesForSoftware: IResultSoftwareManagement[];
  countOfType: number;
}

export function createUpdateSoftwareData(
  data: TaskActionDashboardResponse | undefined,
): ISoftwareManagement {
  let resultSoftware: IResultSoftwareManagement[] = [];

  let countOfType = 0;

  if (data && data.type === 'windows_software_management' && data.result?.data) {
    if (data.result.data.installedSoftware && data.result.data.installedSoftware.length > 0) {
      const installedSoftware = data.result.data.installedSoftware.map((s) => ({
        name: s.name,
        newVersion: s.version,
        publisher: s.publisher,
        status: 'installedSoftware',
      }));
      resultSoftware = [...resultSoftware, ...installedSoftware];
    }
    if (data.result?.data.uninstalledSoftware) {
      const uninstalledSoftware = data.result?.data.uninstalledSoftware.map((s) => ({
        ...s,
        oldVersion: s.version,
        status: 'uninstalledSoftware',
      }));
      resultSoftware = [...resultSoftware, ...uninstalledSoftware];
    }
    if (data.result?.data.updatedSoftware) {
      const updatedSoftware = data.result?.data.updatedSoftware.map((s) => ({
        name: s.name,
        oldVersion: s.oldVersion,
        newVersion: s.newVersion,
        publisher: s.publisher,
        status: 'updatedSoftware',
      }));
      resultSoftware = [...resultSoftware, ...updatedSoftware];
    }
    if (data.result?.data.notUpdatedSoftware) {
      const notUpdatedSoftware = data.result?.data.notUpdatedSoftware.map((s) => ({
        ...s,
        oldVersion: s.version,
        status: 'notUpdatedSoftware',
      }));
      resultSoftware = [...resultSoftware, ...notUpdatedSoftware];
    }
    if (data.result?.data.noAvailableUpdatesForSoftware) {
      const notUpdatedSoftware = data.result?.data.noAvailableUpdatesForSoftware.map((s) => ({
        ...s,
        oldVersion: s.version,
        status: 'noAvailableUpdatesForSoftware',
      }));
      resultSoftware = [...resultSoftware, ...notUpdatedSoftware];
    }
    if (data.result?.data.notFoundSoftware) {
      const notUpdatedSoftware = data.result?.data.notFoundSoftware.map((s) => ({
        ...s,
        name: s.softwareName,
        status: 'notFoundSoftware',
      }));
      resultSoftware = [...resultSoftware, ...notUpdatedSoftware];
    }
  }

  const packagesUpdate = {
    allSoftware: resultSoftware,
    installedSoftware: resultSoftware.filter((install) => install.status === 'installedSoftware'),
    uninstalledSoftware: resultSoftware.filter(
      (install) => install.status === 'uninstalledSoftware',
    ),
    updatedSoftware: resultSoftware.filter((install) => install.status === 'updatedSoftware'),
    notUpdatedSoftware: resultSoftware.filter((install) => install.status === 'notUpdatedSoftware'),
    notFoundSoftware: resultSoftware.filter((install) => install.status === 'notFoundSoftware'),
    noAvailableUpdatesForSoftware: resultSoftware.filter(
      (install) => install.status === 'noAvailableUpdatesForSoftware',
    ),
  };

  const valSoftwareUpdate = Object.values(packagesUpdate);

  valSoftwareUpdate.forEach((s) => {
    if (s && Array.isArray(s) && s.length > 0) {
      countOfType += 1;
    }
  });

  return {
    ...packagesUpdate,
    countOfType,
  };
}
export interface ICreateKbsData {
  id: string;
  status: string;
  rebootBehavior?: 'never' | 'always' | 'canRequest';
}
export function createKbsData(data: TaskActionDashboardResponse | undefined): ICreateKbsData[] {
  let kbsData: ICreateKbsData[] = [];

  if (data && data.type === 'kb_install') {
    if (
      data.result?.data &&
      data.result.data.alreadyExistKb &&
      Array.isArray(data.result.data.alreadyExistKb)
    ) {
      const alreadyExistKb = data.result.data.alreadyExistKb.map((k) => ({
        id: k.id,
        status: 'alreadyExistKb',
      }));
      kbsData = [...kbsData, ...alreadyExistKb];
    }
    if (
      data.result?.data &&
      data.result.data.deletedKb &&
      Array.isArray(data.result.data.deletedKb)
    ) {
      const deletedKb = data.result.data.deletedKb.map((k) => ({
        id: k.id,
        status: 'deletedKb',
      }));
      kbsData = [...kbsData, ...deletedKb];
    }
    if (
      data.result?.data &&
      data.result.data.installedKb &&
      Array.isArray(data.result.data.installedKb)
    ) {
      const installedKb = data.result.data.installedKb.map((k) => ({
        id: k.id,
        status: 'installedKb',
        rebootBehavior: k.rebootBehavior,
      }));
      kbsData = [...kbsData, ...installedKb];
    }
    if (
      data.result?.data &&
      data.result.data.notFoundKb &&
      Array.isArray(data.result.data.notFoundKb)
    ) {
      const notFoundKb = data.result.data.notFoundKb.map((k) => ({
        id: k.id,
        status: 'notFoundKb',
      }));
      kbsData = [...kbsData, ...notFoundKb];
    }
    if (
      data.result?.data &&
      data.result.data.notInstalledKb &&
      Array.isArray(data.result.data.notInstalledKb)
    ) {
      const notInstalledKb = data.result.data.notInstalledKb.map((k) => ({
        id: k.id,
        status: 'notInstalledKb',
      }));
      kbsData = [...kbsData, ...notInstalledKb];
    }
  }

  return kbsData;
}

export interface IAllAgentStatus {
  online: string[];
  offline: string[];
  notActivated: string[];
  disabled: string[];
}
export type IAssetGroupData = {
  assets?:
    | (
        | {
            assetId: string;
            hostname: string | null;
            type?: string;
          }
        | {
            assetId: string;
            imageNames: string[] | null;
            type?: string;
          }
      )[]
    | undefined;
  assetGroups?:
    | {
        assetGroupId: string;
        name: string;
      }[]
    | undefined;
  registries?:
    | {
        registryId: string;
        name: string | null;
      }[]
    | undefined;
  assetDynamicGroups?:
    | {
        assetDynamicGroupId: string;
        name: string;
      }[]
    | undefined;
};

export function setAssetGroupData(
  data:
    | TaskHostExecutionDashboardResponse['scope']
    | TaskReportExecutionDashboardResponse['scope']
    | TaskImageExecutionDashboardResponse['scope']
    | TaskAssetSynchronizeExecutionDashboardResponse['scope'],
): IAssetGroupData {
  // const assetGroupData = [] as IAssetGroupData[];

  const assetGroupData = {
    assets: [],
    assetGroups: [],
    registries: [],
    assetDynamicGroups: [],
  } as IAssetGroupData;
  if ('assetGroups' in data && data.assetGroups) {
    assetGroupData.assetGroups = [...data.assetGroups];
  }
  if ('assets' in data && data.assets) {
    assetGroupData.assets = [...data.assets];
  }

  if ('registries' in data && data.registries) {
    assetGroupData.registries = [...data.registries];
  }

  if ('assetDynamicGroups' in data && data.assetDynamicGroups) {
    assetGroupData.assetDynamicGroups = [...data.assetDynamicGroups];
  }

  return assetGroupData;
}

export function createClsTaskDescription(status: string | undefined): string[] {
  const cssClasses = [''] as string[];

  if (status === 'processing') {
    cssClasses.push('processing');
  } else if (status === 'success') {
    cssClasses.push('success');
  } else if (status === 'fail') {
    cssClasses.push('fail');
  } else if (status === 'timeout') {
    cssClasses.push('fail');
  } else if (status === 'skipped') {
    cssClasses.push('skipped');
  }

  return cssClasses;
}

export interface ICalculateClasses {
  cssSeverityClasses: string[];
  cssScoreClasses: string[];
  severityValue: string | undefined;
  cvssVectorValue: string | undefined;
  cvssScoreValue: string | undefined;
}

export function calculateClassesCvssSeverity(
  auditData: AuditDashboardResponse | undefined,
): ICalculateClasses {
  const cssSeverityClasses = [''] as string[];
  const cssScoreClasses = [''] as string[];
  let severityValue: string | undefined = '';
  let cvssScoreValue: string | undefined = '';
  let cvssVectorValue: string | undefined = '';

  let maxScore = 0;

  auditData?.result?.cumulativeData?.metrics?.forEach((m) => {
    if (m.cvss.score && Number(m.cvss.score) > maxScore) {
      severityValue = m.severity;
      cvssScoreValue = m.cvss.score;
      cvssVectorValue = m.cvss.vector;
      maxScore = Number(m.cvss.score);
    }
    // if (m.source === auditData?.payload.os.name) {
    //   severityValue = m.severity;
    //   cvssScoreValue = m.cvss.score;
    //   cvssVectorValue = m.cvss.vector;
    // }
    // if (m.source !== auditData?.payload.os.name && m.source === 'nvd') {
    //   severityValue = m.severity;
    //   cvssScoreValue = m.cvss.score;
    //   cvssVectorValue = m.cvss.vector;
    // }
  });

  if (cvssScoreValue && Number(cvssScoreValue) >= 0 && Number(cvssScoreValue) <= 3.9) {
    cssSeverityClasses.push('low-color-text');
    cssScoreClasses.push('asset_score low');
  }

  if (Number(cvssScoreValue) >= 3.9 && Number(cvssScoreValue) <= 6.9) {
    cssSeverityClasses.push('low-color-medium');
    cssScoreClasses.push('asset_score medium');
  }

  if (Number(cvssScoreValue) >= 6.9 && Number(cvssScoreValue) <= 8.9) {
    cssSeverityClasses.push('low-color-high');
    cssScoreClasses.push('asset_score high');
  }

  if (Number(cvssScoreValue) > 8.9) {
    cssSeverityClasses.push('low-color-critical');
    cssScoreClasses.push('asset_score critical');
  }

  return {
    cssSeverityClasses,
    cssScoreClasses,
    severityValue,
    cvssVectorValue,
    cvssScoreValue,
  };
}

export function saveLogString2Json(
  data: LogData,
  fileName: string | null | undefined | false | unknown,
): void {
  const a = document.createElement('a');

  // For JSON
  a.href = URL.createObjectURL(
    new Blob([JSON.stringify(data, null, 2)], {
      type: 'text/plain',
    }),
  );

  const fName = `${fileName}__log`;

  a.setAttribute('download', `${fName}.json`);
  // a.setAttribute('download', fName);

  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
}

export function saveLogList2Json(
  data: LogResponse[] | undefined,
  fileName: string | null | undefined | false | unknown,
): void {
  const logData: TLogDataList[] | undefined = data?.map((d) => ({
    createdAt: d.createdAt,
    level: d.level,
    assetId: d.assetId || '',
    message: d.message,
    action: d.type,
  }));
  const a = document.createElement('a');

  // For JSON
  a.href = URL.createObjectURL(
    new Blob([JSON.stringify(logData, null, 2)], {
      type: 'text/plain',
    }),
  );

  const fName = `${fileName}`;

  a.setAttribute('download', `${fName}.json`);
  // a.setAttribute('download', fName);

  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
}
