/* eslint-disable no-nested-ternary */
import '../../TaskDescription.module.scss';
import { useParams } from 'react-router';
import { Button } from '../../../../common/baseElements/Button';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { useCreateTask } from '../../../../../hooks/components/useCreateTask';
import styles from './packageNameModal.module.scss';
import type {
  TVulnPkgForAllSelect,
  TVulnsSoftActions,
  TWinSoftwareTaskUpdate,
} from '../../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function VulnsSoftActions(props: TVulnsSoftActions): React.ReactElement {
  const {
    hostName,
    id,
    vulnSoftDataForSelect,
    vulnSoftDataForSelectWl,
    osType,
    assetIdFromParent,
  } = props;

  const {
    selectedPkgs,
    selectedPkgsWl,
    setPkgWlTask,
    winSoftwareUpdateState,
    winSoftwareUpdateStateWl,
    selectAllPkgOnPage,
    cancelSelectedInTable,
    selectAllWinSoftOnPage,
  } = useHostModalContext();

  const { assetId } = useParams<{ assetId: string }>();

  const { createTaskForAsset } = useCreateTask();

  const handleTaskForAsset = (typePkg: string): void => {
    createTaskForAsset({
      val: osType && osType === 'linux' ? 'package_update' : 'windows_software_management',
      data: undefined,
      assetId: assetIdFromParent || assetId,
      hostname: hostName || undefined,
    });
    setPkgWlTask(typePkg === 'wl');
  };

  return (
    <div className={styles.pkgUpdate}>
      {!id && (
        <Button
          onClick={(): void =>
            osType === 'linux'
              ? selectAllPkgOnPage(vulnSoftDataForSelect as TVulnPkgForAllSelect[], osType, 'notWl')
              : selectAllWinSoftOnPage(
                  vulnSoftDataForSelect as Required<
                    Pick<TWinSoftwareTaskUpdate, 'softwareCodename' | 'softwareName'>
                  >[],
                  osType,
                  'notWl',
                )
          }
          disabled={!vulnSoftDataForSelect || vulnSoftDataForSelect?.length === 0}
          className="selected-pkgs button-mr20"
        >
          {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
        </Button>
      )}
      {!id &&
        ((selectedPkgs && selectedPkgs.length > 0) ||
          (winSoftwareUpdateState && Object.keys(winSoftwareUpdateState).length > 0)) && (
          <Button
            onClick={(): void => handleTaskForAsset('notWl')}
            className="selected-pkgs action button-mr20"
          >
            {osType && osType === 'linux'
              ? localeAuditTask[currentLocale].updateSelectedPackages
              : localeAuditTask[currentLocale].updateSelectedSoft}{' '}
            (
            {selectedPkgs && selectedPkgs.length
              ? selectedPkgs.length
              : winSoftwareUpdateState && Object.keys(winSoftwareUpdateState).length
              ? Object.keys(winSoftwareUpdateState).length
              : null}
            )
          </Button>
        )}
      {id && (
        <Button
          onClick={(): void =>
            osType === 'linux'
              ? selectAllPkgOnPage(vulnSoftDataForSelectWl as TVulnPkgForAllSelect[], osType)
              : selectAllWinSoftOnPage(
                  vulnSoftDataForSelectWl as Required<
                    Pick<TWinSoftwareTaskUpdate, 'softwareCodename' | 'softwareName'>
                  >[],
                  osType,
                )
          }
          className="selected-pkgs button-mr20"
          disabled={!vulnSoftDataForSelectWl || vulnSoftDataForSelectWl?.length === 0}
        >
          {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
        </Button>
      )}
      {id &&
        ((selectedPkgsWl && selectedPkgsWl.length > 0) ||
          (winSoftwareUpdateStateWl && Object.keys(winSoftwareUpdateStateWl).length > 0)) && (
          <Button
            onClick={(): void => handleTaskForAsset('wl')}
            className="selected-pkgs action button-mr20"
          >
            {osType && osType === 'linux'
              ? localeAuditTask[currentLocale].updateSelectedPackages
              : localeAuditTask[currentLocale].updateSelectedSoft}{' '}
            (
            {selectedPkgsWl && selectedPkgsWl.length
              ? selectedPkgsWl.length
              : winSoftwareUpdateStateWl && Object.keys(winSoftwareUpdateStateWl).length
              ? Object.keys(winSoftwareUpdateStateWl).length
              : null}
            )
          </Button>
        )}
      {!id && selectedPkgs && selectedPkgs.length > 0 && (
        <Button
          onClick={(): void => cancelSelectedInTable(osType, id)}
          className="cancel-selected-pkgs"
        >
          {localeAuditTask[currentLocale].cancelSelectedPackages}
        </Button>
      )}
      {!id && winSoftwareUpdateState && Object.keys(winSoftwareUpdateState).length > 0 && (
        <Button
          onClick={(): void => cancelSelectedInTable(osType, id)}
          className="cancel-selected-pkgs"
        >
          {localeAuditTask[currentLocale].cancelSelectedSw}
        </Button>
      )}
      {id &&
        ((selectedPkgsWl && selectedPkgsWl.length > 0) ||
          (winSoftwareUpdateStateWl && Object.keys(winSoftwareUpdateStateWl).length > 0)) && (
          <Button
            onClick={(): void => cancelSelectedInTable(osType, id)}
            className="cancel-selected-pkgs"
          >
            {localeAuditTask[currentLocale].cancelSelectedPackages}
          </Button>
        )}
    </div>
  );
}
