import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';

export function CellLinkName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td>
      {dataItem.type === 'host' ? (
        <NavLink
          // style={{ textDecoration: 'underline' }}
          to={`/lk/assets/groups/static/${dataItem.assetGroupId}`}
        >
          {dataItem.name}
        </NavLink>
      ) : (
        <NavLink
          // style={{ textDecoration: 'underline' }}
          to={`/lk/settings/tokens/${dataItem.apiKeyId}`}
        >
          {dataItem.name}
        </NavLink>
      )}
    </td>
  );
}
