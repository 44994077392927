/* eslint-disable react/jsx-indent */
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { useEffect, useRef, useState } from 'react';
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useQueryClient } from 'react-query';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import { FILTER_DYNAMIC_GROUP } from '../../../../utils/helpers/constant-serv-filter-host';
import { FILTER_INITIAL } from '../../../../utils/helpers/constants';
import type {
  AssetDynamicGroup,
  AssetDynamicGroupDashboardResponse,
} from '../../../../types/__generated/on-premise-solution/api/assetDynamicGroupsDashboardResponse.v1';
import styles from '../../ContainerOfGroups/containerOfGroups.module.scss';
import { useGroupContext } from '../../../../hooks/useGroupContext';
import { Button } from '../../../common/baseElements/Button';
import {
  convertFilterForDynamicGroup,
  IFilterVal,
} from '../../../../utils/filtering-in-table-test';
import { DynamicGroupFilterBlock } from './DynamicGroupFilterBlock';
import { prepareDynamicGroupDescriptionFilters } from '../../../../utils/helpers/host-description-helper';
import { DynamicGroupFilterActions } from './DynamicGroupFilterActions';

export function DynamicGroupDescriptionFilters({
  dataFilters,
  assetDynamicGroupId,
  name,
  description,
  setDataOfFilters,
}: {
  dataFilters: AssetDynamicGroupDashboardResponse['filters'] | null;
  assetDynamicGroupId: string;
  name: string | undefined;
  description: string | null | undefined;
  setDataOfFilters: (v: AssetDynamicGroup['filters'] | null) => void;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHost;

  const queryClient = useQueryClient();

  const FILTER_DYNAMIC_INITIAL: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [{ field: 'latestInventorization.hostname', operator: 'contains', value: '' }],
  };

  const [isDisplayFilterBlock, setDisplayFilterBlock] = useState({
    isFilter1: false,
    isFilter2: false,
    isFilter3: false,
    isFilter4: false,
  });

  const [filter0, setFilter0] = useState<CompositeFilterDescriptor>(FILTER_DYNAMIC_INITIAL);

  const [filter1, setFilter1] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filter2, setFilter2] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filter3, setFilter3] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filter4, setFilter4] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filter0RestApi, setFilter0RestApi] = useState<IFilterVal[] | undefined>(undefined);

  const [filter1RestApi, setFilter1RestApi] = useState<IFilterVal[] | undefined>(undefined);

  const [filter2RestApi, setFilter2RestApi] = useState<IFilterVal[] | undefined>(undefined);

  const [filter3RestApi, setFilter3RestApi] = useState<IFilterVal[] | undefined>(undefined);

  const [filter4RestApi, setFilter4RestApi] = useState<IFilterVal[] | undefined>(undefined);

  const [isEnabledSaveBtn, setEnabledSaveBtn] = useState(false);

  const [isEnabledCancelBtn, setEnabledCancelBtn] = useState(false);

  const [isEmptyFilter, setEmptyFilter] = useState(false);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const {
    editFilterInDynamicGroup,
    setGroupData,
    errorMutationDynamicGrEdit,
    applyMutationDynamicGrEdit,
    setApplyMutationDynamicGrEdit,
    setStatusDynamicGroupTest,
    statusDynamicGroupTest,
  } = useGroupContext();

  const saveFilter = (isSaveFilter: boolean): void => {
    setGroupData({ name: name || '', description });

    editFilterInDynamicGroup(
      assetDynamicGroupId,
      filter0RestApi,
      filter1RestApi,
      filter2RestApi,
      filter3RestApi,
      filter4RestApi,
      isSaveFilter ? 'apply' : 'temp',
    );
    if (isSaveFilter) setEnabledSaveBtn(false);
    setStatusDynamicGroupTest(true);
  };

  const cancelFilters = (): void => {
    setEnabledSaveBtn(false);
    setDataOfFilters(null);
    queryClient.invalidateQueries('hostDynamicGroupDescription');
    queryClient.invalidateQueries('hostDynamicGroupDescriptionRefresh');
    setEnabledCancelBtn(false);
  };

  const onFilterChange = (
    event: FilterChangeEvent,
    setState: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
    isFilterKey: string,
  ): void => {
    setApplyMutationDynamicGrEdit(false);
    if (event.filter.filters && event.filter.filters.length <= 5) {
      setState(event.filter);
      setEnabledCancelBtn(true);
      setEnabledSaveBtn(true);
    }
    if (event.filter.filters.length === 0) {
      setDisplayFilterBlock((prevState) => ({ ...prevState, [isFilterKey]: false }));
    }
  };

  useEffect(() => {
    if (
      !filter0.filters.find((v) => 'value' in v && !v.value) &&
      !filter1.filters.find((v) => 'value' in v && !v.value) &&
      !filter2.filters.find((v) => 'value' in v && !v.value) &&
      !filter3.filters.find((v) => 'value' in v && !v.value) &&
      !filter4.filters.find((v) => 'value' in v && !v.value)
    ) {
      setEmptyFilter(false);

      if (filter0) {
        const convertedFilters: IFilterVal[] | undefined = convertFilterForDynamicGroup(filter0);
        setFilter0RestApi(convertedFilters);
        // setEnabledSaveBtn(true);
      }
      if (filter1) {
        const convertedFilters: IFilterVal[] | undefined = convertFilterForDynamicGroup(filter1);
        setFilter1RestApi(convertedFilters);
      }
      if (filter2) {
        const convertedFilters: IFilterVal[] | undefined = convertFilterForDynamicGroup(filter2);
        setFilter2RestApi(convertedFilters);
      }
      if (filter3) {
        const convertedFilters: IFilterVal[] | undefined = convertFilterForDynamicGroup(filter3);
        setFilter3RestApi(convertedFilters);
      }
      if (filter4) {
        const convertedFilters: IFilterVal[] | undefined = convertFilterForDynamicGroup(filter4);
        setFilter4RestApi(convertedFilters);
      }
    } else {
      setEnabledSaveBtn(false);
    }
  }, [filter0, filter1, filter2, filter3, filter4]);

  useEffect(() => {
    if (
      filter0.filters.find((v) => 'value' in v && !v.value) ||
      filter1.filters.find((v) => 'value' in v && !v.value) ||
      filter2.filters.find((v) => 'value' in v && !v.value) ||
      filter3.filters.find((v) => 'value' in v && !v.value) ||
      filter4.filters.find((v) => 'value' in v && !v.value)
    ) {
      setEmptyFilter(true);
    }
  }, [filter0, filter1, filter2, filter3, filter4]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (isEnabledSaveBtn || !errorMutationDynamicGrEdit) {
      timer.current = setTimeout(() => {
        saveFilter(false);
      }, 1000);
    }
  }, [filter0RestApi, filter1RestApi, filter2RestApi, filter3RestApi, filter4RestApi]);

  useEffect(() => {
    const values = {
      dataFilters,
      setFilter0,
      setFilter1,
      setFilter2,
      setFilter3,
      setFilter4,
      filter1,
      filter2,
      filter3,
      filter4,
      setDisplayFilterBlock,
    };

    prepareDynamicGroupDescriptionFilters(values);
  }, []);

  return (
    <div className={styles.dynamicGroupDescriptionFilters}>
      <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
        <div className={styles.filterAssetsTitleWrap}>
          <span className={`${styles.filterAssetsTitle} wrapper-menu`}>
            {localeHost[currentLocale].filterAssetsTitle}
          </span>
        </div>
        {((filter0.filters && filter0.filters.length > 0) ||
          ((!filter1 || filter1.filters.length < 1) &&
            (!filter2 || filter2.filters.length < 1) &&
            (!filter3 || filter3.filters.length < 1) &&
            (!filter4 || filter4.filters.length < 1))) && (
          <>
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <div className="filter-line">
                  <Filter
                    value={filter0}
                    onChange={(e): void => onFilterChange(e, setFilter0, 'isFilter1')}
                    fields={FILTER_DYNAMIC_GROUP[currentLocale]}
                  />
                </div>
              </IntlProvider>
            </LocalizationProvider>
            <DynamicGroupFilterActions
              setDisplayBlock={setDisplayFilterBlock}
              isFilterValue={isDisplayFilterBlock.isFilter1}
              isFilterKey="isFilter1"
              filter={filter1}
              setFilter={setFilter1}
              setEnabledCancelBtn={setEnabledCancelBtn}
            />
          </>
        )}
        {(isDisplayFilterBlock.isFilter1 || (filter1.filters && filter1.filters.length > 0)) && (
          <>
            <DynamicGroupFilterBlock
              filter={filter1}
              setFilter={setFilter1}
              isFilterKey="isFilter1"
              onFilterChange={onFilterChange}
            />
            <DynamicGroupFilterActions
              setDisplayBlock={setDisplayFilterBlock}
              isFilterValue={isDisplayFilterBlock.isFilter2}
              isFilterKey="isFilter2"
              filter={filter2}
              setFilter={setFilter2}
              setEnabledCancelBtn={setEnabledCancelBtn}
            />
          </>
        )}
        {(isDisplayFilterBlock.isFilter2 || (filter2.filters && filter2.filters.length > 0)) && (
          <>
            <DynamicGroupFilterBlock
              filter={filter2}
              setFilter={setFilter2}
              isFilterKey="isFilter2"
              onFilterChange={onFilterChange}
            />
            <DynamicGroupFilterActions
              setDisplayBlock={setDisplayFilterBlock}
              isFilterValue={isDisplayFilterBlock.isFilter3}
              isFilterKey="isFilter3"
              filter={filter3}
              setFilter={setFilter3}
              setEnabledCancelBtn={setEnabledCancelBtn}
            />
          </>
        )}
        {(isDisplayFilterBlock.isFilter3 || (filter3.filters && filter3.filters.length > 0)) && (
          <>
            <DynamicGroupFilterBlock
              filter={filter3}
              setFilter={setFilter3}
              isFilterKey="isFilter3"
              onFilterChange={onFilterChange}
            />
            <DynamicGroupFilterActions
              setDisplayBlock={setDisplayFilterBlock}
              isFilterValue={isDisplayFilterBlock.isFilter4}
              isFilterKey="isFilter4"
              filter={filter4}
              setFilter={setFilter4}
              setEnabledCancelBtn={setEnabledCancelBtn}
            />
          </>
        )}
        {(isDisplayFilterBlock.isFilter4 || (filter4.filters && filter4.filters.length > 0)) && (
          <DynamicGroupFilterBlock
            filter={filter4}
            setFilter={setFilter4}
            isFilterKey="isFilter4"
            onFilterChange={onFilterChange}
          />
        )}
      </ErrorBoundary>
      {isEnabledSaveBtn &&
        !errorMutationDynamicGrEdit &&
        !statusDynamicGroupTest &&
        applyMutationDynamicGrEdit && (
          <div className={styles.appliedOfDynamicFilters}>
            <span>{localeHost[currentLocale].appliedOfDynamicFilters}</span>
          </div>
        )}
      {!errorMutationDynamicGrEdit && statusDynamicGroupTest && (
        <div className={styles.processing}>
          <span>{localeHost[currentLocale].processing}</span>
        </div>
      )}
      <div className={styles.errorWrap}>
        <span>{errorMutationDynamicGrEdit}</span>
      </div>
      {isEmptyFilter && (
        <div className={styles.errorWrap}>
          <span>{localeHost[currentLocale].emptyFilter}</span>
        </div>
      )}
      <div className={styles.btnsWrap}>
        <Button
          onClick={(): void => saveFilter(true)}
          className={!isEnabledSaveBtn || !!errorMutationDynamicGrEdit ? '' : 'action'}
          disabled={!isEnabledSaveBtn || !!errorMutationDynamicGrEdit}
        >
          {localeHost[currentLocale].save}{' '}
        </Button>
        <Button onClick={cancelFilters} disabled={!isEnabledCancelBtn}>
          {localeHost[currentLocale].cancel}{' '}
        </Button>
      </div>
    </div>
  );
}
