/* eslint-disable react/jsx-pascal-case */
import { loadMessages } from '@progress/kendo-react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useRef } from 'react';
import { Loader } from '@progress/kendo-react-indicators/dist/npm/loader/Loader';
import { nanoid } from 'nanoid';
import localePkgUpdate from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getTaskActionById } from '../../../../services/task-service';
import type { TaskActionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';
import styles from '../TaskDescription.module.scss';
import { useScrollLog } from '../../../../hooks/components/useScrollLog';
import { TaskKbLogIfOldAgent } from './TaskKbLogIfOldAgent';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localePkgUpdate;

loadMessages(localePkgUpdate[currentLocale], currentLocale);

export function TaskKbLog({
  taskActionId,
}: {
  taskActionId: string | undefined;
}): React.ReactElement {
  const queryClient = useQueryClient();

  const parentRef = useRef<HTMLHeadingElement>(null);

  const childRef = useRef<HTMLHeadingElement>(null);

  const lengthOfpartialResults = useRef<number | false | null | undefined>(null);

  const lengthOfDataState = useRef<number | undefined>(undefined);

  const query = useQuery<TaskActionDashboardResponse, ApiError>(
    ['taskActionById', taskActionId],
    () => getTaskActionById(taskActionId),
    {
      onSuccess: (data) => {
        lengthOfpartialResults.current =
          data.type === 'kb_install' &&
          data.partialResults?.data &&
          Array.isArray(data.partialResults?.data) &&
          data.partialResults?.data.length;
      },
      keepPreviousData: true,
      enabled: taskActionId !== undefined,
    },
  );

  const dataState = useScrollLog(parentRef, childRef, query.data);

  lengthOfDataState.current = dataState.current?.length;

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await queryClient.invalidateQueries('taskActionById');
        if (
          childRef.current &&
          query.data?.status === 'processing' &&
          query.data.type === 'kb_install' &&
          lengthOfpartialResults.current &&
          lengthOfDataState.current &&
          lengthOfDataState.current !== lengthOfpartialResults.current
        ) {
          childRef.current.scrollIntoView(false);
        }
      } catch (e) {
        console.log(e);
      }
    }, 5000);

    if (query.data?.status !== 'processing') {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [dataState.current?.length, query.data, queryClient]);

  return (
    <div className={styles.taskKbLog}>
      <div ref={parentRef} className={styles.wrapper}>
        {dataState && dataState.current && query.data?.status === 'processing' ? (
          <div className={styles.content}>
            {dataState.current.map((d) => (
              <div key={nanoid()} className={styles.logProcessingWrap}>
                <span>{d.type === 'kb_install' && 'log' in d && d.log}</span>
              </div>
            ))}
            {query.data?.status === 'processing' ? (
              <div className={styles.loaderWrap}>
                <span className="k-i-loader" />
              </div>
            ) : null}
          </div>
        ) : (
          <div className={styles.content}>
            {query.data?.type === 'kb_install' &&
            query.data.result?.data.log &&
            typeof query.data.result?.data.log === 'string' ? (
              <span>{query.data.result?.data.log}</span>
            ) : (
              <TaskKbLogIfOldAgent data={query.data} />
            )}
            {query.data?.status === 'processing' && (
              <Loader
                style={{ color: 'white', marginLeft: '50%' }}
                size="large"
                type="converging-spinner"
              />
            )}
          </div>
        )}
        <div ref={childRef} style={{ padding: '4px', margin: '16px' }} />
      </div>
    </div>
  );
}
