/* eslint-disable no-nested-ternary */
import localeVmTask from '../../../utils/i18n/taskLocale/vmTask.json';
import type { TaskActionDashboardResponse } from '../../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';
import type { IUpdateFrom } from '../../../utils/helpers/types';

export function ThirdColumn({
  currentLocale,
  taskAction,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  taskAction: TaskActionDashboardResponse;
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.updateFrom}</span>
          </td>
          <td>
            {taskAction.type === 'kb_install' && taskAction.result?.data.updateFrom ? (
              (localeVmTask[currentLocale].updateFromVal[
                taskAction.result?.data.updateFrom
              ] as keyof IUpdateFrom)
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.isRebootNow}</span>
          </td>
          {taskAction.type === 'kb_install' && taskAction.result?.data.isRebootNow ? (
            <td>{localeVmTask[currentLocale].summary.yes}</td>
          ) : taskAction.type === 'kb_install' && taskAction.result?.data.isRebootNow === false ? (
            <td>{localeVmTask[currentLocale].summary.no}</td>
          ) : (
            <td>{localeVmTask[currentLocale].summary.nd}</td>
          )}
        </tr>
      </tbody>
    </table>
  );
}
