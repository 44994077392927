import type { ReactElement } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useQuery } from 'react-query';
import { useMaintenanceWindowsContext } from '../../../../hooks/useMaintenanceWindowsContext';
import { CommonMaintenanceList } from '../../../common/CommonMaintenanceList';
import { Button } from '../../../common/baseElements/Button';
import locale from '../../../../utils/i18n/commonLocales/buttons.json';
import localeMaintenance from '../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import type { AssetMaintenanceWindowsResponse2 } from '../../../../types/__generated/on-premise-solution/api/assetMaintenanceWindowsResponse.v1';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getMaintenanceWindowsInfo } from '../../../../services/asset-service';
import { MaintenanceWindowModal } from './MaintenanceWindowModal';
import {
  convertFromActionsToPeriods,
  convertGroupsWindowsToMaintenanceList,
} from '../../../../utils/helpers/maintenance-windows-helper';
import styles from './MaintenanceWindows.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function MaintenanceListModal({ assetId }: { assetId?: string }): ReactElement {
  const {
    isWindowOpen,
    entityType,
    entityName,
    setTableOpen,
    setWindowEdit,
    editWindow,
    windowsListData,
    disableAllPeriods,
  } = useMaintenanceWindowsContext();

  const queryMaintenanceInfo = useQuery<AssetMaintenanceWindowsResponse2 | undefined, ApiError>(
    ['allMaintenanceInfo', assetId],
    () => (assetId ? getMaintenanceWindowsInfo(assetId) : undefined),
    {
      onSuccess: (resp) => {
        // hostname.current =
        //   resp.type === 'host' ? resp.latestInventorization?.hostname || undefined : undefined;
        // setAccountOfAsset(resp.account);
        // setMaintenanceWindowsList(resp.maintenanceWindows);
        // entityId.current = assetId;
        // entityType.current = 'asset';
        // entityName.current = hostname.current;
        // setSelectedAccountId(resp.accountId);
      },
      enabled: !!assetId,
    },
  );

  return (
    <>
      {isWindowOpen && <MaintenanceWindowModal />}
      <Dialog
        title={
          localeMaintenance[currentLocale].maintenanceWindows +
          (entityType.current
            ? ` ${localeMaintenance[currentLocale].entityTypes[entityType.current]}`
            : '') +
          (entityName.current ? ` "${entityName.current}"` : '')
        }
        className="maintenance-windows-modal middle"
        onClose={(): void => setTableOpen(false)}
      >
        <p>
          {localeMaintenance[currentLocale].mWindowDescription}
          <br />
          {localeMaintenance[currentLocale].mWindowDescription2}
          <br />
          <a href="/lk/doc/#/?id=Технологические-окна">{localeMaintenance[currentLocale].more}</a>
        </p>
        <div
          className="maintenance-windows-tables"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <div className={styles.maintenanceWindowBlock}>
            {assetId && <h4>{localeMaintenance[currentLocale].individualHeader}</h4>}

            <div
              className="button-block"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                margin: '16px 0',
              }}
            >
              <Button
                // style={{
                //   margin: '24px 0',
                // }}
                fill="action"
                onClick={(): void => {
                  editWindow();
                  setWindowEdit(false);
                }}
              >
                {localeMaintenance[currentLocale].addWindowBtn}
              </Button>
              <Button onClick={disableAllPeriods}>
                {localeMaintenance[currentLocale].disableAllBtn}
              </Button>
            </div>
            <CommonMaintenanceList data={windowsListData} />
          </div>
          {assetId && (
            <>
              <div className={styles.maintenanceWindowBlock}>
                <h4>{localeMaintenance[currentLocale].groupsHeader}</h4>
                <CommonMaintenanceList
                  disableMenu
                  isGroupData
                  data={convertGroupsWindowsToMaintenanceList(
                    queryMaintenanceInfo.data?.assetGroups,
                    queryMaintenanceInfo.data?.assetDynamicGroups,
                  )}
                />
              </div>
              <div className={styles.maintenanceWindowBlock}>
                <h4>{localeMaintenance[currentLocale].globalHeader}</h4>
                <CommonMaintenanceList
                  disableMenu
                  data={convertFromActionsToPeriods(
                    queryMaintenanceInfo.data?.account?.maintenanceWindows,
                    {
                      entityId: queryMaintenanceInfo.data?.account?.accountId,
                    },
                  )}
                />
              </div>
            </>
          )}
        </div>
        <div className="k-form-buttons">
          <Button type="button" onClick={(): void => setTableOpen(false)}>
            {locale[currentLocale].close}
          </Button>
        </div>
      </Dialog>
    </>
  );
}
