/* eslint-disable no-nested-ternary */
// import '../TaskDescription.module.scss';
import locale from '../../../../utils/i18n/assetLocales/assetDescriptionGroup.json';
import localeMaintenance from '../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import type { EnrichedAssetGroupResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedAssetGroupResponse.v1';
import { MaintenanceTableListButton } from '../../Host/MaintenanceWindows/MaintenanceTableListButton';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;
export function GroupMetaBlock({
  data,
}: {
  data: EnrichedAssetGroupResponse | undefined;
}): React.ReactElement {
  return (
    <div className="common-summary-page">
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.assetCount}</span>
            </td>
            <td>{data?.assetCount ? <span>{data?.assetCount}</span> : <span>0</span>}</td>
          </tr>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.description}</span>
            </td>
            {/* <td>{data?.description ? <span>{data?.description}</span> : <span>-</span>}</td> */}
            {data?.description ? (
              <td>
                <div title={data?.description} className="vuln-description">
                  {data?.description.length > 100
                    ? `${data?.description.slice(0, 100)}...`
                    : data?.description}
                </div>
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.createdAt}</span>
            </td>
            <td>{new Date(String(data?.createdAt)).toLocaleString()}</td>
          </tr>
          <tr className="taskid">
            <td>
              <span>{locale[currentLocale].metaBlock.modifiedAt}</span>
            </td>
            <td>
              {data?.modifiedAt ? (
                <span>{new Date(String(data?.modifiedAt)).toLocaleString()}</span>
              ) : (
                <span>-</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.createdBy}</span>
            </td>
            <td>
              {data &&
              data.createdBy &&
              'source' in data.createdBy &&
              data?.createdBy.source === 'user' ? (
                <span>{data?.createdBy.name}</span>
              ) : (
                <span>-</span>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <span>{locale[currentLocale].metaBlock.modifiedBy}</span>
            </td>
            <td>
              {data &&
              data.modifiedBy &&
              'source' in data.modifiedBy &&
              data?.modifiedBy.source === 'user' ? (
                <span>{data?.modifiedBy.name}</span>
              ) : (
                <span>-</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{localeMaintenance[currentLocale].maintenanceWindows}</span>
            </td>
            <td>
              <MaintenanceTableListButton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
