/* eslint-disable no-nested-ternary */
import '../TaskDescription.module.scss';
import { useState } from 'react';
import type localeTaskDescription from '../../../../utils/i18n/taskLocale/taskDescription.json';
import {
  createClsTaskDescription,
  setAssetGroupData,
} from '../../../../utils/helpers/task-helpers';
import { ColumnSecond } from './metaBlockTables/ColumnSecond';
import { ColumnFirst } from './metaBlockTables/ColumnFirst';
import { ColumnThird } from './metaBlockTables/ColumnThird';
import type {
  TaskAssetExecutionDashboardResponse,
  TaskDeviceExecutionDashboardResponse,
  TaskExecutionDashboardResponse,
  TaskHostExecutionDashboardResponse,
  TaskImageExecutionDashboardResponse,
  TaskReportExecutionDashboardResponse,
} from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { ColumnThirdPkg } from './metaBlockTables/ColumnThirdPkg';
import { ColumnThirdSm } from './metaBlockTables/ColumnThirdSm';

export type TPkg =
  | {
      packageManager?: 'rpm' | 'deb' | 'portage' | 'pacman' | undefined;
      packages?:
        | {
            name: string;
            packageManager?: 'rpm' | 'deb' | 'portage' | 'pacman' | undefined;
          }[]
        | undefined;
    }
  | undefined;

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTaskDescription;
export function MetaBlock({ data }: { data: TaskExecutionDashboardResponse }): React.ReactElement {
  const [isShow, setShow] = useState(false);
  const [isShowPkg, setShowPkg] = useState(false);

  const scope = data
    ? (data.scope as
        | TaskReportExecutionDashboardResponse['scope']
        | TaskImageExecutionDashboardResponse['scope']
        | TaskHostExecutionDashboardResponse['scope']
        | TaskAssetExecutionDashboardResponse['scope']
        | TaskDeviceExecutionDashboardResponse['scope'])
    : ([] as
        | TaskReportExecutionDashboardResponse['scope']
        | TaskImageExecutionDashboardResponse['scope']
        | TaskHostExecutionDashboardResponse['scope']
        | TaskAssetExecutionDashboardResponse['scope']
        | TaskDeviceExecutionDashboardResponse['scope']);

  const scoreData = setAssetGroupData(scope);

  const cssClasses = createClsTaskDescription(data?.status);

  return (
    <div className="common-summary-page">
      {data && <ColumnFirst data={data} cssClasses={cssClasses} currentLocale={currentLocale} />}
      {data && <ColumnSecond data={data} currentLocale={currentLocale} />}
      {data &&
        (((data.taskType === 'host' || data.taskType === 'asset') &&
          !data.actions.package_update &&
          !data.actions.windows_software_management) ||
          data.taskType === 'device' ||
          data.taskType === 'image' ||
          data.taskType === 'report') && (
          <ColumnThird
            currentLocale={currentLocale}
            scoreData={scoreData}
            isShow={isShow}
            setShow={setShow}
            taskType={data.taskType}
          />
        )}
      {data &&
        (data.taskType === 'host' || data.taskType === 'asset') &&
        data.actions.package_update && (
          <ColumnThirdPkg
            currentLocale={currentLocale}
            scoreData={scoreData}
            isShow={isShow}
            setShow={setShow}
            isShowPkg={isShowPkg}
            setShowPkg={setShowPkg}
            pkgs={data.actions.package_update}
          />
        )}
      {data &&
        (data.taskType === 'host' || data.taskType === 'asset') &&
        data.actions.windows_software_management && (
          <ColumnThirdSm
            currentLocale={currentLocale}
            scoreData={scoreData}
            isShow={isShow}
            setShow={setShow}
            isShowPkg={isShowPkg}
            setShowPkg={setShowPkg}
            softwareManagement={data.actions.windows_software_management}
          />
        )}
    </div>
  );
}
