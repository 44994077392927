import '../../../Host/hostComponents/HostDescription.module.scss';
// import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import localeMaintenance from '../../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import type { AssetRouterDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import type {
  AssetDeviceDashboardResponse,
  AssetHypervisorDashboardResponse,
} from '../../../../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetSwitchDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetDeviceDashboardResponse.v1';
import { MaintenanceTableListButton } from '../../../Host/MaintenanceWindows/MaintenanceTableListButton';

export function TableFourthDeviceMetaBlock({
  data,
  currentLocale,
}: {
  data:
    | AssetRouterDashboardResponse
    | AssetDeviceDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse
    | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>{localeMaintenance[currentLocale].maintenanceWindows}</td>
          <td>
            <MaintenanceTableListButton />
          </td>
        </tr>
        {/* <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.added}</span>
          </td>
          <td>
            <span>{new Date(String(data?.createdAt)).toLocaleString()}</span>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.addedBy}</span>
          </td>
          <td>
            {data?.createdBy.source === 'user' ? (
              <span>{data?.createdBy.name}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr> */}
      </tbody>
    </table>
  );
}
