/* eslint-disable no-nested-ternary */
// import '../../../TaskDescription.module.scss';
import { NavLink } from 'react-router-dom';
import localeVmTask from '../../../utils/i18n/taskLocale/vmTask.json';
import type { TaskActionDashboardResponse } from '../../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';

export function SecondColumn({
  currentLocale,
  taskAction,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  taskAction: TaskActionDashboardResponse;
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.hostName}</span>
          </td>
          <td>
            {taskAction.assets &&
              (taskAction.assets[0].type === 'host' ||
                taskAction.assets[0].subType === 'network_device' ||
                taskAction.assets[0].type === 'hypervisor') &&
              taskAction.assets[0].latestInventorization?.hostname ? (
              <span style={{ fontWeight: '700' }}>
                {taskAction.assets[0].latestInventorization?.hostname}
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.ip}</span>
          </td>
          <td>
            {taskAction.assets &&
              (taskAction.assets[0].type === 'host' ||
                taskAction.assets[0].subType === 'network_device' ||
                taskAction.assets[0].type === 'hypervisor') &&
              taskAction.assets[0].latestInventorization?.ip ? (
              <span>{taskAction.assets[0].latestInventorization?.ip}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.system}</span>
          </td>
          <td>
            {taskAction.assets &&
              (taskAction.assets[0].type === 'host' ||
                taskAction.assets[0].subType === 'network_device' ||
                taskAction.assets[0].type === 'hypervisor') && (
                <span>
                  {taskAction.assets[0].latestInventorization?.os?.name}{' '}
                  {taskAction.assets[0].latestInventorization?.os?.type[0].toUpperCase()}
                  {taskAction.assets[0].latestInventorization?.os?.type.slice(1)}{' '}
                  {taskAction.assets[0].latestInventorization?.os?.version}
                </span>
              )}
            {(taskAction.assets &&
              taskAction.assets[0].type === 'host' &&
              !taskAction.assets[0].latestInventorization) ||
              (!taskAction.assets && <span>-</span>)}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.assetId}</span>
          </td>
          <td>
            {taskAction.assets &&
              (taskAction.assets[0].type === 'host' ||
                taskAction.assets[0].subType === 'network_device' ||
                taskAction.assets[0].type === 'hypervisor') && (
                <NavLink
                  className="link-underline"
                  to={
                    taskAction.assets[0].type === 'host'
                      ? `/lk/assets/hosts/${taskAction.assets[0].assetId}`
                      : `/lk/assets/devices/${taskAction.assets[0].type}/${taskAction.assets[0].assetId}`
                  }
                >
                  {taskAction.assets[0].assetId}
                </NavLink>
              )}

            {(taskAction.assets &&
              taskAction.assets[0].type === 'host' &&
              !taskAction.assets[0].assetId) ||
              (!taskAction.assets && <span>-</span>)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
