/* eslint-disable no-nested-ternary */
// import '../../../TaskDescription.module.scss';
import { NavLink } from 'react-router-dom';
import localeVmTask from '../../../utils/i18n/taskLocale/vmTask.json';
import type { TaskActionDashboardResponse } from '../../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';

export function FirstColumn({
  currentLocale,
  taskAction,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  taskAction: TaskActionDashboardResponse;
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.start}</span>
          </td>
          <td>
            {taskAction.startedAt ? (
              <span>{new Date(taskAction.startedAt).toLocaleString()}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.finish}</span>
          </td>
          {taskAction.finishedAt ? (
            <td>{new Date(String(taskAction.finishedAt)).toLocaleString()}</td>
          ) : (
            <td>-</td>
          )}
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.startId}</span>
          </td>
          <td>
            {taskAction.taskExecutionId ? (
              <NavLink
                className="link-underline"
                to={`/lk/task/executions/${taskAction.taskExecutionId}`}
              >
                {taskAction.taskExecutionId}
              </NavLink>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeVmTask[currentLocale].summary.launchedBy}</span>
          </td>
          <td>
            {taskAction.createdBy.source === 'user' ? (
              <span>{taskAction.createdBy.name}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
