import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';
import localeSoftwareManagement from '../../../../utils/i18n/assetLocales/softwareManagement.json';
import { transformBytesToHuman } from '../../../../lib/axios/helpers';
import type { SavedWindowsSoftwareUpdateWithAvailableDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateWithAvailableDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSoftwareManagement;

export function SoftSizeCell({
  size,
  downloadUrl,
  className,
  status,
  radix,
}: {
  size: number | undefined;
  downloadUrl: string | undefined;
  status: SavedWindowsSoftwareUpdateWithAvailableDashboardResponse['downloadStatus'];
  className?: string;
  radix?: number;
}): ReactElement {
  const sizeText =
    typeof size === 'number' ? transformBytesToHuman(size, radix, currentLocale) : '-';

  return (
    <td className={className || ''}>
      {downloadUrl && status === 'downloaded' ? (
        <a
          title={localeSoftwareManagement[currentLocale].downloadTooltip}
          target="_blank"
          href={downloadUrl}
          rel="noreferrer"
        >
          {sizeText}
        </a>
      ) : (
        sizeText
      )}
    </td>
  );
}

export function SoftSizeCellCallback(props: GridCellProps): ReactElement {
  const { dataItem, className } = props;

  return SoftSizeCell({
    size: dataItem.size,
    downloadUrl: dataItem.downloadUrl,
    status: dataItem.downloadStatus,
    className,
    radix: 2,
  });
}
