import type { GridCellProps } from '@progress/kendo-react-grid';
// import type { EncrichedAssetGroupDashboardItem } from '../../../types/__generated/on-premise-solution/api/assetGroupsDashboardResponse.v1';

export function CellCommonTd(props: GridCellProps): React.ReactElement {
  const { dataItem, field } = props;

  const { createdAt, createdBy, modifiedAt, modifiedBy, latestAssets } = dataItem;

  if (field === 'createdAt')
    return <td>{dataItem.createdAt ? new Date(createdAt).toLocaleString() : '-'}</td>;
  if (field === 'createdBy')
    return <td>{createdBy.source === 'user' && createdBy.name ? createdBy.name : '-'}</td>;
  if (field === 'modifiedAt')
    return <td>{modifiedAt ? new Date(modifiedAt).toLocaleString() : '-'}</td>;
  if (field === 'latestAssets.refreshedAt')
    return (
      <td>
        {latestAssets && latestAssets.refreshedAt
          ? new Date(latestAssets.refreshedAt).toLocaleString()
          : '-'}
      </td>
    );

  if (field === 'modifiedBy')
    return (
      <td>
        {modifiedBy && modifiedBy.source && modifiedBy.source === 'user' && modifiedBy.name
          ? modifiedBy.name
          : '-'}
      </td>
    );

  return <td />;
}
