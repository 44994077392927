import React, { useCallback, useEffect, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import '../../TaskDescription.module.scss';
import { VulnsSoft } from './VulnsSoft';
import { VulnerabilitiesList } from './VulnerabilitiesList';
import { PackageList } from './PackageList';
import { MissingKBs } from './MissingKBs';
import { SoftwareListWin } from './SoftwareListWin';
import type { AuditDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import { TabsVmNetDevDetails } from './TabsVmNetDevDetails';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;

export function TabsVmDetails({
  data,
  hostName,
}: {
  data: AuditDashboardResponse | undefined;
  hostName: string | undefined | false;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const { setSelectedVulns } = useAssetCreatePropContext();

  const { setSelectedPkgs, setSelectedPkgsWl, setMissedKbs, setKbsInWl } = useHostModalContext();

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const countVulnerableObjects = data?.result?.vulnerableObjects.length;

  const countVulns =
    data?.result?.cumulativeData.vulns && Object.keys(data?.result.cumulativeData.vulns).length
      ? Object.keys(data?.result.cumulativeData.vulns).length
      : 0;

  const countPackageList =
    data?.payload && data.type === 'linux' && 'packages' in data.payload
      ? data.payload.packages.length
      : 0;

  const countSoftwareList =
    data?.payload && data.type === 'windows' && 'software' in data.payload
      ? data.payload.software?.length
      : 0;

  const countmissedKB =
    data?.type === 'windows' && data.result.cumulativeData.missedKb !== null
      ? Object.values(data.result.cumulativeData.missedKb).length
      : 0;

  useEffect(() => {
    return () => {
      setSelectedPkgs(null);
      setSelectedPkgsWl(null);
      setSelectedVulns(null);
      setKbsInWl(null);
      setMissedKbs(null);
    };
  }, []);

  return (
    <>
      {data?.type === 'linux' && (
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.vulnSoftLin} (${countVulnerableObjects})`}
          >
            <VulnsSoft
              type={data.type}
              dataResult={data?.result}
              hostName={hostName}
              assetIdFromParent={data.assetId}
            />
          </TabStripTab>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.vulnerabilities} (${countVulns})`}
          >
            <VulnerabilitiesList dataResult={data?.result} hostName={hostName} />
          </TabStripTab>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.packageList} (${countPackageList})`}
          >
            <PackageList dataPayload={data?.payload} hostName={hostName} />
          </TabStripTab>
        </TabStrip>
      )}
      {data?.type === 'windows' && (
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.vulnSoftWin} (${countVulnerableObjects})`}
          >
            <VulnsSoft
              type={data.type}
              dataResult={data?.result}
              hostName={hostName}
              vulnSoftWhiteList={undefined}
              assetIdFromParent={data.assetId}
            />
          </TabStripTab>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.vulnerabilities} (${countVulns})`}
          >
            <VulnerabilitiesList
              dataResult={data?.result}
              hostName={hostName}
              vulnsWhiteList={undefined}
            />
          </TabStripTab>
          <TabStripTab title={`${localeVmTask[currentLocale].tabs.missingKBs}  (${countmissedKB})`}>
            <MissingKBs data={data} hostName={hostName} />
          </TabStripTab>
          <TabStripTab
            title={`${localeVmTask[currentLocale].tabs.softwareList} (${countSoftwareList})`}
          >
            <SoftwareListWin data={data} hostName={hostName} />
          </TabStripTab>
        </TabStrip>
      )}
      {(data?.type === 'network_device' || data?.type === 'hypervisor') && (
        <TabsVmNetDevDetails data={data} hostName={hostName} />
      )}
    </>
  );
}
