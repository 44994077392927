/* eslint-disable no-nested-ternary */
import { NavLink } from 'react-router-dom';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import type { TaskActionsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskActionsDashboardResponse.v1';
import styles from '../form/NewTask.module.scss';
import { CommonTooltip } from '../../../common/CommonTooltip';

export function SecondColumn({
  currentLocale,
  data,
  assetDuplicationsSearchPolicy,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  data: TaskActionsDashboardResponse | undefined;
  assetDuplicationsSearchPolicy: 'globally' | 'inside_asset_group' | 'ignore' | undefined;
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeNewTask[currentLocale].taskSyncDescription.metaBlock.sourceOfImport}</span>
          </td>
          <td>
            {data && data.data[0].ldap ? (
              <span>
                {data && data.data[0].ldap?.type === 'ldap'
                  ? localeNewTask[currentLocale].taskSyncDescription.metaBlock
                    .sourcesOfImportVal1Ldap
                  : localeNewTask[currentLocale].taskSyncDescription.metaBlock
                    .sourcesOfImportVal1Ad}
              </span>
            ) : (
              <span>
                {localeNewTask[currentLocale].taskSyncDescription.metaBlock.sourcesOfImportVal2}
              </span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeNewTask[currentLocale].taskSyncDescription.metaBlock.serverName}</span>
          </td>
          {data && data.data[0].ldap?.name ? (
            <td>
              {' '}
              <NavLink
                className="link-underline"
                to={`/lk/users/servers/${data.data[0].ldap.ldapId}`}
              >
                {data.data[0].ldap?.name}
              </NavLink>
            </td>
          ) : (
            <td>-</td>
          )}
        </tr>
        <tr>
          <td>
            <span>
              {localeNewTask[currentLocale].taskSyncDescription.metaBlock.duplicatSearchPolicy}
            </span>
          </td>
          <td>
            <div className={styles.titleTaskWrapTooltip}>
              {!assetDuplicationsSearchPolicy || assetDuplicationsSearchPolicy === 'ignore' ? (
                <span>{localeNewTask[currentLocale].form.checkDuplicate.doNotCheck}</span>
              ) : assetDuplicationsSearchPolicy === 'globally' ? (
                <span>{localeNewTask[currentLocale].form.checkDuplicate.globalCheck}</span>
              ) : (
                <span>{localeNewTask[currentLocale].form.checkDuplicate.inGroupCheck}</span>
              )}
              <CommonTooltip>
                <div>
                  <div>
                    <span>{localeNewTask[currentLocale].form.synchronize.checkDuplicate}</span>
                  </div>
                  <ul className={styles.tooltipList}>
                    <li>
                      <span className={styles.itemTitle}>
                        {localeNewTask[currentLocale].form.synchronize.duplicateTitle1}
                      </span>
                      <span>{localeNewTask[currentLocale].form.synchronize.duplicatecontent1}</span>
                    </li>
                    <li>
                      <span className={styles.itemTitle}>
                        {localeNewTask[currentLocale].form.synchronize.duplicateTitle2}
                      </span>
                      <span>{localeNewTask[currentLocale].form.synchronize.duplicatecontent2}</span>
                    </li>
                    <li>
                      <span className={styles.itemTitle}>
                        {localeNewTask[currentLocale].form.synchronize.duplicateTitle3}
                      </span>
                      <span>{localeNewTask[currentLocale].form.synchronize.duplicatecontent3}</span>
                    </li>
                  </ul>
                </div>
              </CommonTooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
