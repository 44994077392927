/* eslint-disable react/jsx-pascal-case */
import { Field } from '@progress/kendo-react-form';
import { loadMessages } from '@progress/kendo-react-intl';
import localeCommonTable from '../../../../utils/i18n/commonLocales/table.json';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import { FormInput } from '../../../common/form/FormInput';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { checkLength } from '../../../../utils/helpers/validation';
import { DropDownSelect } from '../../../common/form/DropDownSelect';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function SnmpFields(): React.ReactElement {
  const {
    username,
    setUsername,
    changeAuthProtocol,
    authProtocolList,
    credentialData,
    privacyKey,
    setPrivacyKey,
    authKey,
    setAuthKey,
    authLevelList,
    changeAuthLevel,
    privacyProtocolList,
    changePrivacyProtocol,
  } = useCredentialContext();

  return (
    <div className="form-content">
      <Field
        name="username"
        component={FormInput}
        id="username"
        label={locale[currentLocale].form.userName}
        onChange={(e): void => {
          setUsername(e.target.value);
        }}
        inputValue={username}
        className="form-field-wrapper"
        customValidator={checkLength}
        type="text"
        classForLabel="required-label"
      />
      <Field
        name="authProtocol"
        component={DropDownSelect}
        label={locale[currentLocale].form.authProtocol}
        onChange={changeAuthProtocol}
        data={
          credentialData && credentialData.authProtocol
            ? authProtocolList.filter((t) => t !== credentialData.authProtocol)
            : authProtocolList
        }
        defaultValue={
          credentialData && credentialData.authProtocol
            ? authProtocolList.find((t) => t === credentialData.authProtocol)
            : locale[currentLocale].form.select
        }
        cls="form-field-wrapper"
        // classForLabel="required-label"
      />
      <Field
        name="authLevel"
        component={DropDownSelect}
        label={locale[currentLocale].form.authLevel}
        onChange={changeAuthLevel}
        data={
          credentialData && credentialData.authLevel
            ? authLevelList.filter((t) => t !== credentialData.authLevel)
            : authLevelList
        }
        defaultValue={
          credentialData && credentialData.authLevel
            ? authLevelList.find((t) => t === credentialData.authLevel)
            : locale[currentLocale].form.select
        }
        cls="form-field-wrapper"
      />
      <Field
        name="privacyProtocol"
        component={DropDownSelect}
        label={locale[currentLocale].form.privacyProtocol}
        onChange={changePrivacyProtocol}
        data={
          credentialData && credentialData.privacyProtocol
            ? privacyProtocolList.filter((t) => t !== credentialData.privacyProtocol)
            : privacyProtocolList
        }
        defaultValue={
          credentialData && credentialData.privacyProtocol
            ? privacyProtocolList.find((t) => t === credentialData.privacyProtocol)
            : locale[currentLocale].form.select
        }
        cls="form-field-wrapper"
      />
      <Field
        name="authKey"
        component={FormInput}
        id="authKey"
        label={locale[currentLocale].form.authKey}
        onChange={(e): void => {
          setAuthKey(e.target.value);
        }}
        inputValue={authKey}
        className="form-field-wrapper"
        type="text"
      />
      <Field
        name="privacyKey"
        component={FormInput}
        id="privacyKey"
        label={locale[currentLocale].form.privacyKey}
        onChange={(e): void => {
          setPrivacyKey(e.target.value);
        }}
        inputValue={privacyKey}
        className="form-field-wrapper"
        type="text"
      />
    </div>
  );
}
