/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from 'react';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { useQuery } from 'react-query';
import { Dialog } from '@progress/kendo-react-dialogs';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../../../utils/helpers/types';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import { handleError } from '../../../../utils/errors';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { handleSort } from '../../../../utils/sorting-in-table';
import locale from '../../../../utils/i18n/security/vulnerabilities.json';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { IFilterVal } from '../../../../utils/filtering-in-table-test';
import { useCustomSearch } from '../../../../hooks/components/useCustomSearch';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { useFilter } from '../../../../hooks/components/useFilter';
import { FILTER_OPERATORS } from '../../../../utils/helpers/constant-filter-operators';
import { FILTER_ALL_BULLETINS } from '../../../../utils/helpers/constant-serv-filter-task';
import { getAllBulletins } from '../../../../services/bulletins-service';
import type { DashboardBulletinsResponse } from '../../../../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import { CellPublished } from '../../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnerabilitiesListPublished';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { collectSecurityVulns } from '../../../../utils/helpers/security-vulns-helper';
import { VulnModal } from '../../../common/modal/vulnModal/VulnModal';
import { CellVulnListAlias } from '../../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnListAlias';
import { Input } from '../../../common/baseElements/Input';
import { ScoreCvssCell } from '../../../common/cells/ScoreCvssCell';
import { CellSecurityVulnsId } from '../../Security/cells/CellSecurityVulnsId';
import { CellSecurityTitle } from '../../Security/cells/CellSecurityTitle';
import { CellSecurityCwe } from '../../Security/cells/CellSecurityCwe';
import { CellSelectBulletin } from './CellSelectBulletin';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { Button } from '../../../common/baseElements/Button';
import { getUrlList } from '../../../../utils/helpers/getUrl-list-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(locale[currentLocale], currentLocale);

export function BulletinsListForForm(): React.ReactElement {
  const dataForHooks: IDataHooks = {
    name: 'bulletinId',
    componentName: 'AllBulletinsListById',
  };

  const urlParams = getUrlList('AllBulletinsListById');

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const {
    setAddBulletin,
    bulletinForm,
    bulletinFormTemp,
    addSelectedBulletins,
    setBulletinFormTemp,
  } = useWhiteListContext();

  const { createNotification } = useNotificationContext();

  const { cveName } = useAuditModalContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<DashboardBulletinsResponse, ApiError>(
    ['AllBulletinsListById', page, filterVal, sort],
    () => getAllBulletins(page, filterVal, sort),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (!bulletinFormTemp) {
      setBulletinFormTemp(bulletinForm);
    }
  }, []);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  const { data } = query;

  const securityVulns = collectSecurityVulns(data);

  return (
    <Dialog
      title={locale[currentLocale].applyForVulns.titleSelectVuln}
      onClose={(): void => setAddBulletin(false)}
      className="bulletin-list modal"
    >
      <div className="k-form">
        <div className="form-content select-asset">
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <div className="filter-line">
                {securityVulns && (
                  <Filter
                    value={filter}
                    onChange={onFilterChange}
                    fields={FILTER_ALL_BULLETINS[currentLocale]}
                  />
                )}
                {filter.filters.length === 0 && (
                  <Input
                    name="customSearch"
                    placeholder={locale[currentLocale].customSearchWhiteList}
                    value={customSearch}
                    onChange={(e): void => handleCustomSearch(e)}
                  />
                )}
              </div>
            </IntlProvider>
          </LocalizationProvider>
          {securityVulns && (
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <Grid
                  pageable={PAGEABLE_DATA}
                  sortable
                  sort={sort}
                  className="table task-table"
                  data={securityVulns}
                  skip={page.skip}
                  take={page.take}
                  total={Number(query.data?.total)}
                  filterOperators={FILTER_OPERATORS}
                  onPageChange={pageChange}
                  onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
                  scrollable="none"
                >
                  <Column field="addBulletin" title=" " cell={CellSelectBulletin} width="40px" />
                  <Column
                    title={locale[currentLocale].vulnsTable.cvssScore}
                    filter="text"
                    field="maxScore"
                    width="96px"
                    cell={ScoreCvssCell}
                  />
                  <Column
                    title={locale[currentLocale].vulnsTable.id}
                    filter="text"
                    field="bulletinId"
                    width="140px"
                    cell={CellSecurityVulnsId}
                  />
                  <Column
                    field="aliases"
                    title={localeAuditTask[currentLocale].vulnsList.table.aliases}
                    cell={CellVulnListAlias}
                    width={query.data?.data.find((a) => a.aliases) ? '180px' : '160px'}
                  />
                  <Column
                    title={locale[currentLocale].vulnsTable.title}
                    filter="text"
                    field="title"
                    width="140px"
                    cell={CellSecurityTitle}
                  />
                  <Column
                    field="cwe"
                    title={locale[currentLocale].vulnsTable.cwe}
                    cell={CellSecurityCwe}
                  />
                  {/* <Column
                    field="vector"
                    title={locale[currentLocale].vulnsTable.cvssVector}
                    width="320px"
                    filter="text"
                    cell={CellSecurityVector}
                    sortable={false}
                    headerClassName="no-cursor"
                  /> */}
                  <Column
                    title={locale[currentLocale].vulnsTable.published}
                    width="128px"
                    filter="text"
                    field="date.published"
                    cell={CellPublished}
                  />
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
          )}
          <СommonTableLoader queryData={query.isFetching} filteringData={filterStatus} />
          {query.isError && (
            <div className="info alert alert-danger">Error: {query.error.message}</div>
          )}
          {cveName && <VulnModal />}
        </div>
        <div className="k-form-buttons">
          <Button
            type="submit"
            disabled={
              (!bulletinFormTemp && bulletinForm?.length === 0) ||
              (!bulletinFormTemp && bulletinForm === null) ||
              (!bulletinForm && bulletinFormTemp?.length === 0) ||
              (!bulletinForm && bulletinFormTemp === null)
            }
            onClick={addSelectedBulletins}
          >
            {locale[currentLocale].applyForVulns.btnAddVuln}{' '}
            {bulletinFormTemp && bulletinFormTemp?.length > 0 && `(${bulletinFormTemp.length})`}
          </Button>
          <Button type="button" onClick={(): void => setAddBulletin(false)}>
            {locale[currentLocale].applyForVulns.btnCancelVuln}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
