import '../../../../Host/hostComponents/HostDescription.module.scss';
import localeHostDescription from '../../../../../../utils/i18n/assetLocales/hostDescription.json';
import localeMaintenance from '../../../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import type { AssetImageDashboardResponse } from '../../../../../../types/__generated/on-premise-solution/api/assetImageDashboardResponse.v1';
import {
  MaintenanceTableListButton,
  MaintenanceTd,
} from '../../../../Host/MaintenanceWindows/MaintenanceTableListButton';

export function TableFImageMetaBlock({
  data,
  currentLocale,
}: {
  data: AssetImageDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column column-first">
      <tbody>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.system}</span>
          </td>
          <td>
            {data?.type === 'image' && data?.latestInventorization && (
              <span style={{ textTransform: 'capitalize' }}>
                {' '}
                {data?.latestInventorization?.os?.type === 'linux'
                  ? data?.latestInventorization.os.name
                  : data.latestInventorization.os?.name}{' '}
                {data.latestInventorization.os?.version}
              </span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.size}</span>
          </td>
          <td>
            {data?.type === 'image' && data?.latestInventorization && (
              <span>
                {' '}
                {data.latestInventorization.size
                  ? `${Math.floor(data.latestInventorization.size / 1024 / 1024)} Mb`
                  : '-'}{' '}
              </span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.arch}</span>
          </td>
          <td>
            {data?.type === 'image' && data?.latestInventorization && (
              <span>{data.latestInventorization.arch || '-'}</span>
            )}
          </td>
        </tr>
        <tr>
          <td>{localeMaintenance[currentLocale].maintenanceWindows}</td>

          <MaintenanceTd />
        </tr>
        {/* <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.system}</span>
          </td>
          <td>:</td>
          <td>
            {data?.type === 'image' && data?.latestInventorization && (
              <span>
                {data?.latestInventorization?.os.name} {data?.latestInventorization?.os.type}{' '}
                {data?.latestInventorization?.os.version}
              </span>
            )}
          </td>
        </tr> */}
        {/* <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.idAsset}</span>
          </td>
          <td>:</td>
          <td>
            <span> {data?.type === 'image' && data?.assetId}</span>
          </td>
        </tr> */}
      </tbody>
    </table>
  );
}
