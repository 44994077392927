import { Dialog } from '@progress/kendo-react-dialogs';
import { useQuery } from 'react-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { useRef } from 'react';
import localeAuditTask from '../../../utils/i18n/taskLocale/auditTask.json';
import type { SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse } from '../../../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdatesWithAvailableDashboardResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getSoftwareList } from '../../../services/softManagement-service';
import styles from '../../features/SoftwareManagement/SoftwareManagement.module.scss';
import { SoftwareRow } from '../../features/SoftwareManagement/SoftwareRow';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { Button } from '../baseElements/Button';
import { useStatesForList } from '../../../hooks/components/useStatesForList';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { getAccountId } from '../../../services/local-storage-service';
import { useCommonContext } from '../../../hooks/useCommonContext';
import { useSoftwareManagementContext } from '../../../hooks/useSoftwareManagementContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;
export function AvailableUpdatesModal(): React.ReactElement {
  const WindowsSoftwareResponse =
    useRef<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse['data']>();

  const urlParams = getUrlList('AvailableUpdatesModal');

  const { availableUpdateModalData, setAvailableUpdateModalData } = useHostModalContext();

  const { selectedAccountId } = useSoftwareManagementContext();

  const { sort, page, filterVal } = useStatesForList(urlParams);

  useQuery<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse, ApiError>(
    ['savedSoftwareList', page, filterVal, sort, availableUpdateModalData],
    () =>
      getSoftwareList(
        page,
        filterVal,
        sort,
        availableUpdateModalData?.softwareCodename,
        availableUpdateModalData?.version,
        selectedAccountId,
      ),
    {
      onSuccess: (resp) => {
        if (resp.data && resp.data.length > 0) WindowsSoftwareResponse.current = [resp.data[0]];
      },
    },
  );

  return (
    <Dialog
      className={styles.availableUpdatesModal}
      title={`${localeAuditTask[currentLocale].vulnsSoft.table.availableUpdates} ${
        WindowsSoftwareResponse.current && WindowsSoftwareResponse.current[0]
          ? WindowsSoftwareResponse.current[0].softwareName
          : ''
      }`}
      onClose={(): void => setAvailableUpdateModalData(null)}
    >
      <Grid
        className={`${styles.repositories_table} no-margin-top`}
        data={WindowsSoftwareResponse.current}
        scrollable="none"
      >
        <Column cell={SoftwareRow} />
      </Grid>
      <Button
        type="button"
        className={styles.closeBtn}
        onClick={(): void => setAvailableUpdateModalData(null)}
      >
        {localeAuditTask[currentLocale].close}
      </Button>
    </Dialog>
  );
}
