import { useEffect, useState, type ReactElement } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useMaintenanceWindowsContext } from '../../../../hooks/useMaintenanceWindowsContext';
import { CheckBox } from '../../../common/form/CheckBox';
import { Button } from '../../../common/baseElements/Button';
import { MaintenanceSchedule } from './MaintenanceSchedule';
import styles from './MaintenanceWindows.module.scss';
import localeMaintenance from '../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import {
  getLocaleUTC,
  type IWindowData,
} from '../../../../utils/helpers/maintenance-windows-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeMaintenance;

function getTimeStateFromPeriod(period: string[] | undefined): [string, string] {
  const time: [string, string] = ['', ''];
  if (!period || !period.length) return time;
  const periodValues = period[0].split('-');

  return [periodValues[0], periodValues.length > 1 ? periodValues[1] : ''];
}

export function MaintenanceWindowModal(): ReactElement {
  const { isWindowEdit, setWindowEdit, closeWindow, patchEntity, windowData, setWindowData } =
    useMaintenanceWindowsContext();

  // Period states
  const [hours, setHours] = useState<[string, string]>(
    getTimeStateFromPeriod(windowData?.period.hourRanges),
  );
  const [weekDays, setWeekDays] = useState<[string, string]>(
    getTimeStateFromPeriod(windowData?.period.dayOfWeekRanges),
  );
  const [monthDays, setMonthDays] = useState<[string, string]>(
    getTimeStateFromPeriod(windowData?.period.dayOfMonthRanges),
  );
  const [months, setMonths] = useState<[string, string]>(
    getTimeStateFromPeriod(windowData?.period.monthRanges),
  );
  const [utcOffset, setUtcOffset] = useState<string>(
    windowData?.period.utcOffset || getLocaleUTC() || '+00:00',
  );

  // Actions states
  const [isAudit, setAudit] = useState(windowData?.taskActions.audit);
  const [isInventorization, setInventorization] = useState(windowData?.taskActions.inventorization);
  const [isAgentUpdate, setAgentUpdate] = useState(windowData?.taskActions.agent_update);
  const [isReboot, setReboot] = useState(windowData?.taskActions.system_reboot);
  const [isPackageUpdate, setPackageUpdate] = useState(windowData?.taskActions.package_update);
  const [isKbInstall, setKbInstall] = useState(windowData?.taskActions.kb_install);

  useEffect(() => {
    return () => {
      setWindowData(undefined);
      setWindowEdit(false);
    };
  }, []);

  const handleSubmit = (): void => {
    const entityData: IWindowData = {
      period: {},
      taskActions: {},
    };

    if (hours[0]) entityData.period.hourRanges = [hours.filter((h) => h).join('-')];
    if (weekDays[0]) entityData.period.dayOfWeekRanges = [weekDays.filter((wd) => wd).join('-')];
    if (monthDays[0]) entityData.period.dayOfMonthRanges = [monthDays.filter((md) => md).join('-')];
    if (months[0]) entityData.period.monthRanges = [months.filter((m) => m).join('-')];
    entityData.period.utcOffset = utcOffset || '+00:00';

    if (isAudit) entityData.taskActions.audit = true;
    if (isInventorization) entityData.taskActions.inventorization = true;
    if (isAgentUpdate) entityData.taskActions.agent_update = true;
    if (isPackageUpdate) entityData.taskActions.package_update = true;
    if (isKbInstall) entityData.taskActions.kb_install = true;
    if (isReboot) entityData.taskActions.system_reboot = true;

    patchEntity(entityData);
  };

  return (
    <Dialog
      className={`maintenance-modal small ${styles.windowModal}`}
      title={
        isWindowEdit
          ? localeMaintenance[currentLocale].mWindowModal.editWindowTitle
          : localeMaintenance[currentLocale].mWindowModal.createWindowTitle
        // localeMaintenance[currentLocale].windowModalTitle +
        // (entityType.current
        //   ? ` ${localeMaintenance[currentLocale].entityTypes[entityType.current]}`
        //   : '') +
        // (entityName.current ? ` "${entityName.current}"` : '')
      }
      onClose={closeWindow}
    >
      <Form
        onSubmitClick={handleSubmit}
        render={(): JSX.Element => (
          <FormElement
            className={styles.windowEditModal}
            style={{ display: 'flex', flexDirection: 'column', gap: '20px 0' }}
          >
            <p
              style={{
                margin: '0',
              }}
            >
              {localeMaintenance[currentLocale].mWindowDescription}
            </p>
            <MaintenanceSchedule
              hours={hours}
              setHours={setHours}
              weekDays={weekDays}
              setWeekDays={setWeekDays}
              monthDays={monthDays}
              setMonthDays={setMonthDays}
              months={months}
              setMonths={setMonths}
              utcOffset={utcOffset}
              setUtcOffset={setUtcOffset}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <h4>{localeMaintenance[currentLocale].mWindowModal.allowActions}:</h4>
              <Field
                name="audit"
                component={CheckBox}
                value={isAudit}
                checked={isAudit}
                label={localeNewTask[currentLocale].form.todoActions.auditVul}
                onChange={(e): void => {
                  setAudit(e.target.value);
                }}
              />
              <Field
                name="inventorization"
                component={CheckBox}
                value={isInventorization}
                checked={isInventorization}
                label={localeNewTask[currentLocale].form.todoActions.inventorization}
                onChange={(e): void => {
                  setInventorization(e.target.value);
                }}
              />
              <Field
                name="agentUpdate"
                component={CheckBox}
                value={isAgentUpdate}
                checked={isAgentUpdate}
                label={localeNewTask[currentLocale].form.todoActions.agentUpdate}
                onChange={(e): void => {
                  setAgentUpdate(e.target.value);
                }}
              />
              <Field
                name="packageUpdate"
                component={CheckBox}
                value={isPackageUpdate}
                checked={isPackageUpdate}
                label={localeNewTask[currentLocale].form.todoActions.packageUpdate}
                onChange={(e): void => {
                  setPackageUpdate(e.target.value);
                }}
              />
              <Field
                name="kbInstall"
                component={CheckBox}
                value={isKbInstall}
                checked={isKbInstall}
                label={localeNewTask[currentLocale].form.todoActions.kbUpdate}
                onChange={(e): void => {
                  setKbInstall(e.target.value);
                }}
              />
              {/* <Field
                name="reboot"
                component={CheckBox}
                value={isReboot}
                checked={isReboot}
                label="Перезагрузка"
                onChange={(e): void => {
                  setReboot(e.target.value);
                }}
              /> */}
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  !(
                    (hours.filter((h) => h).join('-') ||
                      weekDays.filter((h) => h).join('-') ||
                      monthDays.filter((h) => h).join('-') ||
                      months.filter((h) => h).join('-')) &&
                    (isAudit ||
                      isInventorization ||
                      isAgentUpdate ||
                      isPackageUpdate ||
                      isKbInstall ||
                      isReboot)
                  )
                }
              >
                {isWindowEdit
                  ? localeButtons[currentLocale].save
                  : localeButtons[currentLocale].create}
              </Button>
              <Button type="button" onClick={closeWindow}>
                {localeButtons[currentLocale].close}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
