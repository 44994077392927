/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { Fade } from '@progress/kendo-react-animation';
import '../../TaskDescription.module.scss';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { nanoid } from 'nanoid';
import localeTaskDescription from '../../../../../utils/i18n/taskLocale/taskDescription.json';
import type { IAssetGroupData } from '../../../../../utils/helpers/task-helpers';
import styles from '../../TaskList.module.scss';
import { ScopeElement } from '../../cellsTaskList/ScopeElement';
import { ScopeNotification } from '../../cellsTaskList/ScopeNotification';

type TSoftwareManagement = {
  action: 'update' | 'install' | 'uninstall';
  softwareList: (
    | {
        fullId: string;
        softwareCodename?: string;
        softwareName?: string;
        [k: string]: unknown;
      }
    | {
        softwareCodename: string;
        softwareName: string;
        [k: string]: unknown;
      }
  )[];
};
export function ColumnThirdSm({
  currentLocale,
  scoreData,
  isShow,
  setShow,
  isShowPkg,
  setShowPkg,
  softwareManagement,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  scoreData: IAssetGroupData;
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isShowPkg: boolean;
  setShowPkg: React.Dispatch<React.SetStateAction<boolean>>;
  softwareManagement: TSoftwareManagement;
}): React.ReactElement {
  const data = scoreData.assets
    ?.map((asset) => ({
      id: asset.assetId,
      // eslint-disable-next-line no-nested-ternary
      name:
        'imageNames' in asset && asset.imageNames?.length
          ? asset.imageNames[0]
          : 'hostname' in asset
          ? asset.hostname
          : null,
      taskType: '',
      deviceType: asset.type,
    }))
    .concat(
      scoreData.assetGroups?.map((assetGroup) => ({
        id: assetGroup.assetGroupId,
        name: assetGroup.name,
        taskType: 'assetgroup',
        deviceType: undefined,
      })) || [],
    )
    .concat(
      scoreData.registries?.map((asset) => ({
        id: asset.registryId,
        name: asset.name,
        taskType: 'registry',
        deviceType: undefined,
      })) || [],
    );

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>{localeTaskDescription[currentLocale].summary.scope}</td>
          <td>
            {data?.slice(0, 8).map((se) => (
              <ScopeElement element={se} key={nanoid()} />
            ))}
            {data && data.length > 8 && (
              <>
                <span
                  onMouseEnter={(): void => setShow(true)}
                  onMouseLeave={(): void => setShow(false)}
                >
                  {localeTaskDescription[currentLocale].summary.andMore} {data.length - 8}
                  <ScopeNotification
                    className={styles.asset_scope}
                    isShow={isShow}
                    data={data}
                    sliceValue={8}
                  />
                </span>
              </>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeTaskDescription[currentLocale].summary.softwareScope}</span>
          </td>
          <td>
            {softwareManagement &&
              softwareManagement.softwareList &&
              softwareManagement.softwareList.slice(0, 4).map((software) => (
                <div className={`${styles.scope}`} key={nanoid()}>
                  <span>{software.softwareName}</span>
                </div>
              ))}
            {softwareManagement &&
              softwareManagement.softwareList &&
              softwareManagement.softwareList.length > 4 && (
                <>
                  <span
                    onMouseEnter={(): void => setShowPkg(true)}
                    onMouseLeave={(): void => setShowPkg(false)}
                  >
                    {localeTaskDescription[currentLocale].summary.andMore}{' '}
                    {softwareManagement.softwareList.length - 4}
                    <NotificationGroup className={`${styles.notification}`}>
                      <Fade>
                        {isShowPkg && softwareManagement.softwareList.length > 4 && (
                          <Notification>
                            {softwareManagement.softwareList.slice(4).map((software) => (
                              <div className={`${styles.scope}`} key={nanoid()}>
                                <span>{software.softwareName}</span>
                              </div>
                            ))}
                          </Notification>
                        )}
                      </Fade>
                    </NotificationGroup>
                  </span>
                </>
              )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
