import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useState, type ReactElement } from 'react';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import localeSoftwareManagement from '../../../utils/i18n/assetLocales/softwareManagement.json';
import type { SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse } from '../../../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdatesWithAvailableDashboardResponse.v1';
import { SoftSizeCellCallback } from './cells/SoftSizeCell';
import { OnlyDateCell } from '../../common/cells/OnlyDateCell';
import { VersionCell } from './cells/VersionCell';
import { MenuCell } from './cells/MenuCell';
import { RadioCell } from './cells/RadioCell';
import { DownloadStatusCell } from './cells/DownloadStatusCell';
import { ApproveStatusCell } from './cells/ApproveStatusCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSoftwareManagement;

export function SoftManagementList({
  software,
  classNameValue,
}: {
  software: SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse['data'][0]['softwareUpdates'];
  classNameValue?: string;
}): ReactElement {
  const [page, setPage] = useState({ take: 5, skip: 0 });
  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'updatePublishedAt', dir: 'desc' }]);

  const pageChange = (event: GridPageChangeEvent): void => {
    setPage({
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  return (
    <div>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            className="no-margin-top"
            total={software.length}
            sortable
            sort={sort}
            style={{
              height: `${Math.min((software?.length || 1) * 40 + 100, 300)}px`,
            }}
            pageSize={page.take}
            skip={page.skip}
            pageable={software?.length > page.take}
            scrollable="none"
            onPageChange={pageChange}
            data={orderBy(software, sort).slice(page.skip, page.take + page.skip)}
            onSortChange={(e: GridSortChangeEvent): void => {
              setSort(e.sort);
            }}
          >
            {classNameValue && <Column field="name" title=" " cell={RadioCell} width="60px" />}
            <Column
              field="downloadStatus"
              title={localeSoftwareManagement[currentLocale].softwareTable.downloadStatus}
              cell={DownloadStatusCell}
              width="136px"
              sortable={false}
            />
            <Column
              field="approveStatus"
              title={localeSoftwareManagement[currentLocale].softwareTable.approveStatus}
              width="100px"
              cell={ApproveStatusCell}
              sortable={false}
            />
            <Column
              field="version"
              title={localeSoftwareManagement[currentLocale].softwareTable.version}
              filter="text"
              cell={VersionCell}
            />
            <Column
              field="arch"
              title={localeSoftwareManagement[currentLocale].softwareTable.arch}
              width="120px"
            />
            <Column
              field="language"
              title={localeSoftwareManagement[currentLocale].softwareTable.lang}
              filter="text"
              width="100px"
            />
            <Column
              field="installerType"
              title={localeSoftwareManagement[currentLocale].softwareTable.installType}
              width="140px"
            />
            <Column
              field="size"
              title={localeSoftwareManagement[currentLocale].softwareTable.size}
              cell={SoftSizeCellCallback}
              //   cell={HostsCount}
              width="100px"
              sortable
            />
            <Column
              field="updatePublishedAt"
              title={localeSoftwareManagement[currentLocale].softwareTable.publishedAt}
              cell={OnlyDateCell}
              //   cell={HostsCount}
              sortable
              filter="date"
              width="148px"
            />
            <Column cell={MenuCell} width="40px" />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
