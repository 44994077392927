/* eslint-disable react/jsx-pascal-case */
import { useQueryClient } from 'react-query';
import localeHostGroups from '../../../utils/i18n/assetLocales/assetGroups.json';
import { EditGroupForm } from './form/EditGroup';
import { useGroupContext } from '../../../hooks/useGroupContext';
import { getUrlHostGr } from '../../../utils/helpers/host-group-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { CommonGroupList } from '../../common/CommonGroupList';
import { Button } from '../../common/baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';
import { useMaintenanceWindowsContext } from '../../../hooks/useMaintenanceWindowsContext';
import { MaintenanceListModal } from '../Host/MaintenanceWindows/MaintenanceListModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function GroupList({ taskElement }: { taskElement?: string }): React.ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlHostGr('HostGroupList');

  const queryClient = useQueryClient();

  const { isOpen, setIsOpen } = useGroupContext();
  const { isTableOpen } = useMaintenanceWindowsContext();

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'HostGroupList',
  };

  const editThisGroup = (): void => {
    setIsOpen(true);
  };

  const handleRefresh = (): void => {
    queryClient.invalidateQueries('HostGroupList');
  };

  return (
    <div className="groups">
      {isTableOpen && <MaintenanceListModal />}
      {taskElement !== 'AddGroupsForTask' && (
        <div className="common-header-page">
          <div className="topactions">
            {currentUser?.role !== 'reader_user' && (
              <Button fill="action" onClick={editThisGroup}>
                {localeHostGroups[currentLocale].btnAddGroup}
              </Button>
            )}
            <Button onClick={handleRefresh} icon="refresh">
              {localeHostGroups[currentLocale].btnRefresh}
            </Button>
          </div>
        </div>
      )}
      <CommonGroupList
        dataForHooks={dataForHooks}
        urlParams={urlParams}
        taskElement={taskElement}
      />
      {isOpen && <EditGroupForm />}
    </div>
  );
}
