import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import { Button } from '../../../common/baseElements/Button';
import styles from './NewTask.module.scss';
import { useSoftwareManagementContext } from '../../../../hooks/useSoftwareManagementContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function MutableWinUpdateBlockTask(): React.ReactElement {
  const {
    isPkgWlTask,
    delSelectedWinSoftFrom,
    winSoftwareUpdateState,
    setWinSoftwareUpdateState,
    winSoftwareUpdateStateWl,
    setWinSoftwareUpdateStateWl,
  } = useHostModalContext();

  const { taskPackageUpdate, taskWinSoftUpdate, setSoftwareManagement, isSoftwareManagement } =
    useTaskAddContext();

  const { selectedSoftwareForUpdate } = useSoftwareManagementContext();

  useEffect(() => {
    if (winSoftwareUpdateState && selectedSoftwareForUpdate) {
      setWinSoftwareUpdateState((current) => ({
        ...current,
        ...selectedSoftwareForUpdate,
      }));
    }
    if (winSoftwareUpdateStateWl && selectedSoftwareForUpdate) {
      setWinSoftwareUpdateStateWl((current) => ({
        ...current,
        ...selectedSoftwareForUpdate,
      }));
    }
    if (!winSoftwareUpdateState && selectedSoftwareForUpdate) {
      setWinSoftwareUpdateState({
        ...selectedSoftwareForUpdate,
      });
    }
    // if (!winSoftwareUpdateStateWl && selectedSoftwareForUpdate) {
    //   setWinSoftwareUpdateStateWl({
    //     ...selectedSoftwareForUpdate,
    //   });
    // }
  }, []);

  useEffect(() => {
    if (
      winSoftwareUpdateState &&
      selectedSoftwareForUpdate &&
      Object.keys(selectedSoftwareForUpdate).length > 0 &&
      !isSoftwareManagement
    ) {
      setWinSoftwareUpdateState({ ...selectedSoftwareForUpdate });
    }
    if (
      winSoftwareUpdateStateWl &&
      selectedSoftwareForUpdate &&
      Object.keys(selectedSoftwareForUpdate).length > 0 &&
      !isSoftwareManagement
    ) {
      setWinSoftwareUpdateStateWl({ ...selectedSoftwareForUpdate });
    }
  }, [selectedSoftwareForUpdate]);

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        {taskWinSoftUpdate && (
          <div className={styles.taskWinUpdateActionWrap}>
            <span className="required-label">
              {localeNewTask[currentLocale].form.applyForAsset.titleUpdateSoft}
            </span>
            <Button
              className={styles.addAssetScope}
              type="button"
              onClick={(): void => setSoftwareManagement(true)}
            >
              {localeNewTask[currentLocale].form.addAssetScope}
            </Button>
          </div>
        )}
      </div>
      <div className="list-assets">
        {!isPkgWlTask &&
          taskWinSoftUpdate &&
          winSoftwareUpdateState &&
          Object.values(winSoftwareUpdateState)?.map((p, i) => {
            if (p.fullId)
              return (
                <Button
                  type="button"
                  fill="task_asset"
                  onClick={(e): void =>
                    delSelectedWinSoftFrom(Object.keys(winSoftwareUpdateState)[i], e, false)
                  }
                  key={nanoid()}
                >
                  <span>
                    {p.softwareName} {p.version} {p.arch} {p.language}{' '}
                    {Object.values(winSoftwareUpdateState).length - 1 !== i && ', '}
                  </span>
                </Button>
              );

            return (
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void =>
                  delSelectedWinSoftFrom(Object.keys(winSoftwareUpdateState)[i], e, false)
                }
                key={nanoid()}
              >
                <span>
                  {p.softwareName}
                  {Object.values(winSoftwareUpdateState).length - 1 !== i && ', '}
                </span>
              </Button>
            );
          })}
        {isPkgWlTask &&
          (taskPackageUpdate || taskWinSoftUpdate) &&
          winSoftwareUpdateStateWl &&
          Object.values(winSoftwareUpdateStateWl)?.map((p, i) => {
            if (p.fullId)
              return (
                <Button
                  type="button"
                  fill="task_asset"
                  onClick={(e): void =>
                    delSelectedWinSoftFrom(Object.keys(winSoftwareUpdateStateWl)[i], e, false)
                  }
                  key={nanoid()}
                >
                  <span>
                    {p.softwareName} {p.version} {p.arch} {p.language}{' '}
                    {Object.values(winSoftwareUpdateStateWl).length - 1 !== i && ', '}
                  </span>
                </Button>
              );

            return (
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => delSelectedWinSoftFrom(p.softwareName, e, true)}
                key={nanoid()}
              >
                <span>
                  {p.softwareName}
                  {Object.values(winSoftwareUpdateStateWl).length - 1 !== i && ', '}
                </span>
              </Button>
            );
          })}
      </div>
    </div>
  );
}
