import { Button } from '@progress/kendo-react-buttons';
import type { State } from '@progress/kendo-data-query';
import { useState } from 'react';
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { ISoftwareManagement } from '../../../../utils/helpers/task-helpers';
import { handlerInstalledSearch } from '../../../../utils/helpers/local-filter-helpers';
import styles from '../TaskDescription.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function TaskSmFastFilter({
  dataState,
  setDataState,
  softwareUpdate,
  filterValue,
}: {
  dataState: State;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  softwareUpdate: ISoftwareManagement;
  filterValue: React.MutableRefObject<any>;
}): React.ReactElement {
  const [activeBtn, setActiveBtn] = useState('all');

  return (
    <div className={styles.package_fast_filter}>
      <Button
        className={activeBtn === 'all' ? 'custom-type-search-active' : 'custom-type-search'}
        onClick={(): void =>
          handlerInstalledSearch(setDataState, filterValue, dataState, 'all', setActiveBtn, 'type')
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.all} (
        {softwareUpdate ? softwareUpdate.allSoftware.length : 0})
      </Button>
      <Button
        className={
          activeBtn === 'installedSoftware' ? 'custom-type-search-active' : 'custom-type-search'
        }
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'installedSoftware',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.installedSu} (
        {softwareUpdate ? softwareUpdate.installedSoftware.length : 0})
      </Button>
      <Button
        className={
          activeBtn === 'uninstalledSoftware' ? 'custom-type-search-active' : 'custom-type-search'
        }
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'uninstalledSoftware',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.deletedSu} (
        {softwareUpdate ? softwareUpdate.uninstalledSoftware.length : 0})
      </Button>
      <Button
        className={
          activeBtn === 'updatedSoftware' ? 'custom-type-search-active' : 'custom-type-search'
        }
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'updatedSoftware',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.updatedSoftware} (
        {softwareUpdate ? softwareUpdate.updatedSoftware.length : 0})
      </Button>
      <Button
        className={
          activeBtn === 'notUpdatedSoftware' ? 'custom-type-search-active' : 'custom-type-search'
        }
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'notUpdatedSoftware',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.notUpdatedSoftware} (
        {softwareUpdate ? softwareUpdate.notUpdatedSoftware.length : 0})
      </Button>
      <Button
        className={
          activeBtn === 'notFoundSoftware' ? 'custom-type-search-active' : 'custom-type-search'
        }
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'notFoundSoftware',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.notFoundSoftware} (
        {softwareUpdate ? softwareUpdate.notFoundSoftware.length : 0})
      </Button>
      <Button
        className={
          activeBtn === 'noAvailableUpdatesForSoftware'
            ? 'custom-type-search-active'
            : 'custom-type-search'
        }
        onClick={(): void =>
          handlerInstalledSearch(
            setDataState,
            filterValue,
            dataState,
            'noAvailableUpdatesForSoftware',
            setActiveBtn,
            'status',
          )
        }
      >
        {localeAuditTask[currentLocale].packageList.customSearch.noAvailableUpdatesForSoftware} (
        {softwareUpdate ? softwareUpdate.noAvailableUpdatesForSoftware.length : 0})
      </Button>
    </div>
  );
}
