import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';

export function CellAssetNameWl(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td>
      {dataItem.type === 'image' && dataItem.imageNames[0] && (
        <NavLink to={`/lk/assets/images/${dataItem.assetId}`}>{dataItem.imageNames[0]}</NavLink>
      )}
      {(dataItem.type === 'router' ||
        dataItem.type === 'firewall' ||
        dataItem.type === 'switch' ||
        dataItem.type === 'host' ||
        dataItem.type === 'hypervisor') &&
      dataItem.hostname ? (
        <NavLink
          to={
            dataItem.type === 'router' ||
            dataItem.type === 'firewall' ||
            dataItem.type === 'switch' ||
            dataItem.type === 'hypervisor'
              ? `/lk/assets/devices/${dataItem.type}/${dataItem.assetId}`
              : `/lk/assets/hosts/${dataItem.assetId}`
          }
        >
          {dataItem.hostname}
        </NavLink>
      ) : (
        <span>-</span>
      )}
      {dataItem.type === 'image' && !dataItem.imageNames[0] && <span>-</span>}
    </td>
  );
}
