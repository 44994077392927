/* eslint-disable react/jsx-indent */
import { Dialog } from '@progress/kendo-react-dialogs';
import { useCallback, useEffect, useState, type ReactElement } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useSoftwareManagementContext } from '../../../hooks/useSoftwareManagementContext';
import type { SavedWindowsSoftwareUpdateWithAvailableDashboardResponse } from '../../../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateWithAvailableDashboardResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getSoftwareInfo } from '../../../services/softManagement-service';
import { SoftwareModalMetablock } from './SoftwareModalMetablock';
import { Button } from '../../common/baseElements/Button';
import localeSofwareManagement from '../../../utils/i18n/assetLocales/softwareManagement.json';
import localeCommonButtons from '../../../utils/i18n/commonLocales/buttons.json';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSofwareManagement;

function replaceCommandString(commandString: string, installerName: string): string {
  const noSpacesInName = !(installerName?.indexOf(' ') > -1);

  return commandString
    .replace('{{installer.msi}}', noSpacesInName ? installerName : `"${installerName}"`)
    .replace('{{log.log}}', noSpacesInName ? `${installerName}.log` : `"${installerName}.log"`);
}

export function SoftwareModal(): ReactElement {
  const currentUser = getUserData();
  const {
    softwareVersionInfo,
    setModalOpen,
    setApproveToSoftware,
    downloadSoftware,
    openRemoveModal,
    selectedAccountId,
  } = useSoftwareManagementContext();

  const queryClient = useQueryClient();

  const query = useQuery<SavedWindowsSoftwareUpdateWithAvailableDashboardResponse, ApiError>(
    ['softwareInfo'],
    () =>
      getSoftwareInfo(
        softwareVersionInfo?.fullId || '',
        currentUser?.role === 'super_admin' ? selectedAccountId : null,
      ),
    {
      //   staleTime: DATA_CACHE_STALETIME,
      //   cacheTime: DATA_CACHE_CACHETIME,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  const [selected, setSelected] = useState<number>(0);

  const fileName =
    query.data?.fileName ||
    [
      query.data?.softwareCodename,
      query.data?.version,
      query.data?.arch,
      query.data?.language,
      query.data?.installerType,
    ]
      .filter((softwareField) => !!softwareField)
      .join('_');

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries('savedSoftwareList');
    };
  }, []);

  return (
    <Dialog
      className="software-info-modal modal"
      title={
        <span>
          {query.data?.softwareName || softwareVersionInfo?.fullId}{' '}
          {query.data?.version || softwareVersionInfo?.version}
          {query.data?.vendorName && (
            <span
              style={{
                color: '#ccc',
                fontSize: '.75em',
              }}
            >
              , {query.data?.vendorName}
            </span>
          )}
        </span>
      }
      onClose={(): void => setModalOpen(false)}
      autoFocus
    >
      <div
        style={{
          margin: '0 0 24px',
        }}
      >
        <div className="topactions">
          {!(
            query.data?.downloadStatus === 'downloaded' &&
            query.data?.downloadUrl &&
            query.data?.softwareUpdateId
          ) && (
            <Button
              onClick={(): void => {
                downloadSoftware([query.data?.fullId as string]);
              }}
            >
              {localeSofwareManagement[currentLocale].buttons.downloadOnprem}
            </Button>
          )}
          <Button
            disabled={
              !(
                query.data?.softwareUpdateId &&
                ((query.data?.downloadStatus === 'downloaded' && query.data?.downloadUrl) ||
                  (query.data?.downloadStatus === 'file_not_saved' && query.data?.isApproved))
              )
            }
            onClick={(): void => {
              setApproveToSoftware(
                query.data?.softwareUpdateId,
                [
                  query.data?.softwareCodename,
                  query.data?.version,
                  query.data?.arch,
                  query.data?.language,
                  query.data?.installerType,
                ]
                  .filter((softwareField) => !!softwareField)
                  .join('_'),
                !query.data?.isApproved,
                true,
              );
            }}
          >
            {query.data?.isApproved
              ? localeSofwareManagement[currentLocale].buttons.disapprove
              : localeSofwareManagement[currentLocale].buttons.approve}
          </Button>
          {(((query.data?.downloadStatus === 'file_not_saved' ||
            query.data?.downloadStatus === 'error') &&
            query.data?.softwareUpdateId) ||
            (query.data?.downloadStatus === 'downloaded' &&
              query.data?.downloadUrl &&
              query.data?.softwareUpdateId)) && (
            <Button
              onClick={(): void => {
                openRemoveModal(
                  query.data?.softwareUpdateId,
                  [
                    query.data?.softwareCodename,
                    query.data?.version,
                    query.data?.arch,
                    query.data?.language,
                    query.data?.installerType,
                  ]
                    .filter((softwareField) => !!softwareField)
                    .join('_'),
                  true,
                );
              }}
            >
              {localeSofwareManagement[currentLocale].buttons.removeOnprem}
            </Button>
          )}
        </div>
      </div>
      <SoftwareModalMetablock data={query.data} />
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title={localeSofwareManagement[currentLocale].modal.aboutTab.title}>
          <div>
            <h4>{query?.data?.description}</h4>
            {query.data?.releaseNotes && (
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                <span>{localeSofwareManagement[currentLocale].modal.aboutTab.releaseNotes}:</span>
                <div
                  style={{
                    marginTop: '4px',
                  }}
                >
                  {query.data.releaseNotes}
                </div>
              </div>
            )}
          </div>
        </TabStripTab>
        <TabStripTab title={localeSofwareManagement[currentLocale].modal.installerTab.title}>
          <div>
            <div>
              {localeSofwareManagement[currentLocale].modal.byteSize}:{' '}
              {query.data?.installer.size
                ? query.data?.installer.size.toString().replace(/\d(?=(?:\d{3})+\b)/g, '$& ')
                : '-'}{' '}
              {/* {localeSofwareManagement[currentLocale].modal.byte} */}
            </div>
            <div>
              {localeSofwareManagement[currentLocale].modal.installerTab.rebootRequired}:{' '}
              {query.data?.commands.update?.isSystemRebootAfter
                ? localeSofwareManagement[currentLocale].booleans.true
                : localeSofwareManagement[currentLocale].booleans.false}
            </div>
            {Object.keys(query.data?.installer.hashes || {}).length && (
              <>
                <h4>{localeSofwareManagement[currentLocale].modal.installerTab.hashes}:</h4>
                <ul style={{ paddingLeft: '16px', margin: '0' }}>
                  {Object.entries(query.data?.installer.hashes || {}).map(
                    ([hashName, hashValue]) => (
                      <li>
                        {hashName}: {hashValue}
                      </li>
                    ),
                  )}
                </ul>
              </>
            )}
            {Object.keys(query.data?.installer.urls || {}).length && (
              <>
                <h4>{localeSofwareManagement[currentLocale].modal.installerTab.urls}:</h4>
                <ul style={{ paddingLeft: '16px', margin: '0' }}>
                  {query.data?.installer.urls?.vendor?.map((d) => (
                    <li>
                      <a href={d.url}>{d.url}</a>
                    </li>
                  ))}
                  {query.data?.installer.urls?.vulnsio?.map((d) => (
                    <li>
                      <a href={d.url}>{d.url}</a>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {query.data?.commands.update && (
              <>
                <h4>{localeSofwareManagement[currentLocale].modal.installerTab.commands}:</h4>
                <div
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '12px',
                    fontFamily: 'monospace',
                    lineHeight: '1.5',
                  }}
                >
                  {query.data?.commands.update.steps.beforeUpdate && (
                    <div>
                      {Object.values(query.data?.commands.update.steps.beforeUpdate).map((d) => (
                        <div>{replaceCommandString(d.command, fileName)}</div>
                      ))}
                    </div>
                  )}
                  {query.data?.commands.update.steps.update && (
                    <div>
                      {Object.values(query.data?.commands.update.steps.update).map((d) => (
                        <div>{replaceCommandString(d.command, fileName)}</div>
                      ))}
                    </div>
                  )}
                  {query.data?.commands.update.steps.afterUpdate && (
                    <div>
                      {Object.values(query.data?.commands.update.steps.afterUpdate).map((d) => (
                        <div>{replaceCommandString(d.command, fileName)}</div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
            {query.data?.commands.installation?.steps.beforeInstall}
          </div>
        </TabStripTab>
      </TabStrip>
      <div className="k-form-buttons">
        <Button type="button" onClick={(): void => setModalOpen(false)}>
          {localeCommonButtons[currentLocale].close}
        </Button>
      </div>
    </Dialog>
  );
}
