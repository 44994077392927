import { useState, type ReactElement } from 'react';
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListFilterChangeEvent,
} from '@progress/kendo-react-dropdowns';
import '@progress/kendo-date-math/tz/all';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
// import { Input } from '../../../common/baseElements/Input';
import { Input } from '@progress/kendo-react-inputs';
import styles from './MaintenanceWindows.module.scss';
import localeMaintenance from '../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeMaintenance;

const weekdayDropdownData = Object.entries(localeMaintenance[currentLocale].weekDays)
  .sort((a, b) => {
    if (!a[0] || !b[0]) return 1;

    return 0;
  })
  .map(([weekdayNumber, weekdayName]) => ({
    text: weekdayName,
    id: weekdayNumber,
  }));

const monthDropdownData = Object.entries(localeMaintenance[currentLocale].months)
  .sort((a, b) => {
    if (!a[0] || !b[0]) return 1;

    return 0;
  })
  .map(([monthNumber, monthName]) => ({
    text: monthName,
    id: monthNumber,
  }));

const utcVariants = [
  '-11:00',
  '-10:00',
  '-09:30',
  '-09:00',
  '-08:00',
  '-07:00',
  '-06:00',
  '-05:00',
  '-04:00',
  '-03:30',
  '-03:00',
  '-02:00',
  '-01:00',
  '+00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+03:30',
  '+04:00',
  '+04:30',
  '+05:00',
  '+05:30',
  '+05:45',
  '+06:00',
  '+06:30',
  '+07:00',
  '+08:00',
  '+08:45',
  '+09:00',
  '+09:30',
  '+10:00',
  '+10:30',
  '+11:00',
  '+12:00',
  '+12:45',
  '+13:00',
  '+13:45',
  '+14:00',
];

export function MaintenanceSchedule({
  hours,
  setHours,
  weekDays,
  setWeekDays,
  monthDays,
  setMonthDays,
  months,
  setMonths,
  utcOffset,
  setUtcOffset,
}: {
  hours: [string, string];
  setHours: React.Dispatch<React.SetStateAction<[string, string]>>;
  weekDays: [string, string];
  setWeekDays: React.Dispatch<React.SetStateAction<[string, string]>>;
  monthDays: [string, string];
  setMonthDays: React.Dispatch<React.SetStateAction<[string, string]>>;
  months: [string, string];
  setMonths: React.Dispatch<React.SetStateAction<[string, string]>>;
  utcOffset: string;
  setUtcOffset: React.Dispatch<React.SetStateAction<string>>;
}): ReactElement {
  const [timeZones, setTimeZones] = useState(utcVariants);

  const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor): any[] => {
    const data = utcVariants;

    return filterBy(data, filter);
  };

  const filterChange = (event: DropDownListFilterChangeEvent): void => {
    setTimeZones(filterData(event.filter));
  };

  return (
    <div>
      <h4>{localeMaintenance[currentLocale].mWindowModal.setSchedule}:</h4>
      <table className={styles.scheduleTable}>
        <tr>
          <td className={styles.timeNameTd}>
            <span>{localeMaintenance[currentLocale].editModal.hours}:</span>
          </td>
          <td>{localeMaintenance[currentLocale].editModal.from}</td>
          <td>
            <Input
              placeholder="12:00"
              // value={hours[0]}
              value={hours[0]}
              style={{ width: '88px' }}
              type="time"
              onChange={(e): void => {
                setHours([e.target.value as string, hours[1]]);
              }}
            />{' '}
            {localeMaintenance[currentLocale].editModal.to}{' '}
            <Input
              placeholder="13:00"
              type="time"
              value={hours[1]}
              style={{ width: '88px' }}
              onChange={(e): void => {
                setHours([hours[0], e.target.value as string]);
              }}
              valid={
                !(
                  hours[0] &&
                  hours[1] &&
                  parseInt(hours[0].replace(':', ''), 10) >= parseInt(hours[1].replace(':', ''), 10)
                )
              }
              validationMessage={localeMaintenance[currentLocale].mWindowModal.invalidatePeriod}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeMaintenance[currentLocale].editModal.weekDays}:</span>
          </td>
          <td>{localeMaintenance[currentLocale].editModal.from}</td>
          <td>
            <DropDownList
              onChange={(e: DropDownListChangeEvent): void => {
                setWeekDays((prev) => [e.target.value.id, prev[1]]);
              }}
              textField="text"
              dataItemKey="id"
              style={{ width: '150px' }}
              data={weekdayDropdownData}
              value={weekdayDropdownData.find((wdd) => wdd.id === weekDays[0])}
            />{' '}
            {localeMaintenance[currentLocale].editModal.to}{' '}
            <DropDownList
              onChange={(e: DropDownListChangeEvent): void => {
                setWeekDays((prev) => [prev[0], e.target.value.id]);
              }}
              textField="text"
              dataItemKey="id"
              style={{ width: '150px' }}
              data={weekdayDropdownData}
              value={weekdayDropdownData.find((wdd) => wdd.id === weekDays[1])}
              valid={
                !(
                  weekDays[0] &&
                  weekDays[1] &&
                  parseInt(weekDays[0], 10) >= parseInt(weekDays[1], 10)
                )
              }
              validationMessage={localeMaintenance[currentLocale].mWindowModal.invalidatePeriod}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeMaintenance[currentLocale].editModal.monthDays}:</span>
          </td>
          <td>{localeMaintenance[currentLocale].editModal.from}</td>
          <td>
            <Input
              value={monthDays[0]}
              style={{ width: '80px' }}
              max={31}
              onChange={(e): void => {
                setMonthDays([e.target.value as string, monthDays[1]]);
              }}
              type="number"
              placeholder="1"
            />{' '}
            {localeMaintenance[currentLocale].editModal.to}{' '}
            <Input
              value={monthDays[1]}
              style={{ width: '80px' }}
              max={31}
              onChange={(e): void => {
                setMonthDays([monthDays[0], e.target.value as string]);
              }}
              type="number"
              placeholder="2"
              valid={
                !(
                  monthDays[0] &&
                  monthDays[1] &&
                  parseInt(monthDays[0], 10) >= parseInt(monthDays[1], 10)
                )
              }
              validationMessage={localeMaintenance[currentLocale].mWindowModal.invalidatePeriod}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeMaintenance[currentLocale].editModal.months}:</span>
          </td>
          <td>{localeMaintenance[currentLocale].editModal.from}</td>
          <td>
            <DropDownList
              textField="text"
              dataItemKey="id"
              style={{ width: '150px' }}
              data={monthDropdownData}
              onChange={(e: DropDownListChangeEvent): void => {
                setMonths((prev) => [e.target.value.id, prev[1]]);
              }}
              value={monthDropdownData.find((md) => md.id === months[0])}
            />{' '}
            {localeMaintenance[currentLocale].editModal.to}{' '}
            <DropDownList
              textField="text"
              dataItemKey="id"
              style={{ width: '150px' }}
              data={monthDropdownData}
              onChange={(e: DropDownListChangeEvent): void => {
                setMonths((prev) => [prev[0], e.target.value.id]);
              }}
              value={monthDropdownData.find((md) => md.id === months[1])}
              valid={
                !(months[0] && months[1] && parseInt(months[0], 10) >= parseInt(months[1], 10))
              }
              validationMessage={localeMaintenance[currentLocale].mWindowModal.invalidatePeriod}
            />
          </td>
        </tr>
        <tr>
          <td>{localeMaintenance[currentLocale].editModal.timeOffset}:</td>
          <td />
          <td>
            <DropDownList
              value={utcOffset}
              defaultValue={utcOffset}
              filterable
              onFilterChange={filterChange}
              onChange={(e: DropDownListChangeEvent): void => {
                setUtcOffset(e.target.value);
              }}
              required
              data={timeZones}
            />
          </td>
        </tr>
      </table>
    </div>
  );
}
