import { nanoid } from 'nanoid';
import { Button } from '../baseElements/Button';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useGroupContext } from '../../../hooks/useGroupContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function DynamicGroupMutableBlock(): React.ReactElement {
  const { setOpenDynamicGroupList } = useHostModalContext();

  const { dynamicGroupForm, deleteDynamicGroupFromTask } = useGroupContext();

  return (
    <div className="apply-assets-group">
      <div className="title-apply-assets">
        <span>{localeNewTask[currentLocale].form.applyForAsset.titleDynamicGroup}</span>
        <Button onClick={(): void => setOpenDynamicGroupList(true)} type="button">
          {localeNewTask[currentLocale].form.addDynamicGroup}
        </Button>
      </div>
      <div className="list-assets">
        {dynamicGroupForm?.map((g) => {
          if (g.name) {
            return (
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteDynamicGroupFromTask(g.assetDynamicGroupId!, e)}
                key={nanoid()}
              >
                {g.name}
              </Button>
            );
          }

          return null;
        })}
        {(dynamicGroupForm?.length === 0 ||
          dynamicGroupForm === null ||
          !dynamicGroupForm.find((g) => g.assetDynamicGroupId)) && (
          <span className="placeholder">
            {localeNewTask[currentLocale].form.applyForAsset.assetDynamicGroupPlaceholder}
          </span>
        )}
      </div>
    </div>
  );
}
