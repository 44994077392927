/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from '@progress/kendo-react-form';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { CheckBox } from '../../../common/form/CheckBox';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import { getUserData } from '../../../../services/local-storage-service';
import styles from './NewTask.module.scss';
import { TaskReport } from './TaskReport';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { TaskKbAdditionalParam } from './TaskKbAdditionalParam';
import { TaskPkgAdditionalParam } from './TaskPkgAdditionalParam';
import { CommonTooltip } from '../../../common/CommonTooltip';
import { TaskActionNotification } from './TaskActionNotification';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function TaskActions(): React.ReactElement {
  const currentUser = getUserData();

  const {
    taskInventorization,
    setTaskInventorization,
    taskAudit,
    setTaskAudit,
    taskAgentUpdate,
    setTaskAgentUpdate,
    taskAuditImage,
    setTaskAuditImage,
    taskType,
    taskPackageUpdate,
    taskWinSoftUpdate,
    setTaskPackageUpdate,
    setTaskWinSoftUpdate,
    taskKbUpdate,
    setTaskKbUpdate,
    taskWhiteList,
    setTaskWhiteList,
    runScanFirst,
    setRunScanFirst,
  } = useTaskAddContext();

  const { missedKbs, selectedPkgs } = useHostModalContext();

  return (
    <div className="newtask__checkbox-block">
      <div className={styles.titleTaskWrapTooltip}>
        <label className="k-label required-label">
          {localeNewTask[currentLocale].form.todoActions.title}
        </label>
        <div className={styles.tooltipWrap}>
          <CommonTooltip>
            <TaskActionNotification />
          </CommonTooltip>
        </div>
      </div>
      {taskType.value.id === 'asset' && (
        <>
          <div className={styles.auditWhitelistWrap}>
            <Field
              name="audit"
              component={CheckBox}
              checked={taskAudit}
              value={taskAudit}
              label={localeNewTask[currentLocale].form.todoActions.auditVul}
              onChange={(e): void => {
                setTaskAudit(e.target.value);
              }}
            />
            {taskAudit && (
              <div className={styles.wlContentWrap}>
                <Field
                  name="taskWhiteList"
                  component={CheckBox}
                  checked={taskWhiteList}
                  value={taskWhiteList}
                  label={localeNewTask[currentLocale].form.todoActions.whiteList}
                  onChange={(e): void => {
                    setTaskWhiteList(e.target.value);
                  }}
                  className={styles.childCheckbox}
                />
                <Field
                  name="runScanFirst"
                  component={CheckBox}
                  checked={runScanFirst}
                  value={runScanFirst}
                  label={localeNewTask[currentLocale].form.todoActions.auditVulIsScan}
                  onChange={(e): void => {
                    setRunScanFirst(e.target.value);
                  }}
                  className={styles.childCheckbox}
                />
                <Field
                  name="audit_image"
                  component={CheckBox}
                  checked={taskAuditImage}
                  value={taskAuditImage}
                  label={localeNewTask[currentLocale].form.todoActions.auditInHostImg}
                  onChange={(e): void => {
                    setTaskAuditImage(e.target.value);
                  }}
                  className={styles.childCheckbox}
                />
              </div>
            )}
          </div>
          <Field
            name="inventorization"
            component={CheckBox}
            checked={taskInventorization}
            value={taskInventorization}
            label={localeNewTask[currentLocale].form.todoActions.inventorization}
            onChange={(e): void => {
              setTaskInventorization(e.target.value);
            }}
          />
          {currentUser?.role !== 'reader_user' && currentUser?.role !== 'user' && (
            <Field
              name="agent_update"
              component={CheckBox}
              checked={taskAgentUpdate}
              value={taskAgentUpdate}
              label={localeNewTask[currentLocale].form.todoActions.agentUpdate}
              onChange={(e): void => {
                setTaskAgentUpdate(e.target.value);
              }}
            />
          )}
          {currentUser?.role !== 'reader_user' && !missedKbs && !taskWinSoftUpdate && (
            <>
              <Field
                name="package_update"
                component={CheckBox}
                checked={taskPackageUpdate}
                value={taskPackageUpdate}
                label={localeNewTask[currentLocale].form.todoActions.packageUpdate}
                onChange={(e): void => {
                  setTaskPackageUpdate(e.target.value);
                }}
              />
              {taskPackageUpdate && <TaskPkgAdditionalParam />}
            </>
          )}
          {currentUser?.role !== 'reader_user' && !missedKbs && !taskKbUpdate && (
            <>
              <Field
                name="windows_software_management"
                component={CheckBox}
                checked={taskWinSoftUpdate}
                value={taskWinSoftUpdate}
                label={localeNewTask[currentLocale].form.todoActions.winSoftUpdate}
                onChange={(e): void => {
                  setTaskWinSoftUpdate(e.target.value);
                }}
              />
              {/* {taskWinSoftUpdate && <TaskPkgAdditionalParam />} */}
            </>
          )}
          {currentUser?.role !== 'reader_user' && !selectedPkgs && !taskWinSoftUpdate && (
            <>
              <Field
                name="kb_install"
                component={CheckBox}
                checked={taskKbUpdate}
                value={taskKbUpdate}
                label={localeNewTask[currentLocale].form.todoActions.kbUpdate}
                onChange={(e): void => {
                  setTaskKbUpdate(e.target.value);
                }}
              />
              {taskKbUpdate && <TaskKbAdditionalParam currentLocale={currentLocale} />}
            </>
          )}
        </>
      )}
      <TaskReport currentLocale={currentLocale} />
    </div>
  );
}
