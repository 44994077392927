import { ChangeEvent, useEffect, useState } from 'react';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import locale from '../../../utils/i18n/filterComponent/inputFilter.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;
export function FilterInputFrom05To1(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  const [value, setValue] = useState<number>(0.5);

  const changeDate = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length === 0) {
      onFilterChange({
        nextFilter: { ...filter, ...{ value: 0.5, text: '' } },
      });

      setValue(0.5);
    }

    if (event.target.value && event.target.value.length > 0 && Number(event.target.value) >= 0) {
      onFilterChange({
        nextFilter: { ...filter, ...{ value: Number(event.target.value), text: '' } },
      });

      setValue(Number(event.target.value));
    } else if (
      event.target.value &&
      event.target.value.length > 0 &&
      Number(event.target.value) < 0
    ) {
      onFilterChange({
        nextFilter: { ...filter, ...{ value: 0.5, text: '' } },
      });

      setValue(0.5);
    }
  };

  useEffect(() => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: 0.5, text: '' } },
    });

    setValue(0.5);
  }, []);

  return (
    <div className="example-wrapper">
      <div className="col-xs-12 col-sm-6 example-col">
        <input
          className="k-input"
          type="number"
          placeholder={locale[currentLocale].inputInteger}
          value={value}
          onChange={(e): void => changeDate(e)}
          step="0.5"
          min="0.5"
          max="1"
        />
      </div>
    </div>
  );
}
