/* eslint-disable no-nested-ternary */
import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { getAccountId } from '../services/local-storage-service';
// import localeUserList from '../utils/i18n/userLocales/userList.json';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import type { SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdatesWithAvailableDashboardResponse.v1';
import type { SavedWindowsSoftwareUpdateCreationRequest } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateCreationRequest.v1';
import type { SavedWindowsSoftwareUpdateCreationResponse } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateCreationResponse.v1';
import {
  donwloadSoftwares,
  sendApproveStatus,
  removeSoftware as removeSoftwareFromStorage,
} from '../services/softManagement-service';
import type { SavedWindowsSoftwareUpdateUpdateResponse } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateUpdateResponse.v1';
import type { SavedWindowsSoftwareUpdateDeletionResponse } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateDeletionResponse.v1';

export type TSelectedSoftwareForUpdate = {
  softwareName: string;
  version?: string;
  arch?: string;
  language?: string;
  fullId?: string;
};
interface ISoftwareManagement {
  softwareApproveName: string | null;
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isApproveSubmitOpen: boolean;
  isRemoveSubmitOpen: boolean;
  softwareVersionInfo: ISoftwareInfo | null;
  errorMutation: string;
  setErrorMutation: React.Dispatch<React.SetStateAction<string>>;
  selectedAccountId: string | null;
  setSelectedAccountId: React.Dispatch<React.SetStateAction<string | null>>;
  setSoftwareVersionInfo: React.Dispatch<React.SetStateAction<ISoftwareInfo | null>>;
  downloadSoftware: (fullIds: string[]) => void;
  // selectedSoftwareForUpdate: TSelectedSoftwareForUpdate[] | null;
  // setSelectedSoftwareForUpdate: React.Dispatch<
  //   React.SetStateAction<TSelectedSoftwareForUpdate[] | null>
  // >;
  selectedSoftwareForUpdate: Record<string, TSelectedSoftwareForUpdate>;
  setSelectedSoftwareForUpdate: React.Dispatch<
    React.SetStateAction<Record<string, TSelectedSoftwareForUpdate>>
  >;
  addSoftware: (softwareCodeName: string, value: TSelectedSoftwareForUpdate) => void;
  removeSoftware: (softwareName: string) => void;
  setApproveToSoftware: (
    softwareUpdateId: string | undefined,
    softwareName: string,
    approveStatus: boolean,
    isModal?: boolean,
  ) => void;
  removeSoftwareFromOnprem: () => void;
  closeApproveModal: () => void;
  approveSoftware: () => void;
  openRemoveModal: (
    softwareUpdateId: string | undefined,
    softwareName: string,
    isModal?: boolean,
  ) => void;
  closeRemoveModal: () => void;
}

type ISoftwareInfo =
  SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse['data'][0]['softwareUpdates'][0];

// const currentLocale = (
//   window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
// ) as keyof typeof localeUserList;

// const SoftwareManagementContext = createContext<IWhiteListContext | null>(null);
const SoftwareManagementContext = createContext<ISoftwareManagement | null>(null);

export function SoftwareManagementProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isApproveSubmitOpen, setApproveSubmitOpen] = useState<boolean>(false);
  const [isRemoveSubmitOpen, setRemoveSubmitOpen] = useState<boolean>(false);
  const [softwareApproveName, setSoftwareApproveName] = useState<string | null>(null);
  const softwareUpdateIdModal = useRef<{
    updateId: string | undefined;
    isModal?: boolean;
  }>({
    updateId: undefined,
  });

  const [softwareVersionInfo, setSoftwareVersionInfo] = useState<ISoftwareInfo | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  // const [selectedSoftwareForUpdate, setSelectedSoftwareForUpdate] = useState<
  //   TSelectedSoftwareForUpdate[] | null
  // >(null);

  const [selectedSoftwareForUpdate, setSelectedSoftwareForUpdate] = useState<
    Record<string, TSelectedSoftwareForUpdate>
  >({});

  const [selectedAccountId, setSelectedAccountId] = useState(getAccountId());

  const queryClient = useQueryClient();

  const mutationDownloadSoftwareToOnprem = useMutation<
    SavedWindowsSoftwareUpdateCreationResponse,
    ApiError,
    SavedWindowsSoftwareUpdateCreationRequest
  >((payload) => donwloadSoftwares(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('softwareInfo');
      queryClient.invalidateQueries('savedSoftwareList');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationApprovingSoftware = useMutation<
    SavedWindowsSoftwareUpdateUpdateResponse,
    ApiError,
    { softwareUpdateId: string; approveStatus: boolean; isModal?: boolean }
  >(({ softwareUpdateId, approveStatus }) => sendApproveStatus(softwareUpdateId, approveStatus), {
    onSuccess: (_, { isModal }) => {
      if (isModal) queryClient.invalidateQueries('softwareInfo');
      else {
        queryClient.invalidateQueries('savedSoftwareList');
        queryClient.invalidateQueries('softwareStats');
      }
      setApproveSubmitOpen(false);
      queryClient.invalidateQueries('infoAuditId');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationRemovingSoftware = useMutation<
    SavedWindowsSoftwareUpdateDeletionResponse,
    ApiError,
    { softwareUpdateId: string; isModal?: boolean }
  >(({ softwareUpdateId }) => removeSoftwareFromStorage(softwareUpdateId), {
    onSuccess: (_, { isModal }) => {
      if (isModal) queryClient.invalidateQueries('softwareInfo');
      else queryClient.invalidateQueries('savedSoftwareList');
      setRemoveSubmitOpen(false);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const downloadSoftware = useCallback(
    (fullIds: string[]) => {
      mutationDownloadSoftwareToOnprem.mutateAsync({
        accountId: selectedAccountId || '',
        softwareUpdateFullIds: fullIds.map((fullId) => ({ fullId, downloadUrl: null })),
      });
    },
    [selectedAccountId],
  );

  const addSoftware = useCallback((softwareCodeName: string, value: TSelectedSoftwareForUpdate) => {
    setSelectedSoftwareForUpdate((current) => ({
      ...current,
      [softwareCodeName]: value,
    }));
  }, []);

  const setApproveToSoftware = useCallback(
    (
      softwareUpdateId: string | undefined,
      softwareName: string,
      approveStatus: boolean,
      isModal?: boolean,
    ) => {
      softwareUpdateIdModal.current = { updateId: softwareUpdateId, isModal };
      setSoftwareApproveName(softwareName);
      if (softwareUpdateId && approveStatus === false)
        mutationApprovingSoftware.mutateAsync({
          softwareUpdateId: softwareUpdateId || softwareUpdateIdModal.current.updateId || '',
          approveStatus,
          isModal,
        });
      else if (softwareUpdateId && approveStatus) setApproveSubmitOpen(true);
    },
    [],
  );

  const approveSoftware = useCallback(() => {
    if (softwareUpdateIdModal.current.updateId)
      mutationApprovingSoftware.mutateAsync({
        softwareUpdateId: softwareUpdateIdModal.current.updateId,
        approveStatus: true,
        isModal: softwareUpdateIdModal.current.isModal,
      });
  }, []);

  const removeSoftware = useCallback((softwareName: string) => {
    setSelectedSoftwareForUpdate((current) => {
      const copy = { ...current };

      delete copy[softwareName];

      return copy;
    });
  }, []);

  const removeSoftwareFromOnprem = useCallback(() => {
    mutationRemovingSoftware.mutateAsync({
      softwareUpdateId: softwareUpdateIdModal.current.updateId || '',
      isModal: softwareUpdateIdModal.current.isModal,
    });
  }, []);

  const closeApproveModal = useCallback(() => {
    softwareUpdateIdModal.current = { updateId: undefined };
    setApproveSubmitOpen(false);
  }, []);

  const openRemoveModal = useCallback(
    (softwareUpdateId: string | undefined, softwareName: string, isModal?: boolean) => {
      softwareUpdateIdModal.current = { updateId: softwareUpdateId, isModal };
      setSoftwareApproveName(softwareName);
      setRemoveSubmitOpen(true);
    },
    [],
  );

  const closeRemoveModal = useCallback(() => {
    softwareUpdateIdModal.current = { updateId: undefined };
    setRemoveSubmitOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      softwareApproveName,
      isModalOpen,
      setModalOpen,
      isApproveSubmitOpen,
      isRemoveSubmitOpen,
      softwareVersionInfo,
      setSoftwareVersionInfo,
      errorMutation,
      setErrorMutation,
      selectedAccountId,
      setSelectedAccountId,
      downloadSoftware,
      selectedSoftwareForUpdate,
      setSelectedSoftwareForUpdate,
      addSoftware,
      removeSoftware,
      setApproveToSoftware,
      removeSoftwareFromOnprem,
      approveSoftware,
      closeApproveModal,
      openRemoveModal,
      closeRemoveModal,
    }),
    [
      softwareApproveName,
      isModalOpen,
      isApproveSubmitOpen,
      isRemoveSubmitOpen,
      softwareVersionInfo,
      errorMutation,
      selectedAccountId,
      downloadSoftware,
      selectedSoftwareForUpdate,
      addSoftware,
      removeSoftware,
      setApproveToSoftware,
      removeSoftwareFromOnprem,
      approveSoftware,
      closeApproveModal,
      openRemoveModal,
      closeRemoveModal,
    ],
  );

  return (
    <SoftwareManagementContext.Provider value={value}>
      {children}
    </SoftwareManagementContext.Provider>
  );
}

export const useSoftwareManagementContext = (): ISoftwareManagement => {
  const context = useContext(SoftwareManagementContext);
  if (context === null) {
    throw new Error(
      'useSoftwareManagementContext must be used inside the WebSocketContext.Provider.',
    );
  }

  return context;
};
