/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { nanoid } from 'nanoid';
import localeTaskDescription from '../../../../../utils/i18n/taskLocale/taskDescription.json';
import type { IAssetGroupData } from '../../../../../utils/helpers/task-helpers';
import styles from '../../TaskDescription.module.scss';
import { ScopeElement } from '../../cellsTaskList/ScopeElement';
import { ScopeNotification } from '../../cellsTaskList/ScopeNotification';

export function ColumnThird({
  currentLocale,
  scoreData,
  isShow,
  setShow,
  taskType,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  scoreData: IAssetGroupData;
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  taskType: 'asset' | 'image' | 'host' | 'report' | 'device';
}): React.ReactElement {
  const data = scoreData.assets
    ?.map((asset) => ({
      id: asset.assetId,
      name:
        // eslint-disable-next-line no-nested-ternary
        'imageNames' in asset && asset.imageNames?.length
          ? asset.imageNames[0]
          : 'hostname' in asset
          ? asset.hostname
          : null,
      taskType: taskType as string,
      deviceType: asset.type,
    }))
    .concat(
      scoreData.assetGroups?.map((assetGroup) => ({
        id: assetGroup.assetGroupId,
        name: assetGroup.name,
        taskType: 'assetgroup',
        deviceType: undefined,
      })) || [],
    )
    .concat(
      scoreData.assetDynamicGroups?.map((assetDynamicGroup) => ({
        id: assetDynamicGroup.assetDynamicGroupId,
        name: assetDynamicGroup.name,
        taskType: 'assetDynamicgroup',
        deviceType: undefined,
      })) || [],
    )
    .concat(
      scoreData.registries?.map((asset) => ({
        id: asset.registryId,
        name: asset.name,
        taskType: 'registry',
        deviceType: undefined,
      })) || [],
    );

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>{localeTaskDescription[currentLocale].summary.scope}</td>
          <td>
            {data?.slice(0, 8).map((se) => (
              <ScopeElement element={se} key={nanoid()} />
            ))}
            {data && data.length > 8 && (
              <>
                <span
                  onMouseEnter={(): void => setShow(true)}
                  onMouseLeave={(): void => setShow(false)}
                >
                  {localeTaskDescription[currentLocale].summary.andMore} {data.length - 8}
                  <ScopeNotification
                    className={styles.asset_scope}
                    isShow={isShow}
                    data={data}
                    sliceValue={8}
                  />
                </span>
              </>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
