/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { Button } from '../../../common/baseElements/Button';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { getUrlUser } from '../../../../utils/helpers/user-list-helper';
import type { LdapGroupsResponse } from '../../../../types/__generated/on-premise-solution/api/ldapGroupsResponse.v1';
import { getLdapGr } from '../../../../services/task-service';
import { useStatesForList } from '../../../../hooks/components/useStatesForList';
import { useServerContext } from '../../../../hooks/useServerContext';
import { PAGEABLE_DATA } from '../../../../utils/helpers/constants';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { CellSelectLdapGr } from './cellHostName/CellSelectLdapGr';
import { addAllLdapGr } from '../../../../utils/helpers/add-task-helper';
import { CellNewGroup } from './cellHostName/CellNewGroup';
import { CellWithouGroup } from './cellHostName/CellWithouGroup';
import { CellSelectAssetGroup } from './cellHostName/CellSelectAssetGroup';
import styles from './NewTask.module.scss';
import { Input } from '../../../common/baseElements/Input';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function LdapGrListModal(): React.ReactElement {
  const urlParams = getUrlUser('LdapGrListModal');

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'LdapGrListModal',
  };

  const { sort, page, setPage } = useStatesForList(urlParams);

  const { serverForm, setOpenLdapGr, ldapGrForm, setLdapGrForm } = useServerContext();

  const [searchName, setSearchName] = useState<string>('');

  const [responseError, setResponseError] = useState<{ code: string; message: string } | null>(
    null,
  );

  const query = useQuery<LdapGroupsResponse, ApiError>(
    ['userList', page, searchName, serverForm],
    () => getLdapGr(page, (serverForm && serverForm[0].ldapId) || '', searchName),
    {
      keepPreviousData: true,
      enabled: !!serverForm,
      retry: 1,
      onError: (resp) => {
        setResponseError({ code: resp.code, message: resp.message });
      },
    },
  );

  const { data } = query;

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const handleExit = (): void => {
    setOpenLdapGr(null);
    setLdapGrForm(null);
  };

  return (
    <Dialog
      title={
        serverForm && serverForm[0].type === 'ldap'
          ? localeNewTask[currentLocale].form.applyForServer.titleLdapList
          : localeNewTask[currentLocale].form.applyForServer.titleAdList
      }
      onClose={(): void => setOpenLdapGr(null)}
      className={styles.ldapGrListModal}
    >
      <div className="k-form">
        <div className="form-content groups">
          <div className={styles.description}>
            {serverForm && serverForm[0] && (
              <span>
                {localeNewTask[currentLocale].form.applyForServer.description1}
                {serverForm[0].type === 'ldap'
                  ? ` LDAP ${localeNewTask[currentLocale].form.applyForServer.description2}`
                  : ` AD ${localeNewTask[currentLocale].form.applyForServer.description2}`}
              </span>
            )}
          </div>
          <div className="k-form-select-buttons">
            <Button onClick={(): void => addAllLdapGr(data?.data, ldapGrForm, setLdapGrForm)}>
              {localeNewTask[currentLocale].form.btnAddAllAssets}
            </Button>
            {ldapGrForm && ldapGrForm.length > 0 && (
              <Button onClick={(): void => setLdapGrForm(null)}>
                {localeNewTask[currentLocale].form.btnDelAllLdapGr} ({ldapGrForm?.length})
              </Button>
            )}
          </div>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <div className={styles.searchLdapGrWrap}>
                <Input
                  name="customSearch"
                  placeholder={
                    serverForm && serverForm[0].type === 'ldap'
                      ? localeNewTask[currentLocale].form.ldapTable.grid.placeHolderLdap
                      : localeNewTask[currentLocale].form.ldapTable.grid.placeHolderAd
                  }
                  value={searchName}
                  onChange={(e): void => setSearchName(e.target.value)}
                />
              </div>
            </IntlProvider>
          </LocalizationProvider>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <Grid
                pageable={query.data?.total && query.data?.total > 15 ? PAGEABLE_DATA : false}
                sortable
                sort={sort}
                data={data}
                skip={page.skip}
                take={page.take}
                total={query.data?.total || 0}
                onPageChange={pageChange}
                scrollable="none"
                className={query.data?.total && query.data?.total < 15 ? 'no-pageable-k-grid' : ''}
              >
                <Column field="addLdapGr" title=" " cell={CellSelectLdapGr} width="44px" />
                <Column
                  field="commonName"
                  title={
                    serverForm && serverForm[0].type === 'ldap'
                      ? localeNewTask[currentLocale].form.ldapTable.grid.ldapGrName
                      : localeNewTask[currentLocale].form.ldapTable.grid.adGrName
                  }
                  filter="text"
                />
                <Column
                  field="objectClass"
                  title={localeNewTask[currentLocale].form.ldapTable.grid.createGr}
                  cell={CellNewGroup}
                />
                <Column
                  title={localeNewTask[currentLocale].form.ldapTable.grid.selectGr}
                  cell={CellSelectAssetGroup}
                />
                <Column
                  title={localeNewTask[currentLocale].form.ldapTable.grid.withoutGr}
                  filter="text"
                  cell={CellWithouGroup}
                />
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
          <СommonTableLoader queryData={query.isLoading} filteringData={query.isFetching} />
        </div>
        <div className="k-form-buttons">
          <Button type="submit" onClick={(): void => setOpenLdapGr(null)}>
            {localeNewTask[currentLocale].form.btnAddAsset}
            {ldapGrForm && <span>({ldapGrForm.length})</span>}
          </Button>
          <Button type="button" onClick={handleExit}>
            {localeNewTask[currentLocale].form.btnCancel}
          </Button>
        </div>
        {responseError && (
          <div className={styles.errorResponseWrap}>
            <span>Code: {responseError?.code}</span>
            <span className={styles.message}>Message: {responseError?.message}</span>
          </div>
        )}
      </div>
    </Dialog>
  );
}
