import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';
import { Button } from '../../../../common/baseElements/Button';
import { useMaintenanceWindowsContext } from '../../../../../hooks/useMaintenanceWindowsContext';
// import localeMaintenance from '../../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import localeButtons from '../../../../../utils/i18n/commonLocales/buttons.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeButtons;

export function MaintenanceMenuCell(props: GridCellProps): ReactElement {
  const { dataItem } = props;

  const { setWindowEdit, editWindow, deleteEntitysPeriod, disableWindow } =
    useMaintenanceWindowsContext();

  const handleDisable = (): void => {
    disableWindow(dataItem);
  };

  const handleEdit = (): void => {
    setWindowEdit(true);
    editWindow({
      period: dataItem.period,
      taskActions: dataItem.taskActions,
    });
  };

  const handleDelete = (): void => {
    deleteEntitysPeriod(dataItem);
  };

  return (
    <td className="cell_menu">
      <div className="groups__menu">
        <div className="dropdown__container">
          <span className="k-icon k-i-more-vertical" />
          <div className="dropdown__content">
            <Button fill="menu" onClick={handleEdit}>
              {localeButtons[currentLocale].edit}
            </Button>
            <div className="delimiter" />
            <Button fill="menu" onClick={handleDisable}>
              {dataItem.period.isDisabled
                ? localeButtons[currentLocale].enable
                : localeButtons[currentLocale].disable}
            </Button>
            <div className="delimiter" />
            <Button fill="menu" onClick={handleDelete}>
              {localeButtons[currentLocale].delete}
            </Button>
          </div>
        </div>
      </div>
    </td>
  );
}
