/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { Fade } from '@progress/kendo-react-animation';
import '../../TaskDescription.module.scss';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { nanoid } from 'nanoid';
import localeTaskDescription from '../../../../../utils/i18n/taskLocale/taskDescription.json';
import type { IAssetGroupData } from '../../../../../utils/helpers/task-helpers';
import type { TPkg } from '../MetaBlock';
import styles from '../../TaskList.module.scss';
import { ScopeElement } from '../../cellsTaskList/ScopeElement';
import { ScopeNotification } from '../../cellsTaskList/ScopeNotification';

export function ColumnThirdPkg({
  currentLocale,
  scoreData,
  isShow,
  setShow,
  isShowPkg,
  setShowPkg,
  pkgs,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  scoreData: IAssetGroupData;
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isShowPkg: boolean;
  setShowPkg: React.Dispatch<React.SetStateAction<boolean>>;
  pkgs: TPkg;
}): React.ReactElement {
  const data = scoreData.assets
    ?.map((asset) => ({
      id: asset.assetId,
      // eslint-disable-next-line no-nested-ternary
      name:
        'imageNames' in asset && asset.imageNames?.length
          ? asset.imageNames[0]
          : 'hostname' in asset
          ? asset.hostname
          : null,
      taskType: '',
      deviceType: asset.type,
    }))
    .concat(
      scoreData.assetGroups?.map((assetGroup) => ({
        id: assetGroup.assetGroupId,
        name: assetGroup.name,
        taskType: 'assetgroup',
        deviceType: undefined,
      })) || [],
    )
    .concat(
      scoreData.registries?.map((asset) => ({
        id: asset.registryId,
        name: asset.name,
        taskType: 'registry',
        deviceType: undefined,
      })) || [],
    );

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>{localeTaskDescription[currentLocale].summary.scope}</td>
          <td>
            {data?.slice(0, 8).map((se) => (
              <ScopeElement element={se} key={nanoid()} />
            ))}
            {data && data.length > 8 && (
              <>
                <span
                  onMouseEnter={(): void => setShow(true)}
                  onMouseLeave={(): void => setShow(false)}
                >
                  {localeTaskDescription[currentLocale].summary.andMore} {data.length - 8}
                  <ScopeNotification
                    className={styles.asset_scope}
                    isShow={isShow}
                    data={data}
                    sliceValue={8}
                  />
                </span>
              </>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeTaskDescription[currentLocale].summary.pkgScope}</span>
          </td>
          <td title={(pkgs && pkgs.packages?.map((pkg) => pkg.name).join(', ')) || ''}>
            {pkgs &&
              pkgs.packages &&
              pkgs.packages.slice(0, 4).map((pkg) => (
                <div className={`${styles.scope}`} key={nanoid()}>
                  <span>{pkg.name}</span>
                </div>
              ))}
            {pkgs && pkgs.packages && pkgs.packages.length > 4 && (
              <>
                <span
                  onMouseEnter={(): void => setShowPkg(true)}
                  onMouseLeave={(): void => setShowPkg(false)}
                >
                  {localeTaskDescription[currentLocale].summary.andMore} {pkgs.packages.length - 4}
                  <NotificationGroup className={`${styles.notification}`}>
                    <Fade>
                      {isShowPkg && pkgs.packages.length > 4 && (
                        <Notification>
                          {pkgs.packages.map((pkg) => (
                            <div className={`${styles.scope}`} key={nanoid()}>
                              <span>{pkg.name}</span>
                            </div>
                          ))}
                        </Notification>
                      )}
                    </Fade>
                  </NotificationGroup>
                </span>
              </>
            )}
            {!pkgs?.packages && (
              <span>{localeTaskDescription[currentLocale].summary.allPkgScope}</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
