/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridCellProps } from '@progress/kendo-react-grid';
import styles from '../SoftwareManagement.module.scss';
import { useSoftwareManagementContext } from '../../../../hooks/useSoftwareManagementContext';
import softManagementLocale from '../../../../utils/i18n/assetLocales/softwareManagement.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof softManagementLocale;

export function ApproveStatusCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { setApproveToSoftware } = useSoftwareManagementContext();
  const approveStyles = [styles.status_image];

  if (dataItem.isApproved) approveStyles.push(styles.approved);
  else approveStyles.push(styles.not_approved);

  if (
    dataItem.softwareUpdateId &&
    (dataItem.downloadStatus === 'downloaded' ||
      (dataItem.downloadStatus === 'file_not_saved' && dataItem.isApproved))
  )
    approveStyles.push(styles.clickable);

  return (
    <td>
      <span
        onClick={
          dataItem.softwareUpdateId &&
          (dataItem.downloadStatus === 'downloaded' ||
            (dataItem.downloadStatus === 'file_not_saved' && dataItem.isApproved))
            ? (): void => {
                setApproveToSoftware(
                  dataItem.softwareUpdateId,
                  [
                    dataItem.softwareCodename,
                    dataItem.version,
                    dataItem.arch,
                    dataItem.language,
                    dataItem.installerType,
                  ]
                    .filter((softwareField) => !!softwareField)
                    .join('_'),
                  !dataItem.isApproved,
                );
              }
            : undefined
        }
        className={approveStyles.join(' ')}
        title={
          dataItem.isApproved
            ? softManagementLocale[currentLocale].statuses.approved
            : softManagementLocale[currentLocale].statuses.notApproved
        }
      />
    </td>
  );
}
