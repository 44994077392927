import type { ReactElement } from 'react';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useMaintenanceWindowsContext } from '../../../../hooks/useMaintenanceWindowsContext';
import { Button } from '../../../common/baseElements/Button';
import styles from './MaintenanceWindows.module.scss';
import { CommonMaintenanceList } from '../../../common/CommonMaintenanceList';
import localeMaintenance from '../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import type { AssetMaintenanceWindowsResponse2 } from '../../../../types/__generated/on-premise-solution/api/assetMaintenanceWindowsResponse.v1';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getMaintenanceWindowsInfo } from '../../../../services/asset-service';
import {
  convertFromActionsToPeriods,
  convertGroupsWindowsToMaintenanceList,
} from '../../../../utils/helpers/maintenance-windows-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeMaintenance;

export function MaintenanceTableListButton(): ReactElement {
  const { assetId } = useParams<{ assetId: string }>();

  const { windowsListData, setTableOpen } = useMaintenanceWindowsContext();

  const queryMaintenanceInfo = useQuery<AssetMaintenanceWindowsResponse2 | undefined, ApiError>(
    ['allMaintenanceInfo', assetId],
    () => (assetId ? getMaintenanceWindowsInfo(assetId) : undefined),
    {
      // onSuccess: (resp) => {},
      enabled: !!assetId,
    },
  );

  const openTableModal = (): void => {
    setTableOpen(true);
  };

  return windowsListData && windowsListData.length ? (
    // <div
    //   style={{
    //     display: 'flex',
    //     position: 'relative',
    //   }}
    // >
    //   <Button fill="modal" onClick={openTableModal}>
    //     {windowsListData?.length || 0}
    //   </Button>
    //   <div>
    //     <CommonTooltip
    //       hiddenStyle={{
    //         width: '400px',
    //         left: '-200px',
    //         top: '52px',
    //         padding: '8px 16px',
    //         display: 'flex',
    //         flexDirection: 'column',
    //         gap: '8px 0',
    //       }}
    //     >
    //       <CommonMaintenanceList data={windowsListData.slice(0, 5)} isShort />
    //       {/* {Object.entries(windowsListInfo).map(([action, actionCount]) => (
    //         <div>
    //           <TaskType title type={action as any} />: {actionCount}
    //         </div>
    //       ))} */}
    //     </CommonTooltip>
    //   </div>
    // </div>
    <div className="groups__menu">
      <div
        className="dropdown__container"
        style={{
          display: 'flex',
        }}
      >
        <Button fill="modal" onClick={openTableModal}>
          {windowsListData?.length || 0}
        </Button>
        <img
          src="/images/question.svg"
          alt=""
          style={{
            display: 'flex',
            width: '16px',
            height: '16px',
            margin: '0px 4px',
          }}
        />
        <div
          className="dropdown__content"
          style={{
            width: '400px',
            padding: '8px 16px',
            left: '-200px',
            top: '16px',
          }}
        >
          <div>
            <span
              style={{
                whiteSpace: 'break-spaces',
              }}
            >
              <div>
                {localeMaintenance[currentLocale].tooltip.info}
                <br />
                <br />
                {localeMaintenance[currentLocale].tooltip.assetsWindowsCount}:{' '}
                {windowsListData.length}
              </div>
            </span>
            <div
              style={{
                padding: '12px 0 20px',
              }}
            >
              <CommonMaintenanceList data={windowsListData.slice(0, 5)} isShort />
            </div>
            {assetId && (
              <div>
                <span>
                  {localeMaintenance[currentLocale].groupsHeader}:{' '}
                  {convertGroupsWindowsToMaintenanceList(
                    queryMaintenanceInfo.data?.assetGroups,
                    queryMaintenanceInfo.data?.assetDynamicGroups,
                  ).length.toString()}
                </span>
                <br />
                <span>
                  {localeMaintenance[currentLocale].globalHeader}:{' '}
                  {convertFromActionsToPeriods(
                    queryMaintenanceInfo.data?.account?.maintenanceWindows,
                  ).length.toString()}
                </span>
              </div>
            )}
            <Button
              style={{
                margin: '12px 0',
              }}
              onClick={(): void => setTableOpen(true)}
            >
              {localeButtons[currentLocale].showAll}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <KendoButton
      icon="plus"
      fillMode="flat"
      className={styles.add_windows}
      onClick={openTableModal}
      style={{
        color: '#44c74f',
      }}
    >
      {localeButtons[currentLocale].add}
    </KendoButton>
  );
}

export function MaintenanceTd(): ReactElement {
  return (
    <td className="cell_menu">
      <MaintenanceTableListButton />
    </td>
  );
}
