import { NavLink } from 'react-router-dom';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import type { TaskActionsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskActionsDashboardResponse.v1';

export function FirstColumn({
  currentLocale,
  data,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  data: TaskActionsDashboardResponse | undefined;
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeNewTask[currentLocale].taskSyncDescription.metaBlock.start}</span>
          </td>
          <td>
            {data && data.data[0].startedAt ? (
              <span>{new Date(data.data[0].startedAt).toLocaleString()}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeNewTask[currentLocale].taskSyncDescription.metaBlock.finish}</span>
          </td>
          {data && data.data[0].finishedAt ? (
            <td>{new Date(String(data.data[0].finishedAt)).toLocaleString()}</td>
          ) : (
            <td>-</td>
          )}
        </tr>
        <tr>
          <td>
            <span>{localeNewTask[currentLocale].taskSyncDescription.metaBlock.startId}</span>
          </td>
          <td>
            {data && data.data[0].taskExecutionId ? (
              <NavLink
                className="link-underline"
                to={`/lk/task/executions/${data && data.data[0].taskExecutionId}`}
              >
                {data && data.data[0].taskExecutionId}
              </NavLink>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeNewTask[currentLocale].taskSyncDescription.metaBlock.launchedBy}</span>
          </td>
          <td>
            {data && data.data[0].createdBy.source === 'user' && data.data[0].createdBy.name ? (
              <span>{data.data[0].createdBy.name}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
