import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect, type ReactElement } from 'react';
import { useQueryClient } from 'react-query';
import { useSoftwareManagementContext } from '../../../hooks/useSoftwareManagementContext';
import { Button } from '../../common/baseElements/Button';
import localeSofwareManagement from '../../../utils/i18n/assetLocales/softwareManagement.json';
import localeCommonButtons from '../../../utils/i18n/commonLocales/buttons.json';
import locale from '../../../utils/i18n/commonLocales/confirmDeleteForm.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSofwareManagement;

export function SoftwareRemoveModal(): ReactElement {
  const {
    isModalOpen,
    errorMutation,
    setErrorMutation,
    softwareApproveName,
    closeRemoveModal,
    removeSoftwareFromOnprem,
  } = useSoftwareManagementContext();

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      if (isModalOpen) queryClient.invalidateQueries('softwareInfo');
      else queryClient.invalidateQueries('savedSoftwareList');
      setErrorMutation('');
    };
  }, []);

  return (
    <Dialog
      autoFocus
      className="software-info-modal small"
      title={localeSofwareManagement[currentLocale].approveSubmitModal.title}
      onClose={closeRemoveModal}
    >
      <div className="k-form">
        <div className="form-content">
          <p>
            {localeSofwareManagement[currentLocale].removeSubmitModal.info} {softwareApproveName}
            <br />
            <br />
            {localeSofwareManagement[currentLocale].removeSubmitModal.info2} <br />
            <br />
            <b>
              {localeSofwareManagement[currentLocale].removeSubmitModal.warningInfo}{' '}
              {softwareApproveName}?
            </b>
          </p>
        </div>
        <div className="k-form-buttons">
          <Button type="submit" onClick={removeSoftwareFromOnprem} tabIndex={0}>
            {/* {locale[currentLocale].btnDeleteYes} */}
            {localeCommonButtons[currentLocale].remove}
          </Button>
          <Button type="button" onClick={closeRemoveModal}>
            {localeCommonButtons[currentLocale].close}
          </Button>
          {errorMutation && (
            <div className="info alert alert-danger">
              {locale[currentLocale].error}: {errorMutation}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
