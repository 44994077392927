import type { GridCellProps } from '@progress/kendo-react-grid';
import { useSoftwareManagementContext } from '../../../../hooks/useSoftwareManagementContext';
import { Button } from '../../../common/baseElements/Button';
import localeSoftManagement from '../../../../utils/i18n/assetLocales/softwareManagement.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSoftManagement;

export function MenuCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { openRemoveModal, downloadSoftware, setApproveToSoftware } =
    useSoftwareManagementContext();

  return (
    <td className="cell_menu">
      <div className="groups__menu">
        <div className="dropdown__container">
          <span className="k-icon k-i-more-vertical" />
          <div className="dropdown__content">
            <Button
              fill="menu"
              onClick={
                dataItem.downloadStatus === 'downloaded' &&
                dataItem.downloadUrl &&
                dataItem.softwareUpdateId
                  ? (): void => {
                      openRemoveModal(
                        dataItem.softwareUpdateId,
                        [
                          dataItem.softwareCodename,
                          dataItem.version,
                          dataItem.arch,
                          dataItem.language,
                          dataItem.installerType,
                        ]
                          .filter((softwareField) => !!softwareField)
                          .join('_'),
                      );
                    }
                  : (): void => {
                      downloadSoftware([dataItem.fullId]);
                    }
              }
            >
              {dataItem.downloadStatus === 'downloaded' &&
              dataItem.downloadUrl &&
              dataItem.softwareUpdateId
                ? localeSoftManagement[currentLocale].buttons.removeOnprem
                : localeSoftManagement[currentLocale].buttons.downloadOnprem}
            </Button>
            {dataItem.downloadStatus === 'file_not_saved' && dataItem.softwareUpdateId && (
              <Button
                onClick={(): void => {
                  openRemoveModal(
                    dataItem.softwareUpdateId,
                    [
                      dataItem.softwareCodename,
                      dataItem.version,
                      dataItem.arch,
                      dataItem.language,
                      dataItem.installerType,
                    ]
                      .filter((softwareField) => !!softwareField)
                      .join('_'),
                  );
                }}
              >
                {localeSoftManagement[currentLocale].buttons.removeOnprem}
              </Button>
            )}
            <Button
              fill="menu"
              disabled={
                !(
                  dataItem.softwareUpdateId &&
                  ((dataItem.downloadStatus === 'downloaded' && dataItem.downloadUrl) ||
                    (dataItem.downloadStatus === 'file_not_saved' && dataItem.isApproved))
                )
              }
              onClick={(): void => {
                setApproveToSoftware(
                  dataItem.softwareUpdateId,
                  [
                    dataItem.softwareCodename,
                    dataItem.version,
                    dataItem.arch,
                    dataItem.language,
                    dataItem.installerType,
                  ]
                    .filter((softwareField) => !!softwareField)
                    .join('_'),
                  !dataItem.isApproved,
                );
              }}
            >
              {dataItem.isApproved
                ? localeSoftManagement[currentLocale].buttons.disapprove
                : localeSoftManagement[currentLocale].buttons.approve}
            </Button>
          </div>
        </div>
      </div>
    </td>
  );
}
