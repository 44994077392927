/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Filter } from '@progress/kendo-react-data-tools';
import { useState } from 'react';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { INITIAL_SORT_SM, PAGEABLE_DATA } from '../../utils/helpers/constants';
import { SoftwareRow } from '../features/SoftwareManagement/SoftwareRow';
import { Button } from './baseElements/Button';
import { getUrlParamsSoftList } from '../../services/softManagement-service';
import styles from '../features/SoftwareManagement/SoftwareManagement.module.scss';
import { useSoftwareManagement } from '../../hooks/components/useSoftwareManagementList';
import softManagementLocale from '../../utils/i18n/assetLocales/softwareManagement.json';
import { FILTER_SOFT_MANAGEMENT } from '../../utils/helpers/constant-serv-filter-host';
import { Input } from './baseElements/Input';
import { SoftwareModal } from '../features/SoftwareManagement/SoftwareModal';
import { useSoftwareManagementContext } from '../../hooks/useSoftwareManagementContext';
import packageStyles from '../features/Task/TaskDescription.module.scss';
import { SoftwareApproveModal } from '../features/SoftwareManagement/SoftwareApproveModal';
import { SoftwareRemoveModal } from '../features/SoftwareManagement/SoftwareRemoveModal';
import { SoftwareAccountsDropDownLst } from '../features/SoftwareManagement/SoftwareAccountsDropDownList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof softManagementLocale;

export function CommonSoftManList(): React.ReactElement {
  const dataForHooks = {
    name: 'softwareName',
    componentName: 'SoftwareManagerList',
  };

  const urlParams = getUrlParamsSoftList(dataForHooks.componentName, INITIAL_SORT_SM);
  const {
    sort,
    filter,
    page,
    softData,
    total,
    setFilterVal,
    setSort,
    pageChange,
    onFilterChange,
    customSearch,
    handleCustomSearch,
    softwareUpdateDownloadedCount,
    softwareUpdateApproveCount,
  } = useSoftwareManagement(urlParams, dataForHooks);

  const { isModalOpen, isApproveSubmitOpen, isRemoveSubmitOpen } = useSoftwareManagementContext();

  const [shortFilter, setShortFilter] = useState<'downloaded' | 'approved' | null>(null);

  const handleShortFilter = (filterName: 'downloaded' | 'approved'): void => {
    if (filterName === shortFilter) {
      setFilterVal([]);
      setShortFilter(null);
    } else setShortFilter(filterName);
  };

  const setSortField = (): void => {
    const s = sort.length ? sort[0] : undefined;
    if (!s?.dir || s.dir === 'asc') setSort([{ dir: 'desc', field: s?.field || 'softwareName' }]);
    else if (s?.dir === 'desc') setSort([{ dir: 'asc', field: s?.field || 'softwareName' }]);
  };
  const handleSort = (event: DropDownListChangeEvent): void => {
    setSort((prev) => [
      { dir: prev?.length ? prev[0].dir : undefined, field: event.target.value.field },
    ]);
  };

  return (
    <div className="software-management-list">
      {isApproveSubmitOpen && <SoftwareApproveModal />}
      {isRemoveSubmitOpen && <SoftwareRemoveModal />}
      {isModalOpen && <SoftwareModal />}
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div
            style={{
              // margin: '24px 0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <div className="filter-line" style={{ flex: 'fit-content' }}>
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_SOFT_MANAGEMENT[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={softManagementLocale[currentLocale].customSearchSoftware}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
            <SoftwareAccountsDropDownLst />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <span>{softManagementLocale[currentLocale].sorting.sortBy}:</span>
              <DropDownList
                textField="text"
                onChange={handleSort}
                style={{
                  width: '180px',
                }}
                data={[
                  {
                    field: 'updatePublishedAt',
                    text: softManagementLocale[currentLocale].sorting.updatePublishedAt,
                  },
                  {
                    field: 'softwareName',
                    text: softManagementLocale[currentLocale].sorting.softwareName,
                  },
                  {
                    field: 'size',
                    text: softManagementLocale[currentLocale].sorting.size,
                  },
                ]}
                defaultValue={{
                  field: sort[0].field,
                  text: softManagementLocale[currentLocale].sorting[
                    sort[0].field as 'softwareName'
                  ],
                }}
              />
              <Button
                style={{ height: '34px', boxSizing: 'border-box', padding: '0 8px' }}
                icon={sort?.length && sort[0].dir ? `sort-${sort[0].dir}` : 'no-sort'}
                onClick={setSortField}
              />
            </div>
          </div>
          {filter.filters.length === 0 && (
            <div className={packageStyles.package_fast_filter} style={{ marginBottom: '24px' }}>
              <KendoButton
                className={
                  shortFilter === 'downloaded' ? 'custom-type-search-active' : 'custom-type-search'
                }
                onClick={(): void => {
                  setFilterVal([
                    {
                      downloadStatus: {
                        $eq: 'downloaded',
                      },
                    },
                  ]);
                  handleShortFilter('downloaded');
                }}
              >
                {softManagementLocale[currentLocale].shortFilters.savedToOnprem} (
                {softwareUpdateDownloadedCount || 0})
              </KendoButton>
              <KendoButton
                className={
                  shortFilter === 'approved' ? 'custom-type-search-active' : 'custom-type-search'
                }
                onClick={(): void => {
                  setFilterVal([
                    {
                      isApproved: {
                        $eq: 'true',
                      },
                    },
                  ]);
                  handleShortFilter('approved');
                }}
              >
                {softManagementLocale[currentLocale].shortFilters.approved} (
                {softwareUpdateApproveCount || 0})
              </KendoButton>
            </div>
          )}
          <Grid
            className={`${styles.repositories_table} no-margin-top`}
            pageable={PAGEABLE_DATA}
            data={softData}
            sort={sort}
            skip={page.skip}
            take={page.take}
            total={total}
            onPageChange={pageChange}
            scrollable="none"
          >
            <Column cell={SoftwareRow} className="CommonSoftManList" />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
