/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { useQuery } from 'react-query';
import { Loader } from '@progress/kendo-react-indicators';
import { ErrorBoundary } from 'react-error-boundary';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import localeVmTask from '../../../../utils/i18n/taskLocale/vmTask.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { handleError } from '../../../../utils/errors';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { useTaskContext } from '../../../../hooks/useTaskContext';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { AddTask } from '../form/AddTask';
import { useConvertToJson } from '../../../../hooks/components/useConvertToJson';
import { useRepeatTask } from '../../../../hooks/components/useRepeatTask';
import { getTaskActionById, getTaskExecutionInfo } from '../../../../services/task-service';
import { MetaBlock } from '../../../common/MetaBlock';
import { createUpdateSoftwareData } from '../../../../utils/helpers/task-helpers';
import type { EnrichedTaskActionResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedTaskActionResponse.v1';
import { TabsSmDetails } from './TabsSmDetails';
import { Button } from '../../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;

export function TaskSmDetails(): React.ReactElement {
  const { isOpenDialog } = useTaskContext();

  const { createNotification } = useNotificationContext();

  const hostName = useRef<string | false | undefined>();

  const checkConsoleData = useRef<boolean>(false);

  const { taskActionId } = useParams<{ taskActionId: string }>();

  const query = useQuery<EnrichedTaskActionResponse, ApiError>(
    ['taskActionById', taskActionId],
    () => getTaskActionById(taskActionId),
    {
      keepPreviousData: true,
      onSuccess(data) {
        hostName.current =
          (data.assets &&
            data.assets[0].type === 'host' &&
            data.assets[0].latestInventorization?.hostname) ||
          '';

        checkConsoleData.current = !(
          data.type === 'windows_software_management' &&
          data.result?.data &&
          !data.result?.data.log &&
          !data.partialResults
        );
      },
    },
  );

  const softwareUpdate = createUpdateSoftwareData(query.data);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [createNotification, query.error]);

  const { repeatTaskDescription } = useRepeatTask();

  const getTaskExecution = async function getTaskExecution(): Promise<void> {
    const result = await getTaskExecutionInfo(query.data?.taskExecutionId);
    repeatTaskDescription(result, undefined);
  };

  const handle2Json = useConvertToJson(query.data, hostName.current, 'pkgUpdate');

  return (
    <div>
      {isOpenDialog && <AddTask />}
      {query.status === 'success' ? (
        <div className="taskdescription">
          <div className="common-header-page">
            <Typography.h3 className="title">
              <span className="audit-taskname">
                {' '}
                {localeVmTask[currentLocale].softwareUpdate} {hostName.current}
              </span>
            </Typography.h3>
            <div className="topactions">
              <Button onClick={(): Promise<void> => getTaskExecution()} disabled={query.isFetching}>
                {localeVmTask[currentLocale].btnRepeat}
              </Button>
              <div className="json-actions">
                <Menu hoverOpenDelay={0} onSelect={handle2Json}>
                  <MenuItem text={localeVmTask[currentLocale].techDetails}>
                    <MenuItem text={localeVmTask[currentLocale].assetData} />
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
            <MetaBlock taskAction={query.data} />
            <TabsSmDetails
              taskActionId={taskActionId}
              softwareUpdate={softwareUpdate}
              hostName={hostName.current}
              checkConsoleData={checkConsoleData.current}
              status={query.data.status}
            />
          </ErrorBoundary>
        </div>
      ) : (
        <Loader
          style={{ color: 'black', marginLeft: '50%' }}
          size="large"
          type="converging-spinner"
        />
      )}
    </div>
  );
}
