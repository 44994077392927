import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { getAccountId } from './local-storage-service';
// import { httpInstance } from '../lib/axios/vm-axios';
// import type { AccountsResponse } from '../types/__generated/on-premise-solution/api/accountsResponse.v1';
import type { AccountEdit } from '../hooks/useAccountModalContext';
import type { AccountCreationRequest } from '../types/__generated/on-premise-solution/api/accountCreationRequest.v1';
import type { AccountCreationResponse } from '../types/__generated/on-premise-solution/api/accountCreationResponse.v1';
import type { AccountDeletionResponse } from '../types/__generated/on-premise-solution/api/accountDeletionResponse.v1';
import type { AccountUpdateResponse } from '../types/__generated/on-premise-solution/api/accountUpdateResponse.v1';
import type { AccountAgentCommandResponse } from '../types/__generated/on-premise-solution/api/accountAgentCommandResponse.v1';
import type { AccountsDashboardResponse } from '../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
// import type { AccountAgentCommandRequestAuditLinux } from '../types/__generated/on-premise-solution/api/accountAgentCommandRequestAuditLinux.v1';
import type { IPageState } from '../utils/helpers/types';
import { history } from '../utils/history';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import { prepareFilterVal } from './filterVal-for-service';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import type { AccountAgentCommandRequestAudit } from '../types/__generated/on-premise-solution/api/accountAgentCommandRequest.v1';
import { urlFilterString } from '../utils/helpers/url-filter-string-helper';

// export async function getSystemAccounts(
//   page: IPageState,
//   filterVal: object,
//   sort: Array<SortDescriptor>,
// ): Promise<AccountsResponse> {
//   const result = await vmInstance.get<AccountsResponse>('/accounts', {
//     params: {
//       skip: page.skip,
//       limit: page.take,
//       sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
//       sortOrder: sort[0].dir,
//       ...filterVal,
//     },
//     paramsSerializer: (params) => {
//       history.replace({
//         pathname: `/lk/accounts?${qs.stringify({
//           skip: page.skip,
//           limit: page.take,
//           sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
//           sortOrder: sort[0].dir,
//           ...filterVal,
//         })}`,
//       });

//       return qs.stringify(params, { encode: false });
//     },
//   });

//   return result.data;
// }

export async function getAccountsForList(
  page?: IPageState,
  filterVal?: IFilterVal[],
  sort?: Array<SortDescriptor>,
): Promise<AccountsDashboardResponse> {
  // const queryString = urlFilterString(filterVal);

  const paramsData: any = {};

  if (page) {
    paramsData.skip = page.skip;
    paramsData.limit = page.take;
  }
  if (sort) {
    paramsData.sortField = sort[0].field.length < 1 ? undefined : sort[0].field;
    paramsData.sortOrder = sort[0].dir;
  }

  const result = await dashboardInstance.get<AccountsDashboardResponse>('/accounts', {
    params: filterVal
      ? {
          ...paramsData,
          ...prepareFilterVal(filterVal),
        }
      : paramsData,

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });
      if (page || filterVal || sort)
        history.replace({
          pathname: `/lk/users/organization?${stringifiedParams}`,
        });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getAllAccounts(): Promise<AccountsDashboardResponse> {
  const result = await dashboardInstance.get<AccountsDashboardResponse>('/accounts');

  return result.data;
}

export async function createAccount({
  accountId,
  name,
}: AccountCreationRequest): Promise<AccountCreationResponse> {
  const result = await vmInstance.post<AccountCreationResponse>('/accounts', {
    accountId,
    name,
  });

  return result.data;
}
export async function deleteAccount(accountId: string): Promise<AccountDeletionResponse | null> {
  const id: string | null = getAccountId();
  if (id === accountId) return null;
  const result = await vmInstance.delete<AccountDeletionResponse>(`/accounts/${accountId}`);

  return result.data;
}
export async function editAccount({
  name,
  accountId,
  maintenanceWindows,
}: AccountEdit): Promise<AccountUpdateResponse> {
  const result = await vmInstance.patch<AccountUpdateResponse>(`/accounts/${accountId}`, {
    name,
    maintenanceWindows,
  });

  return result.data;
}
export async function sendCommand({
  assetId,
  command,
  payload,
}: AccountAgentCommandRequestAudit): Promise<AccountAgentCommandResponse> {
  const result = await vmInstance.post<AccountAgentCommandResponse>('/accounts/agent/command', {
    assetId,
    command,
    payload,
  });

  return result.data;
}
