/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridCellProps } from '@progress/kendo-react-grid';
import styles from '../SoftwareManagement.module.scss';
import { useSoftwareManagementContext } from '../../../../hooks/useSoftwareManagementContext';
import softManagementLocale from '../../../../utils/i18n/assetLocales/softwareManagement.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof softManagementLocale;

export function DownloadStatusCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { downloadSoftware } = useSoftwareManagementContext();

  let dStatusTitle = '';

  const dStyles = [];
  // [styles.status_image, styles.download_onprem];

  switch (dataItem.downloadStatus) {
    case 'error':
      dStyles.push(styles.status_image, styles.error);
      if (
        dataItem.errorType &&
        dataItem.errorType in softManagementLocale[currentLocale].errorTypes
      )
        dStatusTitle =
          softManagementLocale[currentLocale].errorTypes[dataItem.errorType as 'unknown_error'];
      else dStatusTitle = softManagementLocale[currentLocale].statuses.error;
      break;

    case 'file_not_saved':
      dStyles.push(styles.status_image, styles.error);
      dStatusTitle =
        dataItem.errorReason || softManagementLocale[currentLocale].statuses.file_not_saved;
      break;

    case 'not_loaded':
      dStyles.push(styles.status_image, styles.download_onprem, styles.not_loaded);
      dStatusTitle = softManagementLocale[currentLocale].statuses.not_loaded;
      if (dataItem.fullId) dStyles.push(styles.clickable);
      break;

    case 'pending':
      dStyles.push('k-icon', 'k-i-clock');
      dStatusTitle = softManagementLocale[currentLocale].statuses.pending;
      break;

    case 'downloading':
      dStyles.push('k-i-loader');
      dStatusTitle = softManagementLocale[currentLocale].statuses.downloading;
      break;

    case 'downloaded':
      dStyles.push(styles.status_image, styles.download_onprem, styles.downloaded);
      dStatusTitle = softManagementLocale[currentLocale].statuses.downloaded;
      break;

    default:
      break;
  }

  return (
    <td>
      <span
        onClick={
          dataItem.downloadStatus === 'not_loaded' && dataItem.fullId
            ? (): void => {
                downloadSoftware([dataItem.fullId]);
              }
            : undefined
        }
        className={dStyles.join(' ')}
        title={dStatusTitle}
      />
    </td>
  );
}
