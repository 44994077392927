import { NavLink } from 'react-router-dom';
import { nanoid } from 'nanoid';
import type {
  IActionsValues,
  IBulletinSpecific,
  ICellActions,
} from '../../../../../utils/helpers/types';
import localeTask from '../../../../../utils/i18n/taskLocale/task.json';
import { TaskType } from '../../common/TaskType';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function CellActionTimeout({
  assets,
  assetId,
  type,
  status,
  dataItem,
}: ICellActions): React.ReactElement {
  return (
    <>
      {status === 'timeout' &&
        type === 'audit' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="audit" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            {assets && assets[0].type === 'host' && assets[0].latestInventorization?.hostname && (
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            )}
          </>
        )}
      {status === 'timeout' &&
        type === 'audit_image' &&
        assets &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="audit_image" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              {assets[0].type === 'image' &&
                assets[0].imageNames &&
                assets[0].imageNames.length > 0 &&
                assets[0].imageNames[0]}
            </span>
          </>
        )}
      {status === 'timeout' &&
        assets &&
        assets[0].type === 'host' &&
        type === 'package_update' &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="package_update" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            </span>
          </>
        )}
      {status === 'timeout' &&
        assets &&
        assets[0].type === 'host' &&
        type === 'windows_software_management' &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="windows_software_management" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            </span>
          </>
        )}
      {status === 'timeout' &&
        assets &&
        assets[0].type === 'host' &&
        type === 'kb_install' &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="kb_install" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            </span>
          </>
        )}
      {status === 'timeout' &&
        type === 'inventorization' &&
        assets &&
        assets[0].type === 'host' &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            {assets[0].latestInventorization?.hostname ? (
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            ) : (
              <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                {assetId}
              </NavLink>
            )}
          </>
        )}
      {status === 'timeout' &&
        type === 'inventorization' &&
        assets &&
        (assets[0].type === 'router' ||
          assets[0].type === 'firewall' ||
          assets[0].type === 'switch' ||
          assets[0].type === 'hypervisor') &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="inventorization" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            {assets[0].latestInventorization?.hostname ? (
              <NavLink
                className="link-underline"
                to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
              >
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            ) : (
              <NavLink
                className="link-underline"
                to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
              >
                {assetId}
              </NavLink>
            )}
          </>
        )}
      {status === 'timeout' &&
        type === 'audit' &&
        assets &&
        (assets[0].type === 'router' ||
          assets[0].type === 'firewall' ||
          assets[0].type === 'switch' ||
          assets[0].type === 'hypervisor') &&
        assets[0].latestInventorization !== null && (
          <>
            <TaskType title type="audit" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            </span>
            {assets[0].latestInventorization?.hostname ? (
              <NavLink
                className="link-underline"
                to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
              >
                {assets[0].latestInventorization?.hostname}
              </NavLink>
            ) : (
              <NavLink
                className="link-underline"
                to={`/lk/assets/devices/${assets[0].type}/${assetId}`}
              >
                {assetId}
              </NavLink>
            )}
          </>
        )}
      {dataItem.status === 'timeout' && dataItem.type === 'inventorization_container_registry' && (
        <>
          <TaskType title type="inventorization" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {dataItem.registry.name}
          </span>
        </>
      )}
      {status === 'timeout' && type === 'notification' && (
        <>
          <TaskType title type="notification" />{' '}
          <span>{localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]} </span>
          {dataItem.recipientEmails &&
            dataItem.recipientEmails.length > 0 &&
            dataItem.recipientEmails.map((e: string, i: number) => {
              return (
                <span key={nanoid(8)}>
                  {i >= 1 && ', '}
                  {e}
                </span>
              );
            })}
        </>
      )}
      {status === 'timeout' && type === 'bulletin_specific_analysis' && (
        <>
          <TaskType title type="bulletin_specific_analysis" />{' '}
          <span>
            {localeTask[currentLocale].form.bulletinSpecific[type as keyof IBulletinSpecific]}
            {dataItem.bulletinId}
          </span>
        </>
      )}
      {dataItem.status === 'timeout' && dataItem.type === 'asset_ldap_synchronize' && (
        <>
          <TaskType title type="asset_ldap_synchronize" />{' '}
          <span>
            {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
            {dataItem.ldap.name}
          </span>
        </>
      )}
    </>
  );
}
