import type { UseQueryResult } from 'react-query';
import type localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import type { TaskActionsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskActionsDashboardResponse.v1';
import { FirstColumn } from './FirstColumn';
import { SecondColumn } from './SecondColumn';
import { ThirdColumn } from './ThirdColumn';
import type { TaskExecutionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { TCredentialIdTaskSync } from '../../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;
export function MetaBlock({
  data,
  queryExecutionInfo,
  assetDuplicationsSearchPolicy,
  bindCredentials,
}: {
  data: TaskActionsDashboardResponse | undefined;
  queryExecutionInfo: UseQueryResult<TaskExecutionDashboardResponse, ApiError>;
  assetDuplicationsSearchPolicy: React.MutableRefObject<
    'globally' | 'inside_asset_group' | 'ignore' | undefined
  >;
  bindCredentials: React.MutableRefObject<TCredentialIdTaskSync[] | undefined>;
}): React.ReactElement {
  return (
    <div className="common-summary-page">
      <FirstColumn currentLocale={currentLocale} data={data} />
      {queryExecutionInfo.data?.taskType === 'asset_synchronize' && (
        <SecondColumn
          currentLocale={currentLocale}
          data={data}
          assetDuplicationsSearchPolicy={assetDuplicationsSearchPolicy.current}
        />
      )}
      <ThirdColumn
        currentLocale={currentLocale}
        data={data}
        bindCredentials={bindCredentials.current}
      />
    </div>
  );
}
