/* eslint-disable no-nested-ternary */
import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useHistory } from 'react-router';
import type {
  IChildrenNodes,
  IInitialStateSrv,
  IServerContext,
  IServerForm,
  TDataEditSrc,
  TEditServer,
  TLdapGrFormValues,
  TypeLdapEqualAssetGroup,
} from '../utils/helpers/types';
import localeUserList from '../utils/i18n/userLocales/userList.json';
import type { LdapCreationResponse } from '../types/__generated/on-premise-solution/api/ldapCreationResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import type { LdapCreationRequest } from '../types/__generated/on-premise-solution/api/ldapCreationRequest.v1';
import { createServer, deleteServer, editSrv } from '../services/user-service';
import type { Ldap } from '../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import type { LdapUpdateRequest } from '../types/__generated/on-premise-solution/api/ldapUpdateRequest.v1';
import type { LdapUpdateResponse } from '../types/__generated/on-premise-solution/api/ldapUpdateResponse.v1';
import { useAccountModalContext } from './useAccountModalContext';
import { getAccountId, getUserData } from '../services/local-storage-service';
import type { LdapDeletionResponse } from '../types/__generated/on-premise-solution/api/ldapDeletionResponse.v1';
import { useCredentialContext } from './useCredentialContext';
import type { TaskExecutionDashboardResponse } from '../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUserList;

const ServerContext = createContext<IServerContext | null>(null);

export function ServerProvider({ children }: IChildrenNodes): React.ReactElement {
  const currentUser = getUserData();

  const currentAccountId = getAccountId();

  const queryClient = useQueryClient();

  const history = useHistory();

  const [url, setUrl] = useState<string>('');

  const [baseDN, setBaseDN] = useState<string>('');

  const [name, setName] = useState<string>('');

  const [type, setType] = useState<'ldap' | 'activedirectory' | undefined>(undefined);

  const [description, setDescription] = useState<string | null>('');

  const [isServer, setServer] = useState(false);

  const [editServer, setEditServer] = useState<TEditServer | null>(null);

  const [initialState, setInitialState] = useState<IInitialStateSrv | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  const [serverForm, setServerForm] = useState<IServerForm[] | null>(null);

  const [isOpenSrvMutableBlock, setOpenSrvMutableBlock] = useState(false);

  const [isOpenLdapGr, setOpenLdapGr] = useState<string | null>(null);

  const [currentCommonName, setCurrentCommonName] = useState<string | null>(null);

  const [credentialSrv, setCredentialSrv] = useState<
    | {
        credentialId: string | undefined | null;
        credentialName: string | undefined;
        credentialType: string | undefined;
      }
    | undefined
  >(undefined);

  const [ldapGrForm, setLdapGrForm] = useState<TLdapGrFormValues[] | null>(null);

  const [ldapGrPolicy, setLdapGrPolicy] = useState<TLdapGrFormValues[] | null>(null);

  const { accountId, setAccountId } = useAccountModalContext();

  const { setCheckCredentilsResp } = useCredentialContext();

  useEffect(() => {
    setName(editServer ? editServer.name : '');
    setUrl(editServer ? editServer.url : '');
    setBaseDN(editServer ? editServer.baseDN : '');
    setAccountId(
      editServer
        ? { id: editServer.account.accountId, name: editServer.account.name }
        : {
            id: '000',
            name: localeUserList[currentLocale].modal.placeholderAccountName,
          },
    );
    setDescription(editServer ? editServer.description : null);
    setType(editServer ? editServer.type : undefined);
    if (editServer) {
      setInitialState({
        accountId: editServer.accountId,
        type: editServer.type,
        name: editServer.name,
        url: editServer.url,
        baseDN: editServer.baseDN,
        description: editServer.description,
        credentialId: editServer.credentialId,
      });
    }
  }, [editServer, setAccountId]);

  const handleClose = useCallback((): void => {
    setServer(false);
    setEditServer(null);
    setName('');
    setDescription('');
    setCredentialSrv(undefined);
    setCheckCredentilsResp(undefined);
    setBaseDN('');
    setUrl('');
    setType(undefined);
  }, [setCheckCredentilsResp]);

  const mutationNewServer = useMutation<LdapCreationResponse, ApiError, LdapCreationRequest>(
    (payload) => createServer(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ServerList');
        setUrl('');
        setBaseDN('');
        setAccountId({
          id: '000',
          name: localeUserList[currentLocale].modal.placeholderAccountId,
        });
        handleClose();
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  const mutationEditServer = useMutation<
    LdapUpdateResponse,
    ApiError,
    { ldapId: string; payload: LdapUpdateRequest }
  >(({ ldapId, payload }) => editSrv(ldapId, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('ServerList');
      queryClient.invalidateQueries('ldapId');
      setUrl('');
      setBaseDN('');
      setAccountId({
        id: '000',
        name: localeUserList[currentLocale].modal.placeholderAccountId,
      });
      handleClose();
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationLdapDel = useMutation<LdapDeletionResponse | null, ApiError, Ldap['ldapId']>(
    (payload) => deleteServer(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ServerList');
      },
    },
  );

  const changeDropDownType = useCallback(
    (event: DropDownListChangeEvent): void => {
      setCredentialSrv(undefined);
      setType(event.target.value === 'AD' ? 'activedirectory' : event.target.value);
    },
    [setType],
  );

  const addSelectedServers = useCallback((): void => {
    setServerForm(serverForm);
    setOpenSrvMutableBlock(false);
  }, [serverForm]);

  const exitServerFromTask = useCallback(() => {
    setServerForm(null);
    setOpenSrvMutableBlock(false);
  }, []);

  const handleDelete = useCallback(
    (ldapId: string, setConfirm: (value: React.SetStateAction<boolean>) => void): void => {
      mutationLdapDel.mutate(ldapId);
      setConfirm(false);
      history.goBack();
    },
    [history, mutationLdapDel],
  );

  const handleSubmit = useCallback((): void => {
    const dataForm = {
      accountId: currentUser?.role === 'super_admin' ? accountId.id : currentAccountId,
      url,
      baseDN,
      name,
      type,
      description: description || null,
      tls: null,
      credentialId: credentialSrv?.credentialId,
    } as LdapCreationRequest;
    if (editServer) {
      mutationEditServer.mutate({
        ldapId: editServer.ldapId,
        payload: {
          url: initialState?.url !== url ? url : undefined,
          baseDN: editServer?.baseDN !== baseDN ? baseDN : undefined,
          name: initialState?.name !== name ? name : undefined,
          type: initialState?.type !== type ? type : undefined,
          description:
            description && description.length > 0 && initialState?.description !== description
              ? description
              : description?.length === 0
              ? null
              : undefined,
          credentialId:
            initialState?.credentialId &&
            credentialSrv?.credentialId &&
            initialState?.credentialId !== credentialSrv?.credentialId
              ? credentialSrv?.credentialId
              : initialState?.credentialId && !credentialSrv?.credentialId
              ? null
              : !initialState?.credentialId && credentialSrv?.credentialId
              ? credentialSrv?.credentialId
              : undefined,
        },
      });
    } else {
      mutationNewServer.mutate(dataForm);
    }
  }, [
    accountId.id,
    baseDN,
    credentialSrv,
    currentAccountId,
    currentUser?.role,
    description,
    editServer,
    initialState,
    mutationEditServer,
    mutationNewServer,
    name,
    type,
    url,
  ]);

  const deleteServerFromTask = useCallback(
    (ldapId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (serverForm && serverForm.length > 0) {
        setServerForm(serverForm?.filter((server) => server.ldapId !== ldapId));
      }
    },
    [serverForm],
  );

  const delAllServers = useCallback((): void => {
    setServerForm(null);
  }, []);

  const deleteCredentialFromSrv = useCallback(
    (ldapId: string): void => {
      mutationEditServer.mutate({
        ldapId,
        payload: { credentialId: null },
      });
    },
    [mutationEditServer],
  );

  const handleEditServer = useCallback((data: TDataEditSrc): void => {
    setEditServer({
      accountId: data.accountId,
      baseDN: data.baseDN,
      url: data.url,
      ldapId: data.ldapId,
      name: data.name,
      description: data.description,
      type: data.type,
      account: { name: data.name, accountId: data.account.accountId },
      credentialId: (data.credential && data.credential.credentialId) || null,
    });
    setCredentialSrv({
      credentialId: data.credential.credentialId,
      credentialName: data.credential.name,
      credentialType: data.type,
    });
  }, []);

  const deleteLdapGrFromTask = useCallback(
    (commonName: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (ldapGrForm && ldapGrForm.length > 0) {
        setLdapGrForm(ldapGrForm?.filter((l) => l.ldapGroupName !== commonName));
      }
    },
    [ldapGrForm],
  );

  const quantifyImportOptionsSync = useCallback(
    (
      dataExecutionInfo: TaskExecutionDashboardResponse | undefined,
      resultLdapEqualAssetGroup: TypeLdapEqualAssetGroup[] | null,
    ) => {
      const ldapData: TLdapGrFormValues[] | null = [];

      if (dataExecutionInfo?.taskType === 'asset_synchronize') {
        if (dataExecutionInfo.actions.asset_ldap_synchronize?.ldapGroups) {
          dataExecutionInfo.actions.asset_ldap_synchronize?.ldapGroups.forEach((g) => {
            if (g.assetGroupPolicy === 'create') {
              const ldapGrData = {
                ldapGroupName: g.ldapGroupName,
                newGr:
                  resultLdapEqualAssetGroup &&
                  resultLdapEqualAssetGroup.filter((gr) => gr.sourceLdap === g.ldapGroupName) &&
                  resultLdapEqualAssetGroup.filter((gr) => gr.sourceLdap === g.ldapGroupName)[0] &&
                  resultLdapEqualAssetGroup.filter((gr) => gr.sourceLdap === g.ldapGroupName)[0]
                    .assetLdapName
                    ? resultLdapEqualAssetGroup.filter((gr) => gr.sourceLdap === g.ldapGroupName)[0]
                        .assetLdapName
                    : g.ldapGroupName,
                newGrId:
                  resultLdapEqualAssetGroup &&
                  resultLdapEqualAssetGroup.filter((gr) => gr.sourceLdap === g.ldapGroupName) &&
                  resultLdapEqualAssetGroup.filter((gr) => gr.sourceLdap === g.ldapGroupName)[0] &&
                  resultLdapEqualAssetGroup.filter((gr) => gr.sourceLdap === g.ldapGroupName)[0]
                    .assetLdapId
                    ? resultLdapEqualAssetGroup.filter((gr) => gr.sourceLdap === g.ldapGroupName)[0]
                        .assetLdapId
                    : undefined,
                assetGr: null,
                assetGroupPolicy: g.assetGroupPolicy,
              } as TLdapGrFormValues;
              ldapData.push(ldapGrData);
            }
            if (g.assetGroupPolicy === 'add_to_existing') {
              const ldapGrData = {
                ldapGroupName: g.ldapGroupName,
                newGr: null,
                assetGr: {
                  name: g.existingAssetGroup?.name,
                  id: g.existingAssetGroup?.assetGroupId,
                },
                assetGroupPolicy: g.assetGroupPolicy,
              } as TLdapGrFormValues;
              ldapData.push(ldapGrData);
            }
            if (g.assetGroupPolicy === 'do_not_create') {
              const ldapGrData = {
                ldapGroupName: g.ldapGroupName,
                newGr: null,
                assetGr: null,
                assetGroupPolicy: g.assetGroupPolicy,
              } as TLdapGrFormValues;
              ldapData.push(ldapGrData);
            }
          });
        }

        if (ldapData) setLdapGrPolicy(ldapData);
      }
    },
    [],
  );

  const value = useMemo(
    () => ({
      url,
      setUrl,
      baseDN,
      setBaseDN,
      isServer,
      setServer,
      editServer,
      setEditServer,
      description,
      setDescription,
      name,
      setName,
      handleClose,
      handleSubmit,
      type,
      setType,
      changeDropDownType,
      initialState,
      errorMutation,
      setErrorMutation,
      serverForm,
      setServerForm,
      isOpenSrvMutableBlock,
      setOpenSrvMutableBlock,
      exitServerFromTask,
      addSelectedServers,
      deleteServerFromTask,
      delAllServers,
      mutationEditServer,
      deleteCredentialFromSrv,
      credentialSrv,
      setCredentialSrv,
      handleEditServer,
      handleDelete,
      mutationLdapDel,
      isOpenLdapGr,
      setOpenLdapGr,
      ldapGrForm,
      setLdapGrForm,
      deleteLdapGrFromTask,
      currentCommonName,
      setCurrentCommonName,
      quantifyImportOptionsSync,
      ldapGrPolicy,
      setLdapGrPolicy,
    }),
    [
      url,
      baseDN,
      isServer,
      editServer,
      description,
      name,
      handleClose,
      handleSubmit,
      type,
      changeDropDownType,
      initialState,
      errorMutation,
      serverForm,
      isOpenSrvMutableBlock,
      exitServerFromTask,
      addSelectedServers,
      deleteServerFromTask,
      delAllServers,
      mutationEditServer,
      deleteCredentialFromSrv,
      credentialSrv,
      handleEditServer,
      handleDelete,
      mutationLdapDel,
      isOpenLdapGr,
      ldapGrForm,
      deleteLdapGrFromTask,
      currentCommonName,
      quantifyImportOptionsSync,
      ldapGrPolicy,
      setLdapGrPolicy,
    ],
  );

  return <ServerContext.Provider value={value}>{children}</ServerContext.Provider>;
}
export const useServerContext = (): IServerContext => {
  const context = useContext(ServerContext);
  if (context === null) {
    throw new Error('ServerContext must be used inside the ServerContext.Provider.');
  }

  return context;
};
