/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { ReactElement } from 'react';
import localeSofwareManagement from '../../../utils/i18n/assetLocales/softwareManagement.json';
import type { SavedWindowsSoftwareUpdateWithAvailableDashboardResponse } from '../../../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateWithAvailableDashboardResponse.v1';
import { SoftSizeCell } from './cells/SoftSizeCell';
import { useSoftwareManagementContext } from '../../../hooks/useSoftwareManagementContext';
import styles from './SoftwareManagement.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSofwareManagement;

function Status({
  status,
  errorMessage,
  errorType,
  fullId,
}: {
  status: SavedWindowsSoftwareUpdateWithAvailableDashboardResponse['downloadStatus'];
  errorMessage?: SavedWindowsSoftwareUpdateWithAvailableDashboardResponse['errorReason'];
  errorType?: SavedWindowsSoftwareUpdateWithAvailableDashboardResponse['errorType'];
  fullId?: SavedWindowsSoftwareUpdateWithAvailableDashboardResponse['fullId'];
}): ReactElement {
  const { downloadSoftware } = useSoftwareManagementContext();
  if (!status) return <span>-</span>;

  const dStyles = [];

  switch (status) {
    case 'error':
      dStyles.push(styles.status_image, styles.error);
      break;

    case 'file_not_saved':
      dStyles.push(styles.status_image, styles.error);
      break;

    case 'not_loaded':
      dStyles.push(styles.status_image, styles.download_onprem, styles.not_loaded);
      if (fullId) dStyles.push(styles.clickable);
      break;

    case 'pending':
      dStyles.push('k-icon', 'k-i-clock');
      break;

    case 'downloading':
      dStyles.push('k-i-loader');
      break;

    case 'downloaded':
      dStyles.push(styles.status_image, styles.download_onprem, styles.downloaded);
      break;

    default:
      break;
  }

  const statusSpan = (
    <span
      onClick={
        status === 'not_loaded' && fullId
          ? (): void => {
              downloadSoftware([fullId]);
            }
          : undefined
      }
      className={dStyles.join(' ')}
      // title={dStatusTitle}
    />
  );

  if (status === 'error' || status === 'file_not_saved')
    return (
      <span
        style={{
          textDecoration: 'underline',
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
        }}
        title={
          status === 'error' &&
          errorType &&
          errorType in localeSofwareManagement[currentLocale].errorTypes
            ? localeSofwareManagement[currentLocale].errorTypes[errorType]
            : errorMessage
        }
      >
        {statusSpan}
        {localeSofwareManagement[currentLocale].statuses[status]}
      </span>
    );

  return (
    <span
      style={{
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
      }}
    >
      {statusSpan}
      {localeSofwareManagement[currentLocale].statuses[status]}
    </span>
  );
}

export function SoftwareModalMetablock({
  data,
}: {
  data: SavedWindowsSoftwareUpdateWithAvailableDashboardResponse | undefined;
}): ReactElement {
  const { setApproveToSoftware } = useSoftwareManagementContext();
  const approveStyles = [styles.status_image];

  if (data?.isApproved) approveStyles.push(styles.approved);
  else approveStyles.push(styles.not_approved);

  if (
    data?.softwareUpdateId &&
    (data?.downloadStatus === 'downloaded' ||
      (data?.downloadStatus === 'file_not_saved' && data?.isApproved))
  )
    approveStyles.push(styles.clickable);

  return (
    <div className="common-summary-page">
      <table className="column column-first">
        <tbody>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.version}</td>
            <td>{data?.version || '-'}</td>
          </tr>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.arch}</td>
            <td>{data?.arch || '-'}</td>
          </tr>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.lang}</td>
            <td>{data?.language || '-'}</td>
          </tr>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.installerType}</td>
            <td>{data?.installerType || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className="column column-second">
        <tbody>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.size}</td>

            {typeof data?.installer.size === 'number' ? (
              <SoftSizeCell
                size={data.installer.size}
                radix={2}
                downloadUrl={data.downloadUrl || undefined}
                status={data.downloadStatus}
              />
            ) : (
              <td>-</td>
            )}
          </tr>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.downloadStatus}</td>
            <td>
              <Status
                status={data?.downloadStatus}
                errorMessage={data?.errorReason}
                errorType={data?.errorType}
                fullId={data?.fullId}
              />
            </td>
          </tr>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.approved}</td>
            <td>
              <span
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                }}
              >
                <span
                  onClick={
                    data?.softwareUpdateId &&
                    (data?.downloadStatus === 'downloaded' ||
                      (data?.downloadStatus === 'file_not_saved' && data?.isApproved))
                      ? (): void => {
                          setApproveToSoftware(
                            data?.softwareUpdateId,
                            [
                              data?.softwareCodename,
                              data?.version,
                              data?.arch,
                              data?.language,
                              data?.installerType,
                            ]
                              .filter((softwareField) => !!softwareField)
                              .join('_'),
                            !data?.isApproved,
                            true,
                          );
                        }
                      : undefined
                  }
                  className={approveStyles.join(' ')}
                  title={
                    data?.isApproved
                      ? localeSofwareManagement[currentLocale].statuses.approved
                      : localeSofwareManagement[currentLocale].statuses.notApproved
                  }
                />
                {data?.isApproved
                  ? localeSofwareManagement[currentLocale].booleans.true
                  : localeSofwareManagement[currentLocale].booleans.false}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="column column-third">
        <tbody>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.published}</td>
            <td>
              {data?.updatePublishedAt ? new Date(data?.updatePublishedAt).toLocaleString() : '-'}
            </td>
          </tr>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.modified}</td>
            <td>
              {data?.updateModifiedAt ? new Date(data?.updateModifiedAt).toLocaleString() : '-'}
            </td>
          </tr>
          <tr>
            <td>{localeSofwareManagement[currentLocale].modal.updateId}</td>
            <td>{data?.fullId || '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
