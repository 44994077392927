import type { GridCellProps } from '@progress/kendo-react-grid';
import { useCallback, useState } from 'react';
import { Button } from '../../common/baseElements/Button';
import { useAccountModalContext } from '../../../hooks/useAccountModalContext';
import type { Account } from '../../../types/__generated/on-premise-solution/api/account.v1';
import localeAccount from '../../../utils/i18n/accountLocales/accountList.json';
import localeMaintenance from '../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import { getUserData } from '../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../common/modal/DeleteConfirmModal';
import { useMaintenanceWindowsContext } from '../../../hooks/useMaintenanceWindowsContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;
export function AccountMenu({ dataItem }: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const [isConfirm, setConfirm] = useState(false);

  const { entityId, entityName } = useMaintenanceWindowsContext();
  const { mutationDeleteAccount, editAccount, editMaintenanceWindows } = useAccountModalContext();

  const handleEdit = useCallback(() => {
    entityId.current = dataItem.accountId;
    entityName.current = dataItem.name;
    editAccount({
      accountId: dataItem.accountId || '',
      name: dataItem.name,
      maintenanceWindows: dataItem.maintenanceWindows,
    });
  }, [
    entityId,
    dataItem.accountId,
    dataItem.name,
    dataItem.maintenanceWindows,
    entityName,
    editAccount,
  ]);

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (): void => {
    const { accountId } = dataItem as Account;
    mutationDeleteAccount.mutate(accountId);
    setConfirm(false);
  };

  const handleMaintenance = (): void => {
    entityId.current = dataItem.accountId;
    entityName.current = dataItem.name;
    editMaintenanceWindows({
      accountId: dataItem.accountId || '',
      name: dataItem.name,
      maintenanceWindows: dataItem.maintenanceWindows,
    });
  };

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          type="account"
          name={dataItem?.name}
          onClose={(): void => setConfirm(false)}
          handleDelete={handleDelete}
          errorMessage={mutationDeleteAccount.error?.message}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
                <>
                  <Button fill="menu" onClick={handleEdit}>
                    {localeAccount[currentLocale].btnEdit}
                  </Button>
                  <Button fill="menu" onClick={handleMaintenance}>
                    {localeMaintenance[currentLocale].maintenanceWindows}
                  </Button>
                  <Button fill="menu" onClick={handleConfirm}>
                    {localeAccount[currentLocale].btnDelete}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
