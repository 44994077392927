/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useCallback, useEffect } from 'react';
// import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useGroupContext } from '../../../hooks/useGroupContext';

export function CellSelectDynamicGroups(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  // const { hostForm, hostFormTemp, setHostFormTemp } = useHostModalContext();

  const { dynamicGroupForm, setDynamicGroupForm } = useGroupContext();

  // useEffect(() => {
  //   if (!hostFormTemp) {
  //     setHostFormTemp(hostForm);
  //   }
  // }, []);

  const addAssetDynamicGroup = useCallback(
    ({ name, assetDynamicGroupId }: { name: string; assetDynamicGroupId: string }): void => {
      if (dynamicGroupForm && dynamicGroupForm.length > 0) {
        const index = dynamicGroupForm?.findIndex(
          (g) => g.assetDynamicGroupId === assetDynamicGroupId,
        );
        if (index !== -1) {
          const cutHostForm = dynamicGroupForm.filter(
            (group) => group.assetDynamicGroupId !== assetDynamicGroupId,
          );
          setDynamicGroupForm([...cutHostForm]);
        } else {
          setDynamicGroupForm([
            ...dynamicGroupForm,
            {
              assetDynamicGroupId,
              name,
            },
          ]);
        }
      } else {
        setDynamicGroupForm([
          {
            assetDynamicGroupId: dataItem.assetDynamicGroupId,
            name: dataItem.name,
          },
        ]);
      }
    },
    [dynamicGroupForm, setDynamicGroupForm, dataItem.assetDynamicGroupId, dataItem.name],
  );

  return (
    <td className="checkbox_cell">
      <Checkbox
        checked={
          !!dynamicGroupForm?.find((g) => g.assetDynamicGroupId === dataItem.assetDynamicGroupId)
        }
        onClick={(): void =>
          addAssetDynamicGroup({
            name: dataItem.name,
            assetDynamicGroupId: dataItem.assetDynamicGroupId,
          })
        }
      />
    </td>
  );
}
