import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo } from 'react';
import { useHostModalContext } from '../../../../../../hooks/useHostModalContext';

function CellSelectWinSoft(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { addSoftwareToTask, winSoftwareUpdateState, setWinSoftwareUpdateState } =
    useHostModalContext();

  return (
    <td>
      <Checkbox
        checked={
          winSoftwareUpdateState &&
          dataItem.availableUpdate &&
          dataItem.availableUpdate.softwareCodename in winSoftwareUpdateState
        }
        onClick={(): void =>
          dataItem.availableUpdate &&
          addSoftwareToTask(
            dataItem.availableUpdate.softwareCodename,
            dataItem.availableUpdate.softwareName,
            setWinSoftwareUpdateState,
            winSoftwareUpdateState,
          )
        }
        disabled={
          !dataItem.availableUpdate ||
          (dataItem.availableUpdate && !dataItem.availableUpdate.softwareCodename)
        }
      />
    </td>
  );
}

export default memo(CellSelectWinSoft);
