/* eslint-disable react/jsx-pascal-case */
import { useCallback, useState } from 'react';
import type { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { Typography } from '@progress/kendo-react-common';
import { getUrlHostGr } from '../../../utils/helpers/host-group-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { CommonGroupList } from '../CommonGroupList';
import type localeHostGroups from '../../../utils/i18n/assetLocales/assetGroups.json';
import { TabsGroups } from '../../features/ContainerOfGroups/TabsGroups';

export function AddGroupsForTask(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHostGroups;

  const componentName = 'AddGroupsForTask';

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback((e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  }, []);

  return (
    <div>
      {/* <div className="common-header-page">
        <Typography.h3>{localeHostGroups[currentLocale].containerOfGr.title}</Typography.h3>
      </div> */}
      <TabsGroups selected={selected} handleSelect={handleSelect} taskElement="AddGroupsForTask" />
    </div>
  );
}
