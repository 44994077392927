/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Form, FormElement } from '@progress/kendo-react-form';
import { useEffect, useState } from 'react';
import {
  TabStrip,
  TabStripTab,
  type TabStripSelectEventArguments,
} from '@progress/kendo-react-layout';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../../common/baseElements/Button';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import { WhiteListFormMainPage } from './WhiteListFormMainPage';
import styles from '../WhiteListTabEl.module.scss';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { getUserData } from '../../../../services/local-storage-service';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { BulletinsListForForm } from './BulletinsListForForm';
import { HostListForForm } from '../../../common/form/HostListForForm';
import { GroupListForForm } from '../../../common/form/GroupListForForm';
import { DeviceListForForm } from '../../Devices/DeviceListForForm';
import { ImageListForForm } from '../../Settings/ApiKeys/form/ImageListForForm';
import { HostsMutableBlock } from '../../../common/form/HostsMutableBlock';
import { GroupMutableBlock } from '../../../common/form/GroupMutableBlock';
import { DeviceMutableBlock } from '../../../common/form/DeviceMutableBlock';
import { ImageMutableBlock } from '../../Settings/ApiKeys/form/ImageMutableBlock';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { WhiteListFormAssetType } from './WhiteListFormAssetType';
import { DynamicGroupListForForm } from '../../../common/form/DynamicGroupListForForm';
import { DynamicGroupMutableBlock } from '../../../common/form/DynamicGroupMutableBlock';
import { useGroupContext } from '../../../../hooks/useGroupContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function AddWhiteListForm(): React.ReactElement {
  const currentUser = getUserData();

  const [selected, setSelected] = useState<number>(0);

  const [isHosts, setHosts] = useState(false);

  const [isGroups, setGroups] = useState(false);

  const [isImages, setImages] = useState(false);

  const [isDevices, setDevices] = useState(false);

  const [isAssetType, setIsAssetType] = useState(false);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  const { isOpen, isOpenGroupList, isOpenDynamicGroupList, hostForm } = useHostModalContext();

  const { isAddDevice, deviceForm } = useNetDeviceContext();

  const { dynamicGroupForm } = useGroupContext();

  const {
    name,
    onSubmit,
    cancelWhiteListForm,
    isAddBulletin,
    bulletinForm,
    whitelistUpdate,
    errorMutation,
    setErrorMutation,
    assetType,
  } = useWhiteListContext();

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  const { accountId } = useAccountModalContext();

  const valuesForCheckbox = {
    isHosts,
    setHosts,
    isDevices,
    setDevices,
    isImages,
    setImages,
    isGroups,
    setGroups,
    isAssetType,
    setIsAssetType,
  };

  const { isAddImage, imageForm } = useRegisterContext();

  return (
    <Dialog
      title={
        !whitelistUpdate
          ? localeSettings[currentLocale].whiteList.addWhiteList
          : localeSettings[currentLocale].whiteList.editWhiteList
      }
      onClose={cancelWhiteListForm}
      className={styles.whiteListAddForm}
    >
      <Form
        render={(): React.ReactElement => (
          <FormElement>
            <div className={`form-content ${styles.formContent}`}>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.main}>
                  <WhiteListFormMainPage valuesForCheckbox={valuesForCheckbox} />
                </TabStripTab>
                {isHosts && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.host}>
                    <HostsMutableBlock />
                    <GroupMutableBlock />
                    <DynamicGroupMutableBlock />
                  </TabStripTab>
                )}
                {isDevices && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.device}>
                    <DeviceMutableBlock />
                  </TabStripTab>
                )}
                {isImages && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.image}>
                    <ImageMutableBlock />
                  </TabStripTab>
                )}
                {isAssetType && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.assetType}>
                    <WhiteListFormAssetType />
                  </TabStripTab>
                )}
              </TabStrip>
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                onClick={onSubmit}
                disabled={
                  (currentUser?.role === 'super_admin' &&
                    accountId.id === '000' &&
                    !whitelistUpdate) ||
                  !name ||
                  !bulletinForm ||
                  bulletinForm.length === 0 ||
                  (!assetType.firewall &&
                    !assetType.host &&
                    !assetType.image &&
                    !assetType.router &&
                    !assetType.switch &&
                    (!hostForm || hostForm.length === 0) &&
                    (!dynamicGroupForm || dynamicGroupForm.length === 0) &&
                    (!deviceForm || deviceForm.length === 0) &&
                    (!imageForm || imageForm.length === 0))
                }
              >
                {whitelistUpdate
                  ? localeSettings[currentLocale].apiKey.btnEdit
                  : localeSettings[currentLocale].apiKey.btnCreate}
              </Button>
              <Button type="button" onClick={cancelWhiteListForm}>
                {localeSettings[currentLocale].apiKey.btnCancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
      {isOpen && <HostListForForm />}
      {isOpenGroupList && <GroupListForForm />}
      {isOpenDynamicGroupList && <DynamicGroupListForForm />}
      {isAddDevice && <DeviceListForForm />}
      {isAddImage && <ImageListForForm />}
      {isAddBulletin && <BulletinsListForForm />}
    </Dialog>
  );
}
