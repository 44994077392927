import type { TaskActionsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskActionsDashboardResponse.v1';
import type { TCredentialIdTaskSync } from '../../../../utils/helpers/types';

export function ThirdColumn({
  currentLocale,
  data,
  bindCredentials,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  data: TaskActionsDashboardResponse | undefined;
  bindCredentials: TCredentialIdTaskSync[] | undefined;
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>SSH</span>
          </td>
          <td>
            {bindCredentials?.find((b) => b.type === 'ssh') ? (
              <span>{bindCredentials?.filter((b) => b.type === 'ssh')[0].name}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>WINRM</span>
          </td>
          {data && data.data[0].ldap?.name ? (
            <td>
              {bindCredentials?.find((b) => b.type === 'winrm') ? (
                <span>{bindCredentials?.filter((b) => b.type === 'winrm')[0].name}</span>
              ) : (
                <span>-</span>
              )}
            </td>
          ) : (
            <td>-</td>
          )}
        </tr>
      </tbody>
    </table>
  );
}
