/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import localeAuditTask from '../../../../utils/i18n/taskLocale/auditTask.json';
import type { IPackagesUpdate } from '../../../../utils/helpers/task-helpers';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function TaskPResultAdditionalInform({
  packagesUpdate,
  status,
  agentError,
  checkConsoleData,
  setSelected,
}: {
  packagesUpdate: IPackagesUpdate;
  status: 'processing' | 'success' | 'fail' | 'timeout' | 'skipped';
  agentError: string | false | undefined;
  checkConsoleData: boolean;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
}): React.ReactElement {
  return (
    <>
      {packagesUpdate &&
        packagesUpdate.allPkgs.length === 0 &&
        !agentError &&
        status !== 'processing' && (
          <p>{localeAuditTask[currentLocale].vulnsSoft.table.notFoundPackages}</p>
        )}
      {status === 'processing' && (
        <p>{localeAuditTask[currentLocale].vulnsSoft.table.processing}</p>
      )}
      {agentError && (
        <div className="package-update-warning">
          <div className="package-update-warning__wrapper">
            <span className="package-update-warning__title">
              {localeAuditTask[currentLocale].packageUpdateWarning.errorInfo}
            </span>
          </div>
          <div className="package-update-warning__wrapper">
            <span className="package-update-warning__info">{agentError}</span>
          </div>
          {checkConsoleData && (
            <div className="package-update-warning__wrapper">
              <span
                className="package-update-warning__console"
                onClick={(): void => setSelected(1)}
              >
                {localeAuditTask[currentLocale].packageUpdateWarning.linkToConsole}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
