/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridHeaderCellProps } from '@progress/kendo-react-grid';
import localeAuditTask from '../../../../../../utils/i18n/taskLocale/auditTask.json';
import styles from '../packageNameModal.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function AvailableUpdateHeaderCell(props: GridHeaderCellProps): React.ReactElement {
  const { title, onClick, children } = props;

  return (
    <div onClick={onClick} className={`${styles.action_header_cell} k-cell-inner`}>
      {title}
      <span className={styles.types_info}>
        <div className={`${styles.types_info_content} k-link`}>
          <div>
            <span>
              {localeAuditTask[currentLocale].vulnsSoft.table.availableUpdatesTitle1} /{' '}
              {localeAuditTask[currentLocale].vulnsSoft.table.availableUpdatesTitle2}
            </span>
          </div>
        </div>
        {children}
        <img
          src={`${process.env.PUBLIC_URL}/images/question.svg`}
          alt=""
          className="tooltip-question-images"
        />
      </span>
    </div>
  );
}
