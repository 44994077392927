import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { history } from '../utils/history';
import type { IPageState } from '../utils/helpers/types';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import { prepareFilterVal } from './filterVal-for-service';
import type { DashboardBulletinsResponse } from '../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import { commonInstance } from '../lib/axios/hooks/useCommonAxios';
import type { AssetsDevicesDashboardResponse } from '../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetsHostsDashboardResponse } from '../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';
import type { BulletinDashboardResponse } from '../types/__generated/on-premise-solution/api/bulletinDashboardResponse.v1';
import { urlFilterString } from '../utils/helpers/url-filter-string-helper';

export async function getBulletins(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<DashboardBulletinsResponse> {
  // const queryString = urlFilterString(filterVal);

  // const paramsData = {
  //   skip: page.skip,
  //   limit: page.take,
  //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
  //   sortOrder: sort[0].dir,
  //   str: queryString,
  // };

  const result = await dashboardInstance.get<DashboardBulletinsResponse>('/bulletins', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    // params: paramsData,

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      history.replace({
        pathname: `/lk/security/vulnerabilities?${stringifiedParams}`,
      });

      return stringifiedParams;
    },
  });

  return result.data;
}
export async function getBulletinsWl(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<DashboardBulletinsResponse> {
  // const queryString = urlFilterString(filterVal);

  // const paramsData = {
  //   skip: page.skip,
  //   limit: page.take,
  //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
  //   sortOrder: sort[0].dir,
  //   str: queryString,
  // };

  const result = await dashboardInstance.get<DashboardBulletinsResponse>('/bulletins/whitelisted', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    // params: paramsData,

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      history.replace({
        pathname: `/lk/security/vulnerabilities?${stringifiedParams}`,
      });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getAllBulletins(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<DashboardBulletinsResponse> {
  const result = await commonInstance.get<DashboardBulletinsResponse>('/v1/info/bulletins', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getBulletinById(
  bulletinId: string | null,
): Promise<BulletinDashboardResponse> {
  const result = await dashboardInstance.get<BulletinDashboardResponse>(`/bulletins/${bulletinId}`);

  return result.data;
}

export async function getBulletinIds(id: string): Promise<DashboardBulletinsResponse> {
  const result = await commonInstance.get<DashboardBulletinsResponse>(
    `/v1/info/bulletins?type[$ne]=kb&bulletinId[$regex]=${id.toUpperCase()}`,
  );

  return result.data;
}

export async function getRelatedBulletinsPost(
  related: string[] | null | undefined,
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<DashboardBulletinsResponse> {
  const params = {
    skip: page.skip,
    limit: page.take,
    // sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    ...prepareFilterVal(filterVal),
  };

  const stringifiedParams = qs.stringify(params, { encode: false });

  const result = await commonInstance.post<DashboardBulletinsResponse>(
    `/v1/info/bulletins?${stringifiedParams}`,
    stringifiedParams.includes('bulletinId')
      ? null
      : {
          bulletinId: { $in: related || [''] },
        },
  );

  return result.data;
}

export async function getBulletinAssets(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  bulletinId: string | null,
  // ): Promise<AssetsDashboardResponse> {
  //   const result = await dashboardInstance.get<AssetsDashboardResponse>('/assets/hosts', {
): Promise<AssetsHostsDashboardResponse> {
  const result = await dashboardInstance.get<AssetsHostsDashboardResponse>('/assets/hosts', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '&bulletinId[$eq]': `${bulletinId}`,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });
      // history.replace({
      //   pathname: `/lk/assets/hosts?${stringifiedParams}`,
      // });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getBulletinAssetsWl(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  bulletinId: string | null,
  // ): Promise<AssetsDashboardResponse> {
  //   const result = await dashboardInstance.get<AssetsDashboardResponse>('/assets/hosts', {
): Promise<AssetsHostsDashboardResponse> {
  const result = await dashboardInstance.get<AssetsHostsDashboardResponse>('/assets/hosts', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '&whitelistedBulletinId[$eq]': `${bulletinId}`,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      return stringifiedParams;
    },
  });

  return result.data;
}
export async function getBulletinNetDev(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  bulletinId: string | null,
): Promise<AssetsDevicesDashboardResponse> {
  const result = await dashboardInstance.get<AssetsDevicesDashboardResponse>('/assets/devices', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '&bulletinId[$eq]': `${bulletinId}`,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });
      // history.replace({
      //   pathname: `/lk/assets/hosts?${stringifiedParams}`,
      // });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getBulletinNetDevWl(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  bulletinId: string | null,
): Promise<AssetsDevicesDashboardResponse> {
  const result = await dashboardInstance.get<AssetsDevicesDashboardResponse>('/assets/devices', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '&whitelistedBulletinId[$eq]': `${bulletinId}`,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      return stringifiedParams;
    },
  });

  return result.data;
}
