/* eslint-disable react/jsx-no-useless-fragment */
import { useCallback, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import '../../imageRegister.module.scss';
import type { GridCellProps } from '@progress/kendo-react-grid';
import { ImageRepList } from './ImageRepList';
import type { ContainerRegistryRepositoriesDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/containerRegistryRepositoriesDashboardResponse.v1';

const createRepImageList = (
  imageList: ContainerRegistryRepositoriesDashboardResponse['data'][0]['scannedAssetImages'],
): ContainerRegistryRepositoriesDashboardResponse['data'][0]['scannedAssetImages'] => {
  const repImageList: ContainerRegistryRepositoriesDashboardResponse['data'][0]['scannedAssetImages'] =
    [];
  imageList?.forEach((image) => {
    if (image.imageNames.length > 1) {
      image.imageNames.forEach((imageName) => {
        repImageList.push({ ...image, imageNames: [imageName] });
      });
    } else repImageList.push(image);
  });

  return repImageList;
};

export function RepositoryRow(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const [repClass, setRepClass] = useState(['repository', 'visible']);

  const handleContent = useCallback((): void => {
    if (repClass.indexOf('visible') === -1) {
      setRepClass((prevState) => [...prevState, 'visible']);
    } else {
      setRepClass(['repository']);
    }
  }, [repClass]);

  return (
    <td className="wrapper-menu">
      <div className="row">
        <Button
          className={repClass && repClass.indexOf('visible') !== -1 ? 'active' : ''}
          icon={repClass && repClass.indexOf('visible') !== -1 ? 'minus' : 'plus'}
          onClick={handleContent}
        />
        <span
          onClick={handleContent}
          id="imagesids"
          className="k-button k-button-flat row-title"
          role="button"
          tabIndex={0}
          onKeyPress={handleContent}
        >
          {dataItem.repositoryName}
        </span>
      </div>
      <div className={`content ${repClass.join(' ')}`}>
        <ImageRepList repoValues={createRepImageList(dataItem.scannedAssetImages)} />
      </div>
    </td>
  );
}
