import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import type { AssetDynamicGroupDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetDynamicGroupsDashboardResponse.v1';
import { BACKWARD_FILTER_OPERATOR_LIST } from './constant-filter-operators';
import type { TDynamicGroupFilterValue } from './types';

export const returnFilterOperator = (val: TDynamicGroupFilterValue): string => {
  let filterOperator = '';

  let filterOperatorWithNot = '';

  if (typeof val === 'object') {
    if (!Object.keys(val)[0].includes('$not')) {
      filterOperator = BACKWARD_FILTER_OPERATOR_LIST[String(Object.keys(val)[0])];
    }
    if (Object.keys(val)[0].includes('$not')) {
      filterOperatorWithNot = BACKWARD_FILTER_OPERATOR_LIST[String(Object.values(val)[0])];
    }
  }

  return filterOperator || filterOperatorWithNot;
};

export const returnFilterValue = (val: TDynamicGroupFilterValue): string => {
  let filterValue = '';

  let filterValueWithNot = '';

  if (typeof val === 'object') {
    if (!Object.keys(val)[0].includes('$not')) {
      filterValue = String(Object.values(val)[0]);
    }
    if (Object.keys(val)[0].includes('$not')) {
      filterValueWithNot = String(Object.values(val)[1]);
    }
  }

  return filterValue || filterValueWithNot;
};

type TPrepareDynamicFilterGroupDescription = {
  dataFilters: AssetDynamicGroupDashboardResponse['filters'] | null;
  setFilter0: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor>>;
  setFilter1: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor>>;
  setFilter2: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor>>;
  setFilter3: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor>>;
  setFilter4: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor>>;
  filter1: CompositeFilterDescriptor;
  filter2: CompositeFilterDescriptor;
  filter3: CompositeFilterDescriptor;
  filter4: CompositeFilterDescriptor;
  setDisplayFilterBlock: React.Dispatch<
    React.SetStateAction<{
      isFilter1: boolean;
      isFilter2: boolean;
      isFilter3: boolean;
      isFilter4: boolean;
    }>
  >;
};
export const prepareDynamicGroupDescriptionFilters = (
  props: TPrepareDynamicFilterGroupDescription,
): void => {
  const {
    dataFilters,
    setFilter0,
    setFilter1,
    setFilter2,
    setFilter3,
    setFilter4,
    filter1,
    filter2,
    filter3,
    filter4,
    setDisplayFilterBlock,
  } = props;

  if (dataFilters && dataFilters?.length > 0 && dataFilters[0].filter?.length > 0) {
    const filterValues = dataFilters[0].filter.map((v) => {
      const filterValue = Object.values(v)[0] as TDynamicGroupFilterValue;

      return {
        field: Object.keys(v)[0],
        operator:
          typeof Object.values(v)[0] === 'string' ? 'eq' : returnFilterOperator(filterValue),
        value:
          typeof dataFilters[0].filter[0] === 'string'
            ? dataFilters[0].filter[0]
            : returnFilterValue(filterValue),
      };
    });
    if (filterValues) {
      const resultFilter = {
        filters: [...filterValues],
        logic: 'and',
      } as CompositeFilterDescriptor;

      setFilter0(resultFilter);
    }
  }
  if (dataFilters && dataFilters?.length > 1 && dataFilters[1].filter?.length > 0) {
    const filterValues = dataFilters[1].filter.map((v) => {
      const filterValue = Object.values(v)[0] as TDynamicGroupFilterValue;

      return {
        field: Object.keys(v)[0],
        operator:
          typeof Object.values(v)[0] === 'string' ? 'eq' : returnFilterOperator(filterValue),
        value:
          typeof dataFilters[1].filter[0] === 'string'
            ? dataFilters[1].filter[0]
            : returnFilterValue(filterValue),
      };
    });
    if (filterValues) {
      const resultFilter = {
        filters: [...filterValues],
        logic: 'and',
      } as CompositeFilterDescriptor;

      setFilter1(resultFilter);
    }
  }
  if (dataFilters && dataFilters?.length > 2 && dataFilters[2].filter?.length > 0) {
    const filterValues = dataFilters[2].filter.map((v) => {
      const filterValue = Object.values(v)[0] as TDynamicGroupFilterValue;

      return {
        field: Object.keys(v)[0],
        operator:
          typeof dataFilters[2].filter[0] === 'string' ? 'eq' : returnFilterOperator(filterValue),
        value:
          typeof dataFilters[2].filter[0] === 'string'
            ? dataFilters[2].filter[0]
            : returnFilterValue(filterValue),
      };
    });
    if (filterValues) {
      const resultFilter = {
        filters: [...filterValues],
        logic: 'and',
      } as CompositeFilterDescriptor;

      setFilter2(resultFilter);
    }
  }
  if (dataFilters && dataFilters?.length > 3 && dataFilters[3].filter?.length > 0) {
    const filterValues = dataFilters[3].filter.map((v) => {
      const filterValue = Object.values(v)[0] as TDynamicGroupFilterValue;

      return {
        field: Object.keys(v)[0],
        operator:
          typeof dataFilters[3].filter[0] === 'string' ? 'eq' : returnFilterOperator(filterValue),
        value:
          typeof dataFilters[3].filter[0] === 'string'
            ? dataFilters[3].filter[0]
            : returnFilterValue(filterValue),
      };
    });
    if (filterValues) {
      const resultFilter = {
        filters: [...filterValues],
        logic: 'and',
      } as CompositeFilterDescriptor;

      setFilter3(resultFilter);
    }
  }

  if (dataFilters && dataFilters?.length > 4 && dataFilters[4].filter?.length > 0) {
    const filterValues = dataFilters[4].filter.map((v) => {
      const filterValue = Object.values(v)[0] as TDynamicGroupFilterValue;

      return {
        field: Object.keys(v)[0],
        operator:
          typeof dataFilters[4].filter[0] === 'string' ? 'eq' : returnFilterOperator(filterValue),
        value:
          typeof dataFilters[4].filter[0] === 'string'
            ? dataFilters[4].filter[0]
            : returnFilterValue(filterValue),
      };
    });
    if (filterValues) {
      const resultFilter = {
        filters: [...filterValues],
        logic: 'and',
      } as CompositeFilterDescriptor;

      setFilter4(resultFilter);
    }
  }

  if (filter1 && filter1.filters && filter1.filters.length > 0) {
    setDisplayFilterBlock((prevState) => ({ ...prevState, isFilter1: true }));
  }
  if (filter2 && filter2.filters && filter2.filters.length > 0) {
    setDisplayFilterBlock((prevState) => ({ ...prevState, isFilter2: true }));
  }
  if (filter3 && filter3.filters && filter3.filters.length > 0) {
    setDisplayFilterBlock((prevState) => ({ ...prevState, isFilter3: true }));
  }
  if (filter4 && filter4.filters && filter4.filters.length > 0) {
    setDisplayFilterBlock((prevState) => ({ ...prevState, isFilter4: true }));
  }
};
