import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo, useCallback } from 'react';
import { useHostModalContext } from '../../../../../../hooks/useHostModalContext';

function CellSelectPackageWl(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { selectedPkgsWl, setSelectedPkgsWl } = useHostModalContext();

  const addPackage = useCallback(
    (name: string, version: string): void => {
      if (selectedPkgsWl && selectedPkgsWl.length > 0) {
        const index = selectedPkgsWl.findIndex((p) => p.name === name && p.version === version);
        if (index !== -1) {
          const cutSelectedPkgsWl = selectedPkgsWl.filter(
            (pkgName) => pkgName.name !== name || pkgName.version !== version,
          );
          setSelectedPkgsWl([...cutSelectedPkgsWl]);
        } else {
          setSelectedPkgsWl([...selectedPkgsWl, { name, version }]);
        }
      } else {
        setSelectedPkgsWl([{ name, version }]);
      }
    },
    [selectedPkgsWl, setSelectedPkgsWl],
  );

  return (
    <td>
      {selectedPkgsWl?.find((p) => p === dataItem.name) ? (
        <Checkbox
          checked={
            !!selectedPkgsWl?.find(
              (p) => p.name === dataItem.name && p.version === dataItem.version,
            )
          }
          onClick={(): void => addPackage(dataItem.name, dataItem.version)}
        />
      ) : (
        <Checkbox
          checked={
            !!selectedPkgsWl?.find(
              (p) => p.name === dataItem.name && p.version === dataItem.version,
            )
          }
          onClick={(): void => addPackage(dataItem.name, dataItem.version)}
        />
      )}
    </td>
  );
}

export default memo(CellSelectPackageWl);
