import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import type localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import { FILTER_DYNAMIC_GROUP } from '../../../../utils/helpers/constant-serv-filter-host';
import styles from '../../ContainerOfGroups/containerOfGroups.module.scss';

export function DynamicGroupFilterBlock({
  filter,
  setFilter,
  isFilterKey,
  onFilterChange,
}: {
  filter: CompositeFilterDescriptor;
  setFilter: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor>>;
  isFilterKey: string;
  onFilterChange: (
    event: FilterChangeEvent,
    setState: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
    isFilterKey: string,
  ) => void;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHost;

  return (
    <div className={styles.dynamicGroupFilterBlock}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={(e): void => onFilterChange(e, setFilter, isFilterKey)}
              fields={FILTER_DYNAMIC_GROUP[currentLocale]}
            />
          </div>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
