import { UseQueryResult, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useRef } from 'react';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { AssetImageDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetImageDashboardResponse.v1';
import { getImage } from '../../services/dashboard-service';
import type { AuditResponse } from '../../types/__generated/on-premise-solution/api/auditsResponse.v1';
import { infoAudit } from '../../services/audit-service';
import { useAuditModalContext } from '../useAuditModalContext';
import { useCommonContext } from '../useCommonContext';
import { useMaintenanceWindowsContext } from '../useMaintenanceWindowsContext';

interface IUseImageDescription {
  queryDashboardAssetId: UseQueryResult<AssetImageDashboardResponse, ApiError>;
  queryAuditId: UseQueryResult<AuditResponse, ApiError>;
  imagename: string | undefined;
}
export function useImageDescription(): IUseImageDescription {
  const imagename = useRef<string | undefined>(undefined);

  const { assetId } = useParams<{ assetId: string }>();

  const { setAuditData } = useAuditModalContext();

  const { setAccountOfAsset } = useCommonContext();

  const { entityName, setMaintenanceWindowsList } = useMaintenanceWindowsContext();

  const queryDashboardAssetId = useQuery<AssetImageDashboardResponse, ApiError>(
    ['dashboardAssetId', assetId],
    () => getImage(assetId),
    {
      onSuccess: (resp) => {
        imagename.current = resp.type === 'image' ? resp.imageNames[0] : undefined;
        entityName.current = resp.type === 'image' ? resp.imageNames[0] : undefined;
        setAccountOfAsset(resp.account);
        setMaintenanceWindowsList(resp.maintenanceWindows);
      },
      enabled: document.location.pathname.includes('/assets/images/'),
    },
  );

  const queryAuditId = useQuery<AuditResponse, ApiError>(
    [
      'infoAuditId',
      queryDashboardAssetId.data?.type === 'image' &&
        queryDashboardAssetId.data?.latestAudit?.auditId,
    ],
    () =>
      infoAudit(
        queryDashboardAssetId.data?.type === 'image'
          ? queryDashboardAssetId.data?.latestAudit?.auditId
          : undefined,
      ),
    {
      onSuccess: (data) => {
        setAuditData(data);
      },
      keepPreviousData: true,
      enabled: !!(
        queryDashboardAssetId.data?.type === 'image' &&
        queryDashboardAssetId.data?.latestAudit?.auditId
      ),
    },
  );

  return {
    queryDashboardAssetId,
    queryAuditId,
    imagename: imagename.current,
  };
}
